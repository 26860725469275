import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../..";
import Swal from "sweetalert2";
import Footer from "../Base/Footer";

const VerifyDetails = ({
  setActiveTab,
  activeTab,
  userId,
  setAmountOrderNo,
  setPayKey,
  setPayOrderId,
  setOrderTotalAmount,
}) => {
  const [userInput, setUserInput] = useState({});
  const [orderInfo, setOrderInfo] = useState("");
  // const [amountId, setAmountID] = useState(0);
  const {
    name,
    number,
    email,
    language,
    gender,
    instrument,
    songMood,
    songFor,
    songFrom,
    occasion,
    mixingMaster,
    delivery,
    deliveryChrg,
    deliveryDate,
    story,
    audioFile,
  } = userInput;

  const getOrderData = (id) => {
    axios
      .get(BASE_URL + "/v1/form/" + id)
      .then((response) => response.data)
      .then((res_data) => {
        // const parts2 = res_data.data.formRequestDto.orderNumber;
        setOrderInfo(res_data.data.formRequestDto);
        setAmountOrderNo(res_data.data.formRequestDto.orderNumber);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (userId !== 0) {
      getOrderData(userId);
    }
  }, []);
  const backFunc = (e) => {
    e.preventDefault();
    setActiveTab(1);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // let data = { params: { amount: orderInfo.orderTotal } };

    const response = axios

      .post(
        BASE_URL +
          "/v1/masterpayment/createOrder?amount=" +
          `${orderInfo.orderTotal}` +
          "&formId=" +
          userId
      )
      .then((response) => {
        setPayKey(response.data.KeyId);
        setPayOrderId(response.data.OrderId);
        setOrderTotalAmount(orderInfo.orderTotal);
        setActiveTab(3);
      });

    // setPayKey()
    // setPayOrderId();
  };

  const backToEdit = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure want to edit?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        setActiveTab(0);
      }
    });
  };
  return (
    <>
      <section className="section1 bg-color DM_Sans">
        {activeTab === 2 && (
          <div>
            <div className="container-fluid">
              <div className="Tab-shape1 d-none d-md-block">
                <img src={require("../../assets/images/shape1.png")} alt="" />
              </div>
              <div className="Tab-shape2 d-none d-md-block">
                <img src={require("../../assets/images/shape2.png")} alt="" />
              </div>
            </div>

            <div className="container-fluid" id="verifyDetails">
              <div className="ms-sm-5 ps-3">
                <form
                  action=""
                  onSubmit={handleSubmit}
                  className="row my-1  position-relative"
                >
                  <div className="row">
                    <div className="col-md-10 col-xl-8 p-0 px-md-2 position-relative">
                      <div className="dsf">
                        <h2 className="mt-5 c_F77B21 fs-36 fw-6">
                          Verify Your Details
                        </h2>
                      </div>
                      <h4 className="fs-22 c_F77B21 fw-6 py-3 pb-2 py-md-4">
                        Basic Info
                      </h4>
                      <div className="input-group-set gap-2 row-gap-0 gap-md-4 py-0 p-3 px-md-4">
                        <div className="userData-group d-flex gap-1 gap-sm-3">
                          <span className="user-data-label">Name</span>
                          <p className="user-data-value">{orderInfo.name}</p>
                        </div>
                        <div className="userData-group d-flex gap-1 gap-sm-3">
                          <span className="user-data-label">
                            <span className="d-none d-sm-inline-block">
                              Mobile{" "}
                            </span>{" "}
                            Number
                          </span>
                          <p className="user-data-value">
                            {orderInfo.phonenumber}
                          </p>
                        </div>
                        <div className="userData-group d-flex gap-1 gap-sm-3">
                          <span className="user-data-label">Your Email</span>
                          <p className="user-data-value">{orderInfo.mail}</p>
                        </div>
                        <div className="userData-group d-flex gap-1 gap-sm-3">
                          <span className="user-data-label">Language</span>
                          <p className="user-data-value">{orderInfo.lanuage}</p>
                        </div>
                      </div>

                      {/* ============= Back to Edit btn ============ */}
                      <hr className="back-to-edit-hr d-none d-lg-block" />
                      <button
                        className="back-to-edit d-flex btn gap-2 c_F77B21 "
                        type="button"
                        onClick={backToEdit}
                      >
                        <span className="edit-btn-text">Edit</span>
                        <i className="bi bi-pencil-fill edit-btn-icon"></i>
                      </button>
                    </div>
                    <h4 className="fs-22 c_F77B21 fw-6 py-3 pb-2 py-md-4 ps-0">
                      Song Info
                    </h4>
                    <div className="col-md-10 col-xl-8">
                      <div className="row input-group-set py-1 px-md-4 row-gap-sm-3 row-gap-md-4">
                        <div className="col-lg-4 col-sm-6">
                          <div className="userData-group d-flex gap-1 gap-sm-3">
                            <span className="user-data-label">Artist</span>
                            <p className="user-data-value">
                              {orderInfo.artist}
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                          {" "}
                          <div className="userData-group d-flex gap-1 gap-sm-3">
                            <span className="user-data-label">Version</span>
                            <p className="user-data-value">
                              {orderInfo.instrument}
                            </p>
                          </div>
                        </div>
                        {/* <div className="col-lg-4 col-sm-6">
                          {" "}
                          <div className="userData-group d-flex gap-1 gap-sm-3">
                            <span className="user-data-label">Mood</span>
                            <p className="user-data-value">{orderInfo.mood}</p>
                          </div>
                        </div> */}
                        <div className="col-lg-4 col-sm-6">
                          {" "}
                          <div className="userData-group d-flex gap-1 gap-sm-3">
                            <span className="user-data-label">Song To</span>
                            <p className="user-data-value">
                              {orderInfo.songto}
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                          {" "}
                          <div className="userData-group d-flex gap-1 gap-sm-3">
                            <span className="user-data-label">Song From</span>
                            <p className="user-data-value">
                              {orderInfo.songfrom}
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                          {" "}
                          <div className="userData-group d-flex gap-1 gap-sm-3">
                            <span className="user-data-label">Occasion</span>
                            <p className="user-data-value">
                              {orderInfo.occasion}
                            </p>
                          </div>
                        </div>
                        {/* ========================= */}
                        <div className="col-lg-4 col-sm-12">
                          {" "}
                          <div className="userData-group d-flex gap-1 gap-sm-3">
                            <span className="user-data-label">
                              Mixing Master
                            </span>
                            <p className="user-data-value">
                              {orderInfo.mixtureMaster}
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                          {" "}
                          <div className="userData-group d-flex gap-1 gap-sm-3">
                            <span className="user-data-label">
                              Song Delivery
                            </span>
                            <p className="user-data-value">
                              {orderInfo.customerDeliveryDate}
                              <span className="text-muted">
                                {/* ({deliveryDate}) */}
                              </span>{" "}
                              {deliveryChrg}
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                          {" "}
                          <div className="userData-group d-flex gap-1 gap-sm-3">
                            <span className="user-data-label">Total</span>
                            <p className="user-data-value">
                              {"₹"}
                              {orderInfo.orderTotal}
                            </p>
                          </div>
                        </div>
                        {/* ============================ */}
                        <div className="col-xl-8 col-sm-6">
                          {" "}
                          <div className="userData-group  gap-3">
                            <p className="user-data-label d-block">
                              Your Story :
                            </p>
                            <p className="fw-6 text-dark">{orderInfo.story}</p>
                          </div>
                        </div>
                        <div className="col-xl-4 col-sm-6">
                          {" "}
                          <div
                            className="userData-group  gap-3"
                            id="verify_audio"
                          >
                            <p className="user-data-label d-block">
                              Your Story Audio :
                            </p>
                            <audio
                              src={
                                BASE_URL + "/v1/form/song/" + orderInfo.clipPath
                              }
                              className="audio-file-data"
                              controls
                              controlsList="nodownload"
                              style={{ zIndex: 9, position: "relative" }}
                            ></audio>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ================= Visible only on mobile =================== */}
                  <div className="tab-button d-flex details-btn mb-4 my-3 sticky-bottom d-md-none bg-color">
                    <button
                      type="button"
                      onClick={backFunc}
                      className="back-btn fw-6 h-100 ms-3"
                    >
                      Back
                    </button>
                    <button
                      type="submit"
                      className="continue-btn ms-3 mx-sm-4 fw-6 px-3"
                    >
                      Proceed to Pay
                    </button>
                  </div>
                  {/* ================= Not Visible only on mobile =================== */}

                  <div className="tab-button d-flex details-btn mb-4 my-3 d-none d-md-flex bg-color">
                    <button
                      type="button"
                      onClick={backFunc}
                      className="back-btn fw-6 h-100 ms-3"
                    >
                      Back
                    </button>
                    <button
                      type="submit"
                      className="continue-btn ms-3 mx-sm-4 fw-6 px-3"
                    >
                      Proceed to Pay
                    </button>
                  </div>

                  <div className="details-bg-img d-none d-xl-block">
                    <img
                      src={require("../../assets/images/TuneFor2Img/custom-song-for-father.webp")}
                      alt="custom song for father"
                      title="custom song for father"
                      className="tab2-img img-fluid"
                    />
                  </div>
                </form>
              </div>

              <div className="col-lg-4">
                {/* <div className="basic-bg">
                                <img src={require('../../assets/images/basic-bg-img.png')} alt="" />
                            </div>
                            <div className="Tab-shape3">
                                <img src={require('../../assets/images/shape3.png')} alt="" />
                            </div> */}
              </div>
            </div>
          </div>
        )}
      </section>
      <Footer />
    </>
  );
};

export default VerifyDetails;
