import React, { useState } from "react";
import { Tabs } from "antd";
import { TextFields } from "../AdminSettings/TextFields";
import { SongDeliveryDate } from "./SongDeliveryDate";
import LightBox from "./LightBox";

export const Settings = () => {
  const { TabPane } = Tabs;
  const items = [
    { key: "1", label: "TextFields", children: <TextFields /> },
    { key: "2", label: "LightBox", children: <LightBox /> },
  ];
  const [activeTab, setActiveTab] = useState("1");
  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  return (
    <div className="masterBase">
      {/* Button with dynamic text */}

      {/* Tabs */}
      {/* <Tabs
        defaultActiveKey="1"
        indicator={{ size: 30, align: "center" }}
        onChange={handleTabChange}
      >
        {items.map(({ key, tab, content }) => (
          <TabPane tab={tab} key={key}>
            {content}
          </TabPane>
        ))}
      </Tabs> */}
      <Tabs
        defaultActiveKey="1"
        activeKey={activeTab}
        onChange={handleTabChange}
        items={items} // Use the `items` prop
      />
    </div>
  );
};
