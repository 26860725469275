import React, { useEffect } from "react";
import logo from "../assets/images/logo.png";
import Footer from "../pages/Base/Footer.jsx";
import Navbar from "../pages/Base/Navbar";
import { useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

export const PrivacyPolicy = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigateHome = () => {
    navigate("/");
  };
  return (
    <HelmetProvider>
      <Helmet>
        <title>Our Privacy Policy |Tune for 2</title>
        <meta
          name="description"
          content="Safeguard your data with Tune for 2's transparent privacy policy. We're committed to data security and confidentiality. Your privacy is our priority."
          data-rh="true"
        />
        <link rel="canonical" href="https://tunefor2.com/privacy-policy"></link>
      </Helmet>
      <>
        <section id="privacyPolicyPage" className="bg-color">
          <Navbar policy={true} />
          {/* <div className="px-3 pt-3">
          <img src={logo} alt="" />
        </div> */}
          <div className="title mb-5 img_logo">
            <h2 className="fw-7">Privacy Policy</h2>
          </div>
          <div className="whole_padding">
            <div className="line_height fs-20 fw-4">
              Effective Date: Jan 1 2024{" "}
            </div>
            <div className="line_height fs-24 fw-7">Introduction </div>
            <div className="line_height fs-20 fw-4">
              Tune For 2 ("we", "us", "our") is committed to protecting your
              privacy and ensuring the security of your personal information.
              This Privacy Policy outlines how we collect, use, disclose, and
              protect your information when you visit our website or use our
              services.{" "}
            </div>
            <div className="line_height fs-24 fw-7">
              Information We Collect{" "}
            </div>
            <div className="line_height">
              <ul className="no-bullets">
                <li>
                  <div>
                    <span className="fs-20 fw-7">Personal Information:</span>
                    <span className="fs-20 fw-4 mx-3">
                      When you use our services, we may collect personal
                      information such as your name, email address, phone
                      number, and billing information necessary for payment
                      processing.
                    </span>
                  </div>
                </li>
                <li>
                  <div>
                    <span className="fs-20 fw-7">Story Details:</span>
                    <span className="fs-20 fw-4 mx-3">
                      To create customised songs, we may collect details you
                      provide about your personal experiences, emotions, and
                      memories.
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            <div className="line_height fs-24 fw-7">
              How We Use Your Information{" "}
            </div>
            <div>
              <ul className="no-bullets">
                <li>
                  <div>
                    <span className="fs-20 fw-7">
                      Customised Song Creation:
                    </span>
                    <span className="fs-20 fw-4 mx-3">
                      We use the information you provide to create personalized
                      songs based on your stories and preferences.{" "}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="line_height">
                    <span className="fs-20 fw-7">Communication:</span>
                    <span className="fs-20 fw-4 mx-2">
                      We may use your contact information to communicate with
                      you about your order, updates, and promotional offers
                      related to our services.{" "}
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            <div className="line_height fs-24 fw-7">
              Disclosure of Information{" "}
            </div>
            <div className="line_height">
              <ul className="no-bullets">
                <li>
                  <span className="fs-20 fw-7">Service Providers:</span>
                  <span className="fs-20 fw-4 mx-3">
                    We may share your information with third-party service
                    providers who assist us in operating our website or
                    providing our services, such as payment processors and music
                    production partners.
                  </span>
                </li>
                <li>
                  <span className="fs-20 fw-7">Legal Requirements: </span>
                  <span className="fs-20 fw-4 mx-3">
                    We may disclose your information when required by law or to
                    protect our legal rights, enforce our Terms and Conditions,
                    or comply with judicial proceedings.
                  </span>
                </li>
              </ul>
            </div>
            <div className="line_height fs-24 fw-7">Data Security</div>
            <div className="line_height">
              <ul className="no-bullets">
                <li>
                  <span className="fs-20 fw-7">Security Measures:</span>
                  <span className="fs-20 fw-4 mx-3">
                    We implement reasonable security measures to protect your
                    personal information from unauthorized access, use, or
                    disclosure.
                  </span>
                </li>
                <li>
                  <span className="fs-20 fw-7">Payment Security:</span>
                  <span className="fs-20 fw-4 mx-3">
                    All payment transactions are processed through secure
                    payment gateways that comply with industry standards for
                    data protection.
                  </span>
                </li>
              </ul>
            </div>
            <div className="line_height fs-24 fw-7">
              Your Choices and Rights
            </div>
            <div className="line_height">
              <ul className="no-bullets">
                <li>
                  <span className="fs-20 fw-7">Access and Correction: </span>
                  <span className="fs-20 fw-4 mx-3">
                    You may access, correct, or update your personal information
                    by contacting us directly.
                  </span>
                </li>
                <li>
                  <span className="fs-20 fw-7">Opt-Out:</span>
                  <span className="fs-20 fw-4 mx-3">
                    You can opt-out of receiving promotional emails from us by
                    following the unsubscribe instructions included in the
                    emails.
                  </span>
                </li>
              </ul>
            </div>
            <div className="line_height fs-24 fw-7">Children's Privacy</div>
            <div className="line_height">
              <ul className="no-bullets">
                <li>
                  <span className="fs-20 fw-7">Age Restriction:</span>
                  <span className="fs-20 fw-4 mx-3">
                    Our services are not directed to individuals under the age
                    of 18. We do not knowingly collect personal information from
                    minors without parental consent.
                  </span>
                </li>
              </ul>
            </div>
            <div className="line_height fs-24 fw-7">
              Changes to This Privacy Policy{" "}
            </div>
            <div className="line_height">
              <ul className="no-bullets">
                <li>
                  <span className="fs-20 fw-7">Updates:</span>
                  <span className="fs-20 fw-4 mx-3">
                    We may update this Privacy Policy periodically to reflect
                    changes in our practices and legal requirements. We will
                    notify you of any material changes by posting the updated
                    policy on our website.
                  </span>
                </li>
              </ul>
            </div>
            <div className="line_height fs-24 fw-7">Contact Us </div>
            <div className="line_height">
              <ul className="no-bullets">
                <li>
                  <span className="fs-20 fw-7">Questions:</span>
                  <span className="fs-20 fw-4 mx-3">
                    If you have any questions or concerns about our Privacy
                    Policy or our practices regarding your personal information,
                    please contact us.
                  </span>
                </li>
              </ul>
            </div>
            <div className="title pb-4 line_height_top">
              <img
                src={logo}
                alt=""
                onClick={navigateHome}
                className="cursorPointer"
              />
            </div>
          </div>
        </section>
        <div className="container-fluid bg-color">
          <Footer />
        </div>
      </>
    </HelmetProvider>
  );
};
