import React, { useState, useEffect } from "react";
import {
  Input,
  Table,
  Button,
  message,
  Popconfirm,
  Select,
  Tag,
  Spin,
} from "antd";
import { CheckOutlined } from "@ant-design/icons";
import moment from "moment";
import axios from "axios";
import { BASE_URL } from "../../..";
import SingleLineText from "./Components/SingleLineText";
import AudioPlayer from "./Components/AudioPlayer";
import TextArea from "antd/es/input/TextArea";

export const Recording = ({
  handleFieldClick,
  setRecordingSongCount,
  setDeliveredSongCount,
  additionalProp1,
}) => {
  const [tableData, setTableData] = useState([]);
  const [statusValue, setStatusValue] = useState("");
  const [searchText, setSearchText] = useState("");
  const [visible, setVisible] = useState(false);
  const [reloadKey, setReloadKey] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errorValidate, setErrorValidate] = useState("");
  const [playingIndex, setPlayingIndex] = useState(null);
  const [adminComment, setAdminComment] = useState(""); // State for search input
  const [adminDeliveryDate, setAdminDeliveryDate] = useState(""); // State for search input
  const [originalData, setOriginalData] = useState([]); // Original unfiltered data
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 }); // Pagination state
  const [adminId, setadminId] = useState(localStorage.getItem("adminId"));

  const handlePlay = (index) => {
    setPlayingIndex(index);
  };

  const handlePause = () => {
    setPlayingIndex(null);
  };
  const handleReload = () => {
    setReloadKey((prevKey) => prevKey + 1);
  };
  const handleTextChange = (e) => {
    setAdminComment(e.target.value);
  };

  const onFileSelected = (event, record) => {
    const file = event.target.files[0];
    const updatedTableData = tableData.map((item) => {
      if (item.key === record.key) {
        return { ...item, file1Name: file.name };
      }
      return item;
    });
    setTableData(updatedTableData);
  };

  const sendFileAsBlob = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const fileData = reader.result;
        const blob = new Blob([fileData], { type: file.type });
        // Now you can send the 'blob' to your server or perform further operations
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleStatusChange = (value, record) => {
    setStatusValue(value);
  };

  const changeStatus = (record) => {
    const formData = new FormData();

    if (statusValue === "") {
      setVisible(false);

      confirm(statusValue);
    } else {
      const fileInputId = `fileInput-${record.key}`;
      const fileInput = document.getElementById(fileInputId);
      if (fileInput && fileInput.files.length > 0) {
        formData.append("file", fileInput.files[0]);
      }
      confirm(fileInput.files.length);
      // formData.append("orderPosition", statusValue);
      if (fileInput.files.length > 0) {
        setLoading(true);
        const token = localStorage.getItem("accessToken");
        axios
          .put(
            BASE_URL +
              "/v1/form/" +
              record.id +
              "?orderPosition=" +
              statusValue,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              // responseType: "arraybuffer",
            }
          )
          .then(() => {
            setVisible(false);
            confirm(statusValue);
            message.success("Updated Successfully");
            addCommentApi(record);
            fetchSaved();
            setLoading(false);
            getDeliveredCount();
            handleReload();
          })
          .catch((error) => {
            message.error(error.response.data.message);
            confirm("error");
            handleReload();
            setLoading(false);
            console.error("Data Saving: " + error);
          });
      }
      // handleReload();
      setStatusValue("");
    }
  };
  const addCommentApi = (record) => {
    const form_Data = new FormData();
    form_Data.append("orderPosition", "ORDER_CONFIRMED");
    const token = localStorage.getItem("accessToken");
    axios
      .put(
        BASE_URL +
          "/v1/form/add-comment/" +
          record.orderNumber +
          "?" +
          "comment=" +
          adminComment,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          // responseType: "arraybuffer",
        }
      )
      .then(() => {
        // fetchSaved();
        handleReload();
      })
      .catch((error) => {
        console.error("Data Saveing : " + error);
      });
    setAdminComment("");
    setAdminDeliveryDate("");
  };
  const statusTag = (status) => {
    let color = "default";
    let text = "N/A";

    switch (status) {
      case "paid":
        color = "green";
        text = "Success";
        break;
      case "attempted":
        color = "red";
        text = "Failed";
        break;
      default:
        color = "grey";
        text = "Pending";
    }

    return <Tag color={color}>{text}</Tag>;
  };

  const confirm = (value) => {
    setVisible(false);
    if (value === "") {
      message.warning("Please select the status as Delivered");
    } else if (value <= 0) {
      message.warning("Please upload recorded file");
    }
  };

  const data = tableData
    // .filter((item) =>
    //   item.orderNumber.toLowerCase().includes(searchText.toLowerCase())
    // )
    .map((item, index) => ({
      ...item,
      key: index.toString(),
      sno: index + 1,
      status: (
        <Select
          key={reloadKey}
          defaultValue={item.status}
          style={{ width: 155, border: "none", boxShadow: "none" }}
          dropdownStyle={{ border: "none" }}
          onChange={(value) => handleStatusChange(value, item)}
        >
          <Select.Option value="SONG_RECORDING">
            <Tag color="purple">Recording</Tag>
          </Select.Option>
          <Select.Option value="DELIVERED">
            <Tag color="success">Delivered</Tag>
          </Select.Option>
        </Select>
      ),
      screenshot:
        item.screenshot !== null ? (
          <img
            src={BASE_URL + "/v1/form/song/" + item.screenshot} // Assuming upiPath contains the URL of the image
            alt=""
            style={{ maxWidth: "50%", maxHeight: "50%" }}
          />
        ) : (
          "-"
        ),
      clipPath: (
        <AudioPlayer
          src={BASE_URL + "/v1/form/song/" + item.clipPath}
          item={item}
          isPlaying={playingIndex === index}
          onPlay={() => handlePlay(index)}
          onPause={handlePause}
        />
      ),
      comment: (
        <TextArea
          key={reloadKey}
          placeholder="Enter Comment"
          variant="borderless"
          onChange={handleTextChange}
        />
      ),
      deliveryDate:
        item.deliveryDate !== null
          ? item.deliveryDate
          : item.customerDeliveryDate,
      orderTotal: item.orderTotal !== null ? item.orderTotal : "-",
      song: (
        <div className="choose-audio-input rounded">
          <label htmlFor={`fileInput-${item.key}`} className="file-field">
            <div className="choose-file-btn">Choose File</div>
            <input
              className="choose-file-hidden"
              type="file"
              accept="audio/mp3,audio/wav"
              id={`fileInput-${item.key}`}
              onChange={(e) => onFileSelected(e, item)}
            />
          </label>
          <span>{item.file1Name}</span>
        </div>
      ),
    }));

  const originalColumns = [
    {
      title: "Created Date",
      width: 110,
      dataIndex: "createdDate",
      fixed: "left",
      render: (createdDate) => moment(createdDate).format("DD-MM-YYYY"),
    },
    {
      title: "Order Number",
      width: 100,
      dataIndex: "orderNumber",
      fixed: "left",
    },
    {
      title: "Client Name",
      width: 150,
      fixed: "left",
      dataIndex: "name",
    },
    {
      title: "Mobile Number",
      dataIndex: "phonenumber",
      width: 150,
    },
    {
      title: (
        <p>
          Email ID <br />
          <span className="small">Click the email id to copy</span>
        </p>
      ),
      dataIndex: "mail",
      width: 250,
      render: (email) => (
        <a
          onClick={(e) => handleFieldClick(e)}
          href={`mailto:${email}`}
          key={email}
          className="text-dark"
        >
          {email}
        </a>
      ),
    },
    {
      title: "Language",
      dataIndex: "lanuage",
      width: 130,
    },
    {
      title: "Artist",
      dataIndex: "artist",
      width: 130,
    },
    {
      title: "Instrument",
      dataIndex: "instrument",
      width: 150,
    },
    // {
    //   title: "Mood",
    //   dataIndex: "mood",
    //   width: 150,
    // },
    {
      title: "Song From",
      dataIndex: "songfrom",
      width: 150,
    },
    {
      title: "Song To",
      dataIndex: "songto",
      width: 150,
    },
    {
      title: "Occasion",
      dataIndex: "occasion",
      width: 150,
    },
    {
      title: "Story",
      dataIndex: "story",
      width: 400,
      render: (story) => <SingleLineText text={story} />,
    },
    {
      title: "Audio File",
      dataIndex: "clipPath",
      width: 280,
    },
    {
      title: "Payment Order Id",
      dataIndex: "paymentOrderId",
      width: 250,
    },
    {
      title: "Order Total",
      align: "center",
      width: 150,
      dataIndex: "orderTotal",
    },
    {
      title: "Payment Status",
      align: "center",
      width: 150,
      dataIndex: "paymentStatus",
      render: (status) => statusTag(status),
    },
    {
      title: "Status",
      width: 200,
      dataIndex: "status",
    },
    {
      title: "Recorded Song",
      dataIndex: "song",
      width: 300,
    },
    // {
    //   title: "Customer's Selected Date",
    //   dataIndex: "customerDeliveryDate",
    //   width: 150,
    // },
    // {
    //   title: "Delivery Date",
    //   dataIndex: "customerDeliveryDate",
    //   render: (_, record) =>
    //     record.deliveryDate !== ""
    //       ? record.deliveryDate
    //       : record.customerDeliveryDate,
    //   width: 150,
    // },
    {
      title: "Comment",
      dataIndex: "comment",
      width: 200,
    },
    {
      title: "Action",
      fixed: "right",
      dataIndex: "action",
      width: 100,
      align: "center",
      render: (_, record) => (
        <Popconfirm
          open={visible}
          onConfirm={confirm}
          onCancel={() => setVisible(false)}
          okText="Yes"
          cancelText="No"
        >
          <Button
            size="small"
            onClick={() => changeStatus(record)}
            icon={<CheckOutlined />}
          />
        </Popconfirm>
      ),
    },
  ];
  let columns = originalColumns;

  const admin = localStorage.getItem("token");
  if (admin != 1) {
    const columnsToRemove = [
      "phonenumber",
      "screenshot",
      "orderTotal",
      "mail",
      "paymentOrderId",
    ];
    columns = originalColumns.filter(
      (column) => !columnsToRemove.includes(column.dataIndex)
    );
  }

  const fetchSaved = () => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(BASE_URL + "/v1/form/?position=SONG_RECORDING", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // responseType: "arraybuffer",
      })
      .then((response) => response.data)
      .then((res_data) => {
        const updatedData = res_data.data.formDtos.forms.map((item, index) => ({
          ...item,
          key: index.toString(),
          file1Name: "",
        }));
        setRecordingSongCount(res_data.data.formDtos.count);
        setTableData(updatedData);
        setOriginalData(updatedData);
      });
  };

  const getDeliveredCount = () => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(BASE_URL + "/v1/form/?position=DELIVERED", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // responseType: "arraybuffer",
      })
      .then((response) => response.data)
      .then((res_data) => {
        setDeliveredSongCount(res_data.data.formDtos.count);
      });
  };

  useEffect(() => {
    fetchSaved();
  }, [additionalProp1 == "5"]);

  // UseEffect to filter the table data based on search text
  useEffect(() => {
    if (searchText === "") {
      setTableData(originalData); // Reset to original data if search text is empty
    } else {
      const filteredData = originalData.filter((item) => {
        const searchTextLower = searchText.toLowerCase();
        const createdDateStr = moment(item.createdDate)
          .format("DD-MM-YYYY")
          .toLowerCase();
        const customerDeliveryDateStr = moment(item.customerDeliveryDate)
          .format("DD-MM-YYYY")
          .toLowerCase();
        return (
          item.name.toLowerCase().includes(searchTextLower) ||
          item.orderNumber.toLowerCase().includes(searchTextLower) ||
          // item.lanuage.toLowerCase().includes(searchTextLower) ||
          // createdDateStr.includes(searchTextLower) ||
          item.phonenumber.includes(searchText) || // phonenumber as string comparison
          item.mail.toLowerCase().includes(searchTextLower)
          //  ||
          // item.artist.toLowerCase().includes(searchTextLower) ||
          // item.instrument.toLowerCase().includes(searchTextLower) ||
          // item.mood.toLowerCase().includes(searchTextLower) ||
          // item.songfrom.toLowerCase().includes(searchTextLower) ||
          // item.songto.toLowerCase().includes(searchTextLower) ||
          // item.occasion.toLowerCase().includes(searchTextLower) ||
          // item.story.toLowerCase().includes(searchTextLower) ||
          // // (item.status.toLowerCase() ?? "").includes(searchTextLower) || // handle dropdown status
          // customerDeliveryDateStr.includes(searchTextLower) ||
          // (item.comments?.toLowerCase() ?? "").includes(searchTextLower) // handle null/undefined comments
        );
      });
      setTableData(filteredData);
    }
  }, [searchText, originalData]);

  // Effect to clear search text when data changes
  useEffect(() => {
    setSearchText(""); // Clear search text when originalData changes
  }, [originalData]);

  // Reset pagination when the table changes
  useEffect(() => {
    setPagination({ current: 1, pageSize: 10 });
  }, [tableData]);

  return (
    <div>
      <Spin
        spinning={loading}
        fullscreen
        className="custom-spin"
        size="large"
        tip="Loading"
      />
      <Input
        size="small"
        className="search fs-14 fw-6 p-2 px-3 my-3"
        placeholder="Search"
        prefix={<i className="bi bi-search"></i>}
        style={{ float: "right", width: "200px" }}
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText} // Controlled input
      />
      <Table
        style={{ fontWeight: "600" }}
        // rowKey="orderID"
        columns={columns}
        dataSource={data}
        scroll={{
          x: 3200,
          y: "60svh",
        }}
        // pagination={true}
        pagination={pagination} // Pass pagination state to the Table component
        onChange={(pagination) => setPagination(pagination)} // Update pagination state when pagination changes
        bordered
      />
    </div>
  );
};
