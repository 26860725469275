import React from "react";

const Tab = ({ activeTab, setActiveTab }) => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="tab-wrapper d-flex justify-content-between row pe-0">
            <div className="container">
              <div className="row">
                <div className="col-12 ps-3 ">
                  <div className=" tab-container mt-5 ms-md-5 ms-3 ">
                    <div
                      className={`tab ${activeTab === 0 ? "active" : ""}`}
                      // onClick={() => setActiveTab(0)}
                    >
                      <img
                        src={require("../../assets/images/tab-img1.png")}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div
                      className={`tab ${activeTab === 1 ? "active" : ""}`}
                      // onClick={() => setActiveTab(1)}
                    >
                      <img
                        src={require("../../assets/images/tab-img2.png")}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div
                      className={`tab ${activeTab === 2 ? "active" : ""}`}
                      // onClick={() => setActiveTab(2)}
                    >
                      <img
                        src={require("../../assets/images/tab-img3.png")}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div
                      className={`tab ${activeTab === 3 ? "active" : ""}`}
                      // onClick={() => setActiveTab(3)}
                    >
                      <img
                        src={require("../../assets/images/tab-img4.png")}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="img-wrapper d-inline d-md-none col-4 p-0">
              {activeTab === 0 && (
                <div className="tab-content">
                  <img
                    src={require("../../assets/images/firsttab-img.png")}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              )}
              {activeTab === 1 && (
                <div className="tab-content">
                  <img
                    src={require("../../assets/images/basic-bg-img.png")}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              )}
              {activeTab === 2 && (
                <div className="tab-content">
                  <img
                    src={require("../../assets/images/details-bg-img.png")}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              )}
              {activeTab === 3 && (
                <div className="tab-content">
                  <img
                    src={require("../../assets/images/payment-bg.png")}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tab;
