import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Drawer,
  Input,
  message,
  Popconfirm,
  Switch,
  Spin,
} from "antd";
import { useNavigate } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useAuth } from "./AuthContext";
import "./login.css";
import axios from "axios";
import { BASE_URL } from "../../..";
import { jwtDecode } from "jwt-decode";

export const Login = () => {
  const [input, setInput] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(true);

  const { login } = useAuth();
  const navigate = useNavigate();

  // const handleLogin = () => {
  //   // Perform your login logic here (e.g., API call)
  //   // If login is successful, call the login function
  //   login();
  // };
  useEffect(() => {
    checkLogin();
  }, []);

  const checkLogin = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      await axios.get(`${BASE_URL}/v1/form/paginated?sortDirection=DESC`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },

        // responseType: "arraybuffer",
      });

      login();
    } catch (error) {
      setLoading(false);
      console.error("Error creating post:", error);
    }
  };

  const goForgotPassword = () => {
    navigate("/forgotPassword");
    // Perform your login logic here (e.g., API call)
    // If login is successful, call the login function
    // login();
  };
  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    // login();
    const errors = {};
    if (!input.email) {
      errors.email = "email is required";
    }

    if (!input.password) {
      errors.password = "password is required";
    }

    // If there are errors, set them to state and prevent form submission
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    let privilege_arr = ["_id", "email", "mobile", "roleId", "username"];
    const payload = {
      email: input.email,
      password: input.password,
      appId: "app8",
      privilege: privilege_arr,
    };

    try {
      const response = await axios.post(
        `${BASE_URL}/v1/authentication/login`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      message.success("Login Successfully");
      if (response.data.data.accessToken != "") {
        const token = response.data.data.accessToken;
        const roleId = response.data.data.userDetails.roleId;
        const decoded_token = jwtDecode(token);
        localStorage.setItem("accessToken", token);
        localStorage.setItem("token", decoded_token.userDetails.roleId);
        localStorage.setItem("username", decoded_token.userDetails.username);
        login();
      }
    } catch (error) {
      message.error(error.response.data.message);
      console.error("Error:", error);
    }

    setErrors({});
    // setInput({ name: "", price: "" });
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });

    // Clear the error message for this field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear the error message for this field
    }));
  };

  return (
    <div className="login_background">
      {loading ? (
        <Spin
          spinning={loading}
          fullscreen
          className="custom-spin"
          size="large"
          tip="Loading"
        />
      ) : (
        <div className="login-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-6 d-none d-md-block img-fluid">
                <img
                  src={require("../../../assets/images/image_143.png")}
                  className="side_image"
                  alt=""
                />
              </div>
              <div className="col-md-6 col-12 ">
                <div className="login-entry flex-center">
                  <div className="login_outer">
                    <div className="login_content flex-center">
                      <img
                        src={require("../../../assets/images/logo.png")}
                        alt=""
                        className=""
                      />

                      <div className="login_top flex-center">Log in</div>
                    </div>

                    <div className="">
                      <form onSubmit={handleSubmit}>
                        <div>
                          <label
                            htmlFor="email"
                            className="side-nav-label fw-5 fs-16 c_454F5B"
                          >
                            Email
                          </label>
                          <span className="text-danger">*</span>
                          <br />
                          <Input
                            type="email"
                            className="rounded input_field"
                            name="email"
                            placeholder="Enter Email ID"
                            id="email"
                            onChange={handleInput}
                            value={input.email}
                          />
                          {errors.email && (
                            <div className="text-danger">{errors.email}</div>
                          )}
                        </div>
                        <div>
                          <label
                            htmlFor="password"
                            className="side-nav-label mt-4 fw-5 fs-16 c_454F5B"
                          >
                            Password
                          </label>
                          <span className="text-danger">*</span>
                          <br />
                          <Input.Password
                            type="text"
                            className="rounded input_field"
                            name="password"
                            placeholder="Enter Password"
                            id="password"
                            onChange={handleInput}
                            value={input.password}
                          />
                          {/* <Input.Password placeholder="input password" /> */}

                          {errors.password && (
                            <div className="text-danger">{errors.password}</div>
                          )}
                        </div>
                        <div className="forgot_pw" onClick={goForgotPassword}>
                          Forgot password?
                        </div>
                        <div className="">
                          <Button
                            size="large"
                            className="login_btn"
                            htmlType="submit"
                          >
                            Log in
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
