import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Drawer,
  Input,
  message,
  Popconfirm,
  Switch,
} from "antd";
import axios from "axios";
import { BASE_URL } from "../../..";

export const Artist = () => {
  const [editArtistData, setEditArtistData] = useState({
    name: "",
    price: "",
  });
  const [editID, setEditID] = useState("");
  const [tableData, setTableData] = useState([]);
  const [input, setInput] = useState({ name: "", price: "" });

  const [newopen, setNewOpen] = useState(false);

  const [errors, setErrors] = useState({});
  const fetchData = () => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(`${BASE_URL}/v1/artistmaster/getall`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // params: data.params,
        // responseType: "arraybuffer",
      })
      .then((response) => response.data)
      .then((res_data) => {
        setTableData(res_data.data.artist);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onClose = () => {
    setNewOpen(false);
    setErrors({});
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    e.preventDefault();
    const errors = {};

    if (!editArtistData.price) {
      errors.Artistprice = "Price is required";
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    let data = {
      artist: editArtistData.artist,
      price: editArtistData.price,
    };
    const token = localStorage.getItem("accessToken");
    axios

      .put(BASE_URL + "/v1/artistmaster/" + editID, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // params: data.params,
        // responseType: "arraybuffer",
      })
      .then(() => {
        fetchData();
        message.success("Updated Successfully");
      })
      .catch((error) => {
        message.error(error.response.data.message);
      });
    setNewOpen(false);
  };

  const handleInput = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const handleEdit = (id) => {
    setEditID(id);
    setNewOpen(true);
    const token = localStorage.getItem("accessToken");
    axios
      .get(BASE_URL + "/v1/artistmaster/" + id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // params: data.params,
        // responseType: "arraybuffer",
      })
      .then((response) => response.data)
      .then((res_data) => {
        setEditArtistData(res_data.data.artistMaster);
      });
  };
  const handleKeyPressNumber = (e) => {
    const charCode = e.charCode;
    if (!/[0-9]/.test(String.fromCharCode(charCode))) {
      e.preventDefault();
    }
  };
  const handleEditInput = (e) => {
    const { name, value } = e.target;
    setEditArtistData({ ...editArtistData, [name]: value });

    // Clear the error message for this field
    setErrors((prevErrors) => ({
      ...prevErrors,
      Artistprice: "", // Clear the error message for this field
    }));
  };

  const columns = [
    { title: "SI.No", dataIndex: "sno", width: 150, align: "center" },
    { title: "Artist", dataIndex: "artist", width: 250 },
    { title: "Price", dataIndex: "price", width: 750 },
    {
      title: "Action",
      align: "center",
      dataIndex: "actions",
      width: 200,
      fixed: "right",
    },
  ];

  const data = tableData.map((item, index) => ({
    ...item,
    key: index.toString(),
    sno: index + 1,
    actions: (
      <div>
        <Button
          type="link"
          onClick={() => handleEdit(item.id)}
          className="edit-hover"
        >
          <i className="bi bi-pencil-fill sec-clr edit-icon-hover"></i>
        </Button>
      </div>
    ),
  }));

  return (
    <>
      <Table
        scroll={{ x: 0, y: "50svh" }}
        columns={columns}
        dataSource={data}
        bordered
        pagination={true}
      />

      {/* <Tabx  */}
      <Drawer open={newopen} closable={false} onClose={onClose}>
        <div className="nav-content">
          <div className="d-flex justify-content-between sec-clr nav-title align-items-center">
            <h2 className="pri-clr fw-7 fs-30">
              {editID ? "Edit Artist Price" : "Add New Language"}
            </h2>
            <i
              className="bi bi-x fs-26 fw-bold close-btn"
              onClick={onClose}
            ></i>
          </div>
          <div className="">
            <form onSubmit={handleEditSubmit}>
              <div>
                <label
                  htmlFor="name"
                  className="side-nav-label fw-5 fs-16 c_454F5B"
                >
                  Artist Gender
                </label>
                <span className="text-danger">*</span>
                <br />
                <Input
                  type="text"
                  className="side-nav-input rounded"
                  name="name"
                  //   placeholder="Enter Language Name"
                  id="name"
                  onChange={editID ? handleEditInput : handleInput}
                  value={editID ? editArtistData.artist : input.name}
                  readOnly
                />
              </div>
              <div>
                <label
                  htmlFor="price"
                  className="side-nav-label fw-5 fs-16 mt-4 c_454F5B"
                >
                  Price
                </label>
                <span className="text-danger">*</span>
                <br />
                <Input
                  type="text"
                  className="side-nav-input rounded"
                  name="price"
                  placeholder="Enter Price"
                  id="price"
                  onKeyPress={handleKeyPressNumber}
                  onChange={editID ? handleEditInput : handleInput}
                  value={editID ? editArtistData.price : input.price}
                />
                {errors.Artistprice && (
                  <div className="text-danger">{errors.Artistprice}</div>
                )}
              </div>
              <div className="button-side-nav d-flex justify-content-center">
                <Button size="large" className="button-bg" htmlType="submit">
                  Update
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Drawer>
    </>
  );
};
