import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Table,
  DatePicker,
  message,
  Popconfirm,
  Spin,
} from "antd";
import { Songdata } from "./SongData";
import TextArea from "antd/es/input/TextArea";
import SingleLineText from "./Components/SingleLineText";
import AudioPlayer from "./Components/AudioPlayer";
import DropdownSongStatus from "./Components/DropdownSongStatus";
import DateSelector from "./Components/DateSelector";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import axios from "axios";
import dayjs from "dayjs";
import moment from "moment";
import { Select, Tag } from "antd";
import { BASE_URL } from "../../..";
import {
  DownloadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import { Image, Space } from "antd";
import { set } from "date-fns";

export const Processing = ({
  handleFieldClick,
  setSavedSongCount,
  setConfirmedSongCount,
  additionalProp1,
  setRejectedSongCount,
}) => {
  const [tableData, setTableData] = useState([]); // Initialize as an empty array
  const [adminId, setadminId] = useState(localStorage.getItem("token"));
  const [statusValue, setStatusValue] = useState("");
  const [searchText, setSearchText] = useState(""); // State for search input
  const [adminDeliveryDate, setAdminDeliveryDate] = useState(""); // State for search input
  const [adminComment, setAdminComment] = useState(""); // State for search input
  const [visible, setVisible] = useState(false);
  const [reloadKey, setReloadKey] = useState(0);
  const [loading, setLoading] = useState(false);
  const [playingIndex, setPlayingIndex] = useState(null);
  const [rejectedData, setRejectedData] = useState();
  const [originalData, setOriginalData] = useState([]); // Original unfiltered data
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 }); // Pagination state

  const handlePlay = (index) => {
    setPlayingIndex(index);
  };

  const handlePause = () => {
    setPlayingIndex(null);
  };
  // const [input, setI]

  //   const handleReload = () => {
  //     window.location.reload();
  //   };
  const handleReload = () => {
    setReloadKey((prevKey) => prevKey + 1); // Incrementing key to force re-render
  };
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };
  const onDownload = (src) => {
    fetch(src)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = "image.png";
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      });
  };
  const changeStatus = (record) => {
    const formData = new FormData();
    if (statusValue === "") {
      setVisible(false);
      confirm(statusValue);
      // formData.append("orderPosition", record.status);
    } else {
      setLoading(true);

      formData.append("orderPosition", statusValue);
      const token = localStorage.getItem("accessToken");
      axios
        .put(BASE_URL + "/v1/form/" + record.id, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          // responseType: "arraybuffer",
        })
        .then(() => {
          setVisible(true);
          // confirm();
          setVisible(false);
          confirm(statusValue);
          handleReload();
          fetchSaved();
          setLoading(false);
          addCommentApi(record);
          getConfirmCount();
        })
        .catch((error) => {
          console.error("Data Saveing : " + error);
          setLoading(false);
        });

      setStatusValue("");
    }
  };
  const addCommentApi = (record) => {
    const form_Data = new FormData();
    form_Data.append("orderPosition", "ORDER_CONFIRMED");
    const token = localStorage.getItem("accessToken");
    axios
      .put(
        BASE_URL +
          "/v1/form/add-comment/" +
          record.orderNumber +
          "?" +
          "comment=" +
          adminComment +
          "&" +
          "deliverydate=" +
          adminDeliveryDate,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          // responseType: "arraybuffer",
        }
      )
      .then(() => {
        // fetchSaved();
        handleReload();
      })
      .catch((error) => {
        console.error("Data Saveing : " + error);
      });
    setAdminComment("");
    setAdminDeliveryDate("");
  };
  const handleStatusChange = (value, record) => {
    setStatusValue(value);
  };
  const onChange = (date, dateString) => {
    setAdminDeliveryDate(dateString);
  };
  const handleTextChange = (e) => {
    setAdminComment(e.target.value);
  };
  const confirm = (value) => {
    setVisible(false);
    if (value !== "") {
      message.success("Updated Successfully");
    } else {
      message.error("Please select the status as confirmed");
    }
  };
  const rejectFunc = (record) => {
    setRejectedData(record);
  };
  const statusTag = (status) => {
    let color = "default";
    let text = "N/A";

    switch (status) {
      case "paid":
        color = "green";
        text = "Success";
        break;
      case "attempted":
        color = "red";
        text = "Failed";
        break;
      default:
        color = "grey";
        text = "Pending";
    }

    return <Tag color={color}>{text}</Tag>;
  };
  const reject = () => {
    const token = localStorage.getItem("accessToken");
    axios
      .put(
        BASE_URL +
          "/v1/form/rejectForm/" +
          rejectedData.id +
          "?formStatus=inactive",
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        getRejectedCount();
        message.success("Rejected Successfully");
        fetchSaved();
      });
  };

  const data = tableData
    // .filter((item) =>
    //   item.orderNumber.toLowerCase().includes(searchText.toLowerCase())
    // )
    .map((item, index) => ({
      ...item,
      key: index.toString(),
      sno: index + 1,
      status: (
        <>
          <Select
            key={reloadKey}
            defaultValue={item.status}
            style={{ width: 155, border: "none", boxShadow: "none" }}
            dropdownStyle={{ border: "none" }}
            onChange={(value) => handleStatusChange(value, item)}
          >
            <Select.Option value="ORDER_SAVED">
              <Tag color="processing">Order saved</Tag>
            </Select.Option>
            <Select.Option value="ORDER_CONFIRMED">
              <Tag color="warning">Order confirmed</Tag>
            </Select.Option>
          </Select>
        </>
      ),

      screenshot:
        item.screenshot !== null ? (
          <Image
            width={140}
            src={BASE_URL + "/v1/form/song/" + item.screenshot}
            style={{ maxWidth: "50%", maxHeight: "50%" }}
            preview={{
              toolbarRender: (
                _,
                {
                  transform: { scale },
                  actions: {
                    onFlipY,
                    onFlipX,
                    onRotateLeft,
                    onRotateRight,
                    onZoomOut,
                    onZoomIn,
                  },
                }
              ) => (
                <Space size={12} className="toolbar-wrapper">
                  <DownloadOutlined
                    onClick={() =>
                      onDownload(BASE_URL + "/v1/form/song/" + item.screenshot)
                    }
                  />
                  <SwapOutlined rotate={90} onClick={onFlipY} />
                  <SwapOutlined onClick={onFlipX} />
                  <RotateLeftOutlined onClick={onRotateLeft} />
                  <RotateRightOutlined onClick={onRotateRight} />
                  <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                  <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                </Space>
              ),
            }}
          />
        ) : (
          "-"
        ),
      comment: (
        <TextArea
          key={reloadKey}
          placeholder="Enter Comment"
          variant="borderless"
          onChange={handleTextChange}
        />
      ),
      clipPath: (
        <AudioPlayer
          src={BASE_URL + "/v1/form/song/" + item.clipPath}
          item={item}
          isPlaying={playingIndex === index}
          onPlay={() => handlePlay(index)}
          onPause={handlePause}
        />
      ),
    }));

  // =====================  Table Column Header Data =======================
  const originalColumns = [
    {
      title: "Created Date",
      width: 110,
      dataIndex: "createdDate",
      fixed: "left",
      render: (createdDate) => moment(createdDate).format("DD-MM-YYYY"),
    },
    {
      title: "Order Number",
      width: 100,
      dataIndex: "orderNumber",
      fixed: "left",
    },
    {
      title: "Client Name",
      width: 150,
      fixed: "left",
      dataIndex: "name",
    },
    {
      title: "Mobile Number",
      dataIndex: "phonenumber",
      width: 150,
    },
    {
      title: (
        <p>
          Email ID <br />
          <span className="small">Click the email id to copy</span>
        </p>
      ),
      dataIndex: "mail",
      width: 250,
      render: (email) => {
        return (
          <a
            onClick={(e) => handleFieldClick(e)}
            href={`mailto:${email}`}
            key={email}
            className="text-dark"
          >
            {email}
          </a>
        );
      },
    },
    {
      title: "Language",
      dataIndex: "lanuage",
      width: 130,
    },
    {
      title: "Artist",
      dataIndex: "artist",
      width: 130,
    },
    {
      title: "Instrument",
      dataIndex: "instrument",
      width: 150,
    },
    // {
    //   title: "Inst. Version",
    //   dataIndex: "instVersion",
    //   width: 150,
    // },
    // {
    //   title: "Mood",
    //   dataIndex: "mood",
    //   width: 150,
    // },
    {
      title: "Song From",
      dataIndex: "songfrom",
      width: 150,
    },
    {
      title: "Song To",
      dataIndex: "songto",
      width: 150,
    },
    {
      title: "Occasion",
      dataIndex: "occasion",
      width: 150,
    },
    {
      title: "Story",
      dataIndex: "story",
      width: 400,
      render: (story) => {
        return <SingleLineText text={story} />;
      },
    },
    {
      title: "Audio File",
      dataIndex: "clipPath",
      width: 280,
      // render: (mp3) => {
      //   return <AudioPlayer src={mp3} />;
      // },
    },
    {
      title: "Payment Order Id",
      dataIndex: "paymentOrderId",
      width: 250,
    },
    {
      title: "Order Total",
      align: "center",
      width: 150,
      dataIndex: "orderTotal",
    },
    {
      title: "Payment Status",
      align: "center",
      width: 150,
      dataIndex: "paymentStatus",
      render: (status) => statusTag(status),
    },
    {
      title: "Status",
      width: 200,
      dataIndex: "status",
    },
    {
      title: "Customer's Selected Date",
      dataIndex: "customerDeliveryDate",
      // render: (customerExpect) => {
      //   return <span>{customerExpect}</span>;
      // },
      width: 150,
    },
    // {
    //   title: "Delivery Date",
    //   dataIndex: "deliveryDate",
    //   render: () => (
    //     <DatePicker
    //       onChange={onChange}
    //       key={reloadKey}
    //       disabledDate={disabledDate}
    //     />
    //   ),
    //   width: 150,
    // },
    {
      title: "Comment",
      dataIndex: "comment",
      width: 200,
    },
    {
      title: "Action",
      fixed: "right",
      dataIndex: "action",
      width: 120,
      align: "center",
      render: (_, record) => (
        <div>
          <Popconfirm
            open={visible}
            onConfirm={confirm}
            onCancel={() => setVisible(false)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              size="small"
              onClick={() => changeStatus(record)}
              icon={<CheckOutlined />}
            />
          </Popconfirm>
          {/* <Popconfirm
            title="Are you sure to delete?"
            onConfirm={reject}
            okText="Yes"
            cancelText="No"
          >
            <Button
              size="small"
              className="mx-2"
              icon={<CloseOutlined />}
              onClick={() => rejectFunc(record)}
            />
          </Popconfirm> */}
        </div>
      ),
    },
  ];

  let columns = originalColumns;

  const admin = localStorage.getItem("token");
  if (admin != 1) {
    const columnsToRemove = [
      "phonenumber",
      "screenshot",
      "orderTotal",
      "mail",
      "paymentOrderId",
    ];
    columns = originalColumns.filter(
      (column) => !columnsToRemove.includes(column.dataIndex)
    );
  }

  const fetchSaved = () => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(BASE_URL + "/v1/form/?position=ORDER_SAVED", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // responseType: "arraybuffer",
      })
      .then((response) => response.data)
      .then((res_data) => {
        setSavedSongCount(res_data.data.formDtos.count);
        setTableData(res_data.data.formDtos.forms);
        setOriginalData(res_data.data.formDtos.forms);
      });
  };

  const getConfirmCount = () => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(BASE_URL + "/v1/form/?position=ORDER_CONFIRMED", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // responseType: "arraybuffer",
      })
      .then((response) => response.data)
      .then((res_data) => {
        setConfirmedSongCount(res_data.data.formDtos.count);
        // setTableData(res_data.data.formDtos.forms);
      });
  };
  const getRejectedCount = () => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(BASE_URL + "/v1/form/getrejectedforms?activeStatus=inactive", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // responseType: "arraybuffer",
      })
      .then((response) => response.data)
      .then((res_data) => {
        setRejectedSongCount(res_data.data.formRequestDtos.count);
        // setTableData(res_data.data.formDtos.forms);
      });
  };

  // xxxxxxxxxxxxxxxxxx  Table Column Header Data End xxxxxxxxxxxxxxxxxx

  // =====================  Table Body Data =======================
  useEffect(() => {
    fetchSaved();
  }, [additionalProp1 == "2"]);

  // UseEffect to filter the table data based on search text
  useEffect(() => {
    if (searchText === "") {
      setTableData(originalData); // Reset to original data if search text is empty
    } else {
      const filteredData = originalData.filter((item) => {
        const searchTextLower = searchText.toLowerCase();
        const createdDateStr = moment(item.createdDate)
          .format("DD-MM-YYYY")
          .toLowerCase();
        const customerDeliveryDateStr = moment(item.customerDeliveryDate)
          .format("DD-MM-YYYY")
          .toLowerCase();
        return (
          item.name.toLowerCase().includes(searchTextLower) ||
          item.orderNumber.toLowerCase().includes(searchTextLower) ||
          // item.lanuage.toLowerCase().includes(searchTextLower) ||
          // createdDateStr.includes(searchTextLower) ||
          item.phonenumber.includes(searchText) || // phonenumber as string comparison
          item.mail.toLowerCase().includes(searchTextLower)
          // ||
          //  item.artist.toLowerCase().includes(searchTextLower) ||
          // item.instrument.toLowerCase().includes(searchTextLower) ||
          // item.mood.toLowerCase().includes(searchTextLower) ||
          // item.songfrom.toLowerCase().includes(searchTextLower) ||
          // item.songto.toLowerCase().includes(searchTextLower) ||
          // item.occasion.toLowerCase().includes(searchTextLower) ||
          // item.story.toLowerCase().includes(searchTextLower) ||
          // (item.status.toLowerCase() ?? "").includes(searchTextLower) || // handle dropdown status
          // customerDeliveryDateStr.includes(searchTextLower) ||
          // (item.comments?.toLowerCase() ?? "").includes(searchTextLower) // handle null/undefined comments
        );
      });
      setTableData(filteredData);
    }
  }, [searchText, originalData]);

  // Effect to clear search text when data changes
  useEffect(() => {
    setSearchText(""); // Clear search text when originalData changes
  }, [originalData]);

  // Reset pagination when the table changes
  useEffect(() => {
    setPagination({ current: 1, pageSize: 10 });
  }, [tableData]);

  return (
    <div>
      <Spin
        spinning={loading}
        fullscreen
        className="custom-spin"
        size="large"
        tip="Loading"
      />
      <Input
        size="small"
        className="search fs-14 fw-6 p-2 px-3 my-3"
        placeholder="Search"
        prefix={<i className="bi bi-search"></i>}
        style={{ float: "right", width: "200px" }}
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText} // Controlled input
      />
      <Table
        style={{ fontWeight: "600" }}
        // rowKey="orderID"
        columns={columns}
        dataSource={data}
        scroll={{
          x: 3200,
          y: "60svh",
        }}
        // pagination={true}
        pagination={pagination} // Pass pagination state to the Table component
        onChange={(pagination) => setPagination(pagination)} // Update pagination state when pagination changes
        bordered
      />{" "}
    </div>
  );
};
