import React, { useState, useEffect } from "react";
import { Table, Button, Drawer, Input, message, Checkbox } from "antd";
import axios from "axios";
import { BASE_URL } from "../../..";

export const LightBox = () => {
  const [editArtistData, setEditArtistData] = useState({
    name: "",
    price: "",
  });
  const [editID, setEditID] = useState("");
  const [tableData, setTableData] = useState([]);
  const [input, setInput] = useState({ name: "" });
  const [file1Name, setFile1Name] = useState("");
  const [newopen, setNewOpen] = useState(false);
  const [editValue, setEditValue] = useState(false);

  const [flag, setFlag] = useState(false);
  const fetchLightBoxVideo = () => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(BASE_URL + "/v1/welcomeVideo/getAllWelcomeVideos", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .then((res_data) => {
        setTableData(res_data.data.welcomeVideoResponse.welcomeVideos);
      });
  };
  const handleCheckboxChange = (e) => setFlag(e.target.checked);
  const onFileSelected = (event) => {
    const file = event.target.files[0]; // Get the selected file
    setFile1Name(file?.name);
    setEditValue(true);
  };

  useEffect(() => {
    fetchLightBoxVideo();
  }, []);

  const onClose = () => {
    setNewOpen(false);
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("videoFlag", flag);
    if (editValue) {
      formData.append(
        "videoClip",
        document.getElementById("fileInput").files[0]
      );
    }
    const token = localStorage.getItem("accessToken");

    axios
      .put(BASE_URL + "/v1/welcomeVideo/updateVideo/" + editID, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        fetchLightBoxVideo();

        message.success("Updated Successfully");
        setNewOpen(false);
        setEditValue(false);
      })
      .catch((error) => {
        message.error(error.response?.data?.message || "Update failed");
      });
  };

  const handleEdit = (id) => {
    setEditID(id);
    setNewOpen(true);
    const token = localStorage.getItem("accessToken");

    axios
      .get(BASE_URL + "/v1/welcomeVideo/" + id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .then((res_data) => {
        setEditArtistData(res_data.data.welcomeVideoResponse.welcomeVideos);
        const updatedRes =
          res_data.data.welcomeVideoResponse.welcomeVideos.videoName.substring(
            res_data.data.welcomeVideoResponse.welcomeVideos.videoName.indexOf(
              "-"
            ) + 1
          );

        setFile1Name(updatedRes);
        setInput({
          name: res_data.data.welcomeVideoResponse.welcomeVideos.videoType,
        });
        setFlag(res_data.data.welcomeVideoResponse.welcomeVideos.videoFlag);
      });
  };

  const columns = [
    {
      title: "Visibility",
      dataIndex: "videoFlag",
      width: "20%",
      align: "center",
    },
    {
      title: "View",
      dataIndex: "videoType",
      width: "20%",
      align: "center",
    },
    {
      title: "Video",
      dataIndex: "videoName",
      width: "60%",
      align: "center",
    },
    {
      title: "Action",
      align: "center",
      dataIndex: "actions",
      width: 200,
      fixed: "right",
    },
  ];

  const data = tableData.map((item, index) => ({
    ...item,
    key: index.toString(),
    sno: index + 1,
    videoFlag: item.videoFlag ? (
      <i className="bi bi-eye-fill text-success fs-26"></i>
    ) : (
      <i className="bi bi-eye-slash-fill text-danger fs-26"></i>
    ),
    actions: (
      <div>
        <Button
          type="link"
          onClick={() => handleEdit(item.id)}
          className="edit-hover"
        >
          <i className="bi bi-pencil-fill sec-clr edit-icon-hover"></i>
        </Button>
      </div>
    ),
    videoName: (
      <div>
        <video
          width="300"
          height="200"
          controls
          ref={(video) => {
            if (video) {
              video.load(); // Manually reload the video whenever component re-renders
            }
          }}
          controlsList="nodownload"
        >
          <source
            src={`${BASE_URL}/v1/welcomeVideo/video/${item.videoName}`}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    ),
  }));

  return (
    <>
      <Table
        scroll={{ x: 0, y: "50svh" }}
        columns={columns}
        dataSource={data}
        bordered
        pagination={true}
      />

      {/* <Tabx  */}
      <Drawer
        open={newopen}
        closable={false}
        onClose={onClose}
        maskClosable={false}
      >
        <div className="nav-content">
          <div className="d-flex justify-content-between sec-clr nav-title align-items-center">
            <h2 className="pri-clr fw-7 fs-30">Edit {input.name} View Video</h2>
            <i
              className="bi bi-x fs-26 fw-bold close-btn"
              onClick={onClose}
            ></i>
          </div>
          <div className="">
            <form onSubmit={handleEditSubmit}>
              <div>
                <label
                  htmlFor="name"
                  className="side-nav-label fw-5 fs-16 c_454F5B"
                >
                  Visibility
                </label>
                {/* <span className="text-danger">*</span> */}
                <br />
                <Checkbox
                  checked={flag}
                  onChange={handleCheckboxChange}
                ></Checkbox>
                <span>
                  {flag ? (
                    <span className="text-success fw-5 mx-3">
                      Visibility Mode Enable
                    </span>
                  ) : (
                    <span className="text-danger fw-5 mx-3">
                      Visibility Mode Disable
                    </span>
                  )}
                </span>
              </div>
              <div className="mt-4">
                <label
                  htmlFor="name"
                  className="side-nav-label fw-5 fs-16 c_454F5B"
                >
                  View
                </label>
                {/* <span className="text-danger">*</span> */}
                <br />
                <Input
                  type="text"
                  className="side-nav-input rounded fw-5"
                  name="name"
                  //   placeholder="Enter Language Name"
                  id="name"
                  value={input.name}
                  readOnly
                />
              </div>
              <div>
                <label
                  htmlFor="price"
                  className="side-nav-label fw-5 fs-16 mt-4 c_454F5B"
                >
                  Choose Video
                </label>
                <div className="side-nav-input rounded">
                  <label htmlFor="fileInput" className="file-field">
                    <div className="choose-file-btn">Choose File</div>
                    <input
                      className="choose-file-hidden"
                      type="file"
                      id="fileInput"
                      accept="video/*"
                      onChange={onFileSelected}
                    />
                  </label>
                  <span>{file1Name}</span>
                </div>
              </div>
              <div className="button-side-nav d-flex justify-content-center">
                <Button size="large" className="button-bg" htmlType="submit">
                  Update
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Drawer>
    </>
  );
};
export default LightBox;
