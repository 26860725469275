import React, { useState } from "react";

const SingleLineText = ({ text }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={`single-line-text ${expanded ? "expanded" : ""}`}>
      <p>{text}</p>
      {text ? (
        <button className="read-more-btn" onClick={toggleExpand}>
          {!expanded ? "Read more" : "Read less"}
        </button>
      ) : null}
    </div>
  );
};

export default SingleLineText;
