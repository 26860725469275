import React from "react";
import { Route, Routes } from "react-router-dom";
import { AuthProvider, useAuth } from "./pages/Admin/AdminLogin/AuthContext";
import PrivateRoute from "./pages/Admin/AdminLogin/PrivateRoute";
import CreateSongMain from "./pages/Create_song/CreateSongMain";
import Home from "./pages/Home/HomeMain";
import LandingPage from "./pages/LandingPage";
import MailConfig from "./pages/MailConfig";
import AdminBase from "./pages/Admin/AdminBase";
import AboutUs from "./pages/AboutUs/AboutUs";
import ContactUs from "./pages/ContactUs/ContactUs";
import MailTemplate from "./pages/Home/MailTemplate";
import { MailTemplate2 } from "./pages/Home/MailTemplate2";
import { AddReview } from "./pages/Home/AddReview";
import VerifyDetails from "./pages/Create_song/VerifyDetails";
import Order from "./pages/Order_tracking";
import { Login } from "./pages/Admin/AdminLogin/Login";
import ForgotPassword from "./pages/Admin/AdminLogin/Forgot";
import { NewPassword } from "./pages/Admin/AdminLogin/NewPassword";
import { TermsAndCondition } from "./pages/TermsAndCondition";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { FrequentlyAndQuestion } from "./pages/FrequentlyAndQuestion";
import { OurProducts } from "./pages/OurProducts/OurProducts";
import SuccessFailure from "./pages/Create_song/PaymentSuccessFailed/SuccessFailure";
import OurProductsMob from "./pages/OurProducts/OurProductsMob";

const App = () => {
  return (
    <>
      <AuthProvider>
        <Routes>
          {/* <Route path="/" element={<LandingPage />} / >  */}
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/our-gifts" element={<OurProductsMob />} />
          <Route path="/contact" element={<ContactUs />} />
          {/* <Route path="/" element= {<MailConfig />} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/newpassword/:id" element={<NewPassword />} />
          <Route
            path="/backOffice"
            element={<PrivateRoute element={AdminBase} />}
          />
          {/* <Route path="/" element={<MailTemplate/>}/> */}
          <Route path="/faq" element={<FrequentlyAndQuestion />} />
          <Route path="/testadmin" element={<AdminBase />} />

          <Route path="/addReview/:id" element={<AddReview />} />
          <Route path="/create-a-song" element={<CreateSongMain />} />
          {/* <Route path="/" element={<VerifyDetails/>}/>   */}
          <Route path="/trackOrder/:id" element={<Order />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-and-conditions" element={<TermsAndCondition />} />
          <Route path="/payment-status" element={<SuccessFailure />} />
        </Routes>
      </AuthProvider>
    </>
  );
};

export default App;
