export const Masterdata = {
  version: [
    {
      sno: 1,
      versionName: "Simple Guitar",
      price: "Nil",
      sampleAudio:
        "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
      enable: "checked",
    },
    {
      sno: 2,
      versionName: "Advanced Guitar",
      price: "Rs.1200",
      sampleAudio: " ",
      enable: "unchecked",
    },
    {
      sno: 3,
      versionName: "Wedding piano",
      price: "Rs.500",
      sampleAudio: "sampleaudio.mp3",
      enable: "checked",
    },
    {
      sno: 4,
      versionName: "Party Drums",
      price: "Nil",
      sampleAudio: "audio (3).wav",
      enable: "unchecked",
    },
    {
      sno: 5,
      versionName: "Peace Flute",
      price: "Rs.2500",
      sampleAudio:
        "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
      enable: "checked",
    },
    {
      sno: 6,
      versionName: "Simple Drums",
      price: "Rs.2000",
      sampleAudio: " ",
      enable: "unchecked",
    },
    {
      sno: 7,
      versionName: "Wild Trumpet",
      price: "Rs.1500",
      sampleAudio:
        "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3",
      enable: "checked",
    },
  ],

  language: [
    {
      sno: 1,
      name: "Tamil",
      price: "Nil",
      enable: "checked",
    },
    {
      sno: 2,
      name: "French",
      price: "Rs.1200",
      enable: "unchecked",
    },
    {
      sno: 3,
      name: "Malayalam",
      price: "Rs.500",
      enable: "checked",
    },
    {
      sno: 4,
      name: "Telugu",
      price: "Rs.2000",
      enable: "unchecked",
    },
    {
      sno: 5,
      name: "Kannadam",
      price: "Rs.2500",
      enable: "checked",
    },
    {
      sno: 6,
      name: "English",
      price: "Nil",
      enable: "checked",
    },
    {
      sno: 7,
      name: "Spanish",
      price: "Rs.1500",
      enable: "unchecked",
    },
  ],

  mood: [
    {
      sno: 1,
      moodName: "Happy",
      enable: "checked",
    },
    {
      sno: 2,
      moodName: "Sad",
      enable: "unchecked",
    },
    {
      sno: 3,
      moodName: "Excited",
      enable: "checked",
    },
    {
      sno: 4,
      moodName: "Relaxed",
      enable: "checked",
    },
    {
      sno: 5,
      moodName: "Angry",
      enable: "unchecked",
    },
    {
      sno: 6,
      moodName: "Peaceful",
      enable: "checked",
    },
    {
      sno: 7,
      moodName: "Anxious",
      enable: "unchecked",
    },
  ],

  textFields: [
    {
      sno: 1,
      fieldsName: "Your Name",
      action: "False",
    },
    {
      sno: 2,
      fieldsName: "Mobile Number",
      action: "True",
    },
    {
      sno: 3,
      fieldsName: "Your Email id ",
      action: "False",
    },
    {
      sno: 4,
      fieldsName: "Language",
      action: "True",
    },
    {
      sno: 5,
      fieldsName: "Choose Version",
      action: "False",
    },
    {
      sno: 6,
      fieldsName: "Choose Mood",
      action: "True",
    },
    {
      sno: 7,
      fieldsName: "Who is the song to",
      action: "False",
    },
  ],
};
