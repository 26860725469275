import React, { useState, useRef } from "react";
import "./style.css"; // Assuming the CSS you provided is in style.css

const OTPVerification = () => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);

  const handleInputChange = (e, index) => {
    const value = e.target.value;

    if (isNaN(value) && value !== "") return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move focus to the next input field if the input length is 1
    if (value.length === 1 && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);

      // Move focus to the previous input field if backspace is pressed
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handleResendOTP = () => {
    // Logic for resending OTP
  };

  return (
    <div className="container">
      <div className="wrapper">
        {otp.map((_, index) => (
          <input
            key={index}
            type="text"
            className={`field ${index + 1}`}
            maxLength="1"
            value={otp[index]}
            onChange={(e) => handleInputChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            ref={(el) => (inputRefs.current[index] = el)}
          />
        ))}
      </div>
      {/* <button className="resend" onClick={handleResendOTP}>
        Resend OTP
        <i className="fa fa-caret-right"></i>
      </button> */}
    </div>
  );
};

export default OTPVerification;
