import React, { useEffect } from "react";
import logo from "../assets/images/logo.png";
import Footer from "../pages/Base/Footer.jsx";
import Navbar from "../pages/Base/Navbar";
import { useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

export const FrequentlyAndQuestion = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigateHome = () => {
    navigate("/");
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>Tune for 2 | Frequently Asked Questions (FAQ)</title>
        <meta
          name="description"
          content="Tune for 2 FAQs: Have questions about personalized songs? Our FAQ covers everything about custom songs. Find answers and start crafting today!"
          data-rh="true"
        />
        <link rel="canonical" href="https://tunefor2.com/faq"></link>
      </Helmet>
      <>
        <section id="privacyPolicyPage" className="bg-color">
          <Navbar terms={true} />
          {/* <div className="px-3 pt-3">
              <img src={logo} alt="" />
            </div> */}
          <div className="title mb-5 img_logo">
            <h2 className="fw-7">Frequently Asked Questions</h2>
          </div>
          <div className="whole_padding">
            {/* <div className="line_height fs-20 fw-4">
              These Terms and Conditions ("Terms") govern your use of the Tune
              For 2 website and services provided by Tune For 2 ("we", "us",
              "our"). By accessing or using our website and services, you agree
              to these Terms. If you do not agree with any part of these Terms,
              please do not use our website or services.
            </div> */}
            <div className="line_height fs-24 fw-7">
              1. What is a customised song?
            </div>
            <div className="line_height fs-20 fw-4">
              A customised song is a unique piece of music created specifically
              for you or your loved ones, based on your provided details such as
              memories, emotions, and special moments.
            </div>
            <div className="line_height fs-24 fw-7">
              2. How does the process work?
            </div>
            <div className="line_height fs-20 fw-4">
              The process is simple! You share your story and preferences with
              us, choose the style of the song, make the payment, and our
              talented musicians and lyricists will craft a personalized song
              just for you.
            </div>
            <div className="line_height fs-24 fw-7">
              3. What can I use a customised song for?{" "}
            </div>
            <div className="line_height fs-20 fw-4">
              Customised songs are perfect for special occasions such as
              weddings, anniversaries, birthdays, proposals, or as heartfelt
              gifts to express love and appreciation.
            </div>
            <div className="line_height fs-24 fw-7">
              4. How long does it take to receive my customised song?{" "}
            </div>
            <div className="line_height fs-20 fw-4">
              Typically, you will receive your personalised song on the
              mentioned date after finalizing the details and completing the
              payment. This timeline may vary based on the complexity of the
              project.
            </div>
            <div className="line_height fs-24 fw-7">
              5. Can I preview or request changes to the song before final
              delivery?{" "}
            </div>
            <div className="line_height fs-20 fw-4">
              Yes, we provide a preview of the song for your feedback. You can
              request minor revisions to ensure the song meets your expectations
              before we finalize and deliver it to you.
            </div>
            <div className="line_height fs-24 fw-7">
              6. What formats will I receive my customised song in?
            </div>
            <div className="line_height fs-20 fw-4">
              You will receive your customised song in high-quality digital
              formats suitable for sharing online and preserving as a keepsake.
            </div>
            <div className="line_height fs-24 fw-7">
              7. How much does a customised song cost?
            </div>
            <div className="line_height fs-20 fw-4">
              Our pricing varies based on the style of the song, complexity, and
              additional services chosen. Please contact us for a personalized
              quote based on your specific requirements.
            </div>
            <div className="line_height fs-24 fw-7">
              8. Is there a refund policy?{" "}
            </div>
            <div className="line_height fs-20 fw-4">
              Due to the personalized nature of our service, refunds are
              generally not offered once work on your customised song has begun.
              However, we strive for customer satisfaction and will work closely
              with you to address any concerns.
            </div>
            <div className="line_height fs-24 fw-7">
              9. Can I gift a customised song to someone else?
            </div>
            <div className="line_height fs-20 fw-4">
              Absolutely! Many of our customers choose to gift customised songs
              to their loved ones as a unique and memorable present. We can also
              include a personalized message or dedication with the song
              delivery.
            </div>
            <div className="line_height fs-24 fw-7">
              10. Do you offer gift certificates or vouchers for customised
              songs?
            </div>
            <div className="line_height fs-20 fw-4">
              Yes, we offer gift certificates or vouchers that allow recipients
              to create their own customised song experience. It's a perfect way
              to give the gift of music and creativity!
            </div>
            <div className="line_height fs-24 fw-7">
              11. Can I request a specific genre or musical style for my
              customised song?{" "}
            </div>
            <div className="line_height fs-20 fw-4">
              Yes, you can choose the genre or musical style that best suits
              your preferences and the message you want to convey. Our team will
              tailor the song accordingly to capture the right mood and
              emotions.
            </div>
            <div className="line_height fs-24 fw-7">
              12. Are the lyrics of the customised song original?{" "}
            </div>
            <div className="line_height fs-20 fw-4">
              Yes, all lyrics for our customised songs are original and crafted
              uniquely for each customer based on their provided story and
              sentiments.
            </div>
            <div className="line_height fs-24 fw-7">
              13. Can I include personal details or specific references in the
              song lyrics?{" "}
            </div>
            <div className="line_height fs-20 fw-4">
              Absolutely! Including personal details or references in the lyrics
              adds a special touch to your customised song. You can share
              specific anecdotes, names, dates, or meaningful phrases that you
              want incorporated into the song.
            </div>
            <div className="line_height fs-24 fw-7">
              14. Can I collaborate with the songwriter or provide feedback
              during the song creation process?
            </div>
            <div className="line_height fs-20 fw-4">
              Yes, we encourage collaboration and value your input! You can
              provide feedback on the song's lyrics and melody during the
              creation process to ensure it aligns with your vision.
            </div>
            <div className="line_height fs-24 fw-7">
              15. Is there an option to add instrumental arrangements or
              additional vocals to my customised song?
            </div>
            <div className="line_height fs-20 fw-4">
              Yes, we offer additional services such as instrumental
              arrangements or additional vocals to enhance your customised song.
              These options can be discussed during the consultation phase.
            </div>
            <div className="line_height fs-24 fw-7">
              16. How can I ensure the confidentiality of my personal story and
              details?
            </div>
            <div className="line_height fs-20 fw-4">
              We prioritize confidentiality and respect your privacy. All
              personal stories and details shared with us are kept confidential
              and used solely for the purpose of creating your customised song.
            </div>
            <div className="line_height fs-24 fw-7">
              17. Can I order a customised song as a surprise gift for someone?
            </div>
            <div className="line_height fs-20 fw-4">
              Yes, many customers choose to surprise their loved ones with a
              customised song as a heartfelt and memorable gift. We can
              coordinate delivery to ensure it's a surprise.
            </div>
            <div className="line_height fs-24 fw-7">
              18. Do you offer rush or expedited services for urgent requests?{" "}
            </div>
            <div className="line_height fs-20 fw-4">
              Yes, we offer rush services for urgent requests depending on our
              current workload and availability. Please contact us directly to
              discuss your timeline and options.
            </div>
            <div className="line_height fs-24 fw-7">
              19. Can I license the customised song for commercial use or public
              performance?{" "}
            </div>
            <div className="line_height fs-20 fw-4">
              Our customised songs are intended for personal use and enjoyment.
              If you intend to use the song for commercial purposes or public
              performance, additional licensing arrangements may be required.
              Please contact us to discuss your specific needs.
            </div>
            <div className="line_height fs-24 fw-7">
              20. How can I contact customer support if I have more questions or
              need assistance?{" "}
            </div>
            <div className="line_height fs-20 fw-4">
              You can reach our customer support team via email at{" "}
              <b>smartinkartenjer@email.com</b> or through our contact form on
              the website. We're here to assist you with any questions or
              concerns you may have about our customised song services.
            </div>

            <div className="title pb-4 line_height_top">
              <img
                src={logo}
                alt=""
                className="cursorPointer"
                onClick={navigateHome}
              />
            </div>
          </div>
        </section>
        <div className="container-fluid bg-color">
          <Footer />
        </div>
      </>
    </HelmetProvider>
  );
};
