import React, { useEffect, useState } from "react";
import { Table, Button, Rate, Input, message, Popconfirm, Spin } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { Songdata } from "./SongData";
import SingleLineText from "./Components/SingleLineText";
import AudioPlayer from "./Components/AudioPlayer";
import DropdownSongStatus from "./Components/DropdownSongStatus";
import DateSelector from "./Components/DateSelector";
import axios from "axios";
import { BASE_URL } from "../../..";
import moment from "moment";
import { Select, Tag } from "antd";
export const Delivered = ({ handleFieldClick, additionalProp1 }) => {
  const [tableData, setTableData] = useState([]); // Initialize as an empty array
  const [statusValue, setStatusValue] = useState("");
  const [searchText, setSearchText] = useState(""); // State for search input
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [playingIndex, setPlayingIndex] = useState(null);
  const [adminPlayingIndex, setAdminPlayingIndex] = useState(null);

  const [adminId, setadminId] = useState(localStorage.getItem("adminId"));
  const [originalData, setOriginalData] = useState([]); // Original unfiltered data
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 }); // Pagination state

  const handlePlay = (index) => {
    setPlayingIndex(index);
  };
  const handleAdminPlay = (index) => {
    setAdminPlayingIndex(index);
  };

  const handlePause = () => {
    setPlayingIndex(null);
  };
  const handleAdminPause = () => {
    setAdminPlayingIndex(null);
  };
  const handleStatusChange = (value, record) => {
    setStatusValue(value);
  };
  const statusTag = (status) => {
    let color = "default";
    let text = "N/A";

    switch (status) {
      case "paid":
        color = "green";
        text = "Success";
        break;
      case "attempted":
        color = "red";
        text = "Failed";
        break;
      default:
        color = "grey";
        text = "Pending";
    }

    return <Tag color={color}>{text}</Tag>;
  };
  const changeStatus = (record) => {
    const formData = new FormData();
    if (statusValue === "") {
      // formData.append("orderPosition", record.status);
    } else {
      setLoading(true);
      formData.append("orderPosition", statusValue);
      const token = localStorage.getItem("accessToken");
      axios
        .put(BASE_URL + "/v1/form/" + record.id, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          // responseType: "arraybuffer",
        })
        .then(() => {
          setVisible(true);
          confirm();
          fetchSaved();
          setLoading(false);
        })
        .catch((error) => {
          console.error("Data Saveing : " + error);
          setLoading(false);
        });
      setStatusValue("");
    }
  };
  const confirm = () => {
    setVisible(false);
    message.success("Updated Successfully");
  };
  const data = tableData
    // .filter((item) =>
    //   item.orderNumber.toLowerCase().includes(searchText.toLowerCase())
    // )
    .map((item, index) => ({
      ...item,
      key: index.toString(),
      sno: index + 1,
      status: (
        <>
          {item.status === "DELIVERED" ? (
            <Tag color="green">{item.status}</Tag>
          ) : (
            "-"
          )}
        </>
      ),
      // deliveryDate1: <DateSelector />,
      screenshot:
        item.screenshot !== null ? (
          <img
            src={BASE_URL + "/v1/form/song/" + item.screenshot} // Assuming upiPath contains the URL of the image
            alt=""
            style={{ maxWidth: "50%", maxHeight: "50%" }}
          />
        ) : (
          "-"
        ),
      comments: item.comments !== null ? item.comments : "-",
      // deliveryDate:
      //   item.deliveryDate !== null
      //     ? item.deliveryDate
      //     : item.customerDeliveryDate,
      orderTotal: item.orderTotal !== null ? item.orderTotal : "-",
      clipPath: (
        <AudioPlayer
          src={BASE_URL + "/v1/form/song/" + item.clipPath}
          item={item}
          isPlaying={playingIndex === index}
          onPlay={() => handlePlay(index)}
          onPause={handlePause}
        />
      ),
      adminClipPath: (
        <AudioPlayer
          src={BASE_URL + "/v1/form/song/" + item.adminClipPath}
          item={item}
          isPlaying={adminPlayingIndex === index}
          onPlay={() => handleAdminPlay(index)}
          onPause={handleAdminPause}
        />
      ),
    }));
  // =====================  Table Column Header Data =======================
  const originalColumns = [
    {
      title: "Created Date",
      width: 110,
      dataIndex: "createdDate",
      fixed: "left",
      render: (createdDate) => moment(createdDate).format("DD-MM-YYYY"),
    },
    {
      title: "Order Number",
      width: 100,
      dataIndex: "orderNumber",
      fixed: "left",
    },
    {
      title: "Client Name",
      width: 150,
      fixed: "left",
      dataIndex: "name",
    },
    {
      title: "Mobile Number",
      dataIndex: "phonenumber",
      width: 150,
    },
    {
      title: (
        <p>
          Email ID <br />
          <span className="small">Click the email id to copy</span>
        </p>
      ),
      dataIndex: "mail",
      width: 250,
      render: (email) => {
        return (
          <a
            onClick={(e) => handleFieldClick(e)}
            href={`mailto:${email}`}
            key={email}
            className="text-dark"
          >
            {email}
          </a>
        );
      },
    },
    {
      title: "Language",
      dataIndex: "lanuage",
      width: 130,
    },
    {
      title: "Artist",
      dataIndex: "artist",
      width: 130,
    },
    {
      title: "Instrument",
      dataIndex: "instrument",
      width: 150,
    },
    // {
    //   title: "Inst. Version",
    //   dataIndex: "instVersion",
    //   width: 150,
    // },
    // {
    //   title: "Mood",
    //   dataIndex: "mood",
    //   width: 150,
    // },
    {
      title: "Song From",
      dataIndex: "songfrom",
      width: 150,
    },
    {
      title: "Song To",
      dataIndex: "songto",
      width: 150,
    },
    {
      title: "Occasion",
      dataIndex: "occasion",
      width: 150,
    },
    {
      title: "Story",
      dataIndex: "story",
      width: 400,
      render: (story) => {
        return <SingleLineText text={story} />;
      },
    },
    {
      title: "Audio File",
      dataIndex: "clipPath",
      width: 280,
      // render: (mp3) => {
      //   return <AudioPlayer src={mp3} />;
      // },
    },
    {
      title: "Recorded song",
      dataIndex: "adminClipPath",
      width: 280,
      // render: (mp3) => {
      //   return <AudioPlayer src={mp3} />;
      // },
    },
    {
      title: "Payment Order Id",
      dataIndex: "paymentOrderId",
      width: 250,
    },
    {
      title: "Order Total",
      align: "center",
      width: 150,
      dataIndex: "orderTotal",
    },
    {
      title: "Payment Status",
      align: "center",
      width: 150,
      dataIndex: "paymentStatus",
      render: (status) => statusTag(status),
    },
    {
      title: "Status",
      width: 200,
      dataIndex: "status",

      // render: (tag, record) => {
      //   return (
      //     <Select
      //       defaultValue={selectedSongStatus[record.orderNumber] || tag}
      //       style={{ width: 155 }}
      //       onChange={(value) => handleStatusChange(value, record)}
      //     >
      //       <Select.Option value="Processing">
      //         <Tag color="processing">Order saved</Tag>
      //       </Select.Option>
      //       <Select.Option value="Composing">
      //         <Tag color="warning">Order confirmed</Tag>
      //       </Select.Option>
      //       <Select.Option value="Rejected">
      //         <Tag color="error">Composing</Tag>
      //       </Select.Option>
      //       <Select.Option value="Delivered">
      //         <Tag color="success">Recording</Tag>
      //       </Select.Option>
      //       <Select.Option value="Delivered">
      //         <Tag color="success">Delivered</Tag>
      //       </Select.Option>
      //       x
      //     </Select>
      //   );
      // },
    },
    // {
    //   title: "Customer's Selected Date",
    //   dataIndex: "customerDeliveryDate",
    //   // render: (customerExpect) => {
    //   //   return <span>{customerExpect}</span>;
    //   // },
    //   width: 150,
    // },
    {
      title: "Customer Delivery Date",
      dataIndex: "customerDeliveryDate",

      width: 150,
    },
    {
      title: "Admin Delivery Date",
      width: 150,
      dataIndex: "deliveryDate",
    },
    // {
    //   title: "Comment",
    //   dataIndex: "comments",

    //   width: 200,
    // },

    // {
    //   title: "Customer Review",
    //   dataIndex: "review",
    //   width: 350,
    //   render: (review) => {
    //     return <SingleLineText text={review} />;
    //   },
    // },
    // {
    //   title: "Rating",
    //   dataIndex: "rating",
    //   width: 200,
    //   render: (_, record) => {
    //     return <Rate disabled defaultValue={record.rating} />;
    //   },
    // },
  ];
  let columns = originalColumns;
  const admin = localStorage.getItem("token");
  if (admin != 1) {
    const columnsToRemove = [
      "phonenumber",
      "screenshot",
      "orderTotal",
      "mail",
      "paymentOrderId",
    ];
    columns = originalColumns.filter(
      (column) => !columnsToRemove.includes(column.dataIndex)
    );
  }
  const fetchSaved = () => {
    setLoading(true);
    const token = localStorage.getItem("accessToken");
    axios
      .get(BASE_URL + "/v1/form/?position=DELIVERED", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // responseType: "arraybuffer",
      })
      .then((response) => response.data)
      .then((res_data) => {
        setTableData(res_data.data.formDtos.forms);
        setOriginalData(res_data.data.formDtos.forms);
        setLoading(false);
      });
  };
  // xxxxxxxxxxxxxxxxxx  Table Column Header Data End xxxxxxxxxxxxxxxxxx

  // =====================  Table Body Data =======================
  useEffect(() => {
    fetchSaved();
  }, [additionalProp1 == "6"]);

  // UseEffect to filter the table data based on search text
  useEffect(() => {
    if (searchText === "") {
      setTableData(originalData); // Reset to original data if search text is empty
    } else {
      const filteredData = originalData.filter((item) => {
        const searchTextLower = searchText.toLowerCase();
        const createdDateStr = moment(item.createdDate)
          .format("DD-MM-YYYY")
          .toLowerCase();
        const customerDeliveryDateStr = moment(item.customerDeliveryDate)
          .format("DD-MM-YYYY")
          .toLowerCase();
        return (
          item.name.toLowerCase().includes(searchTextLower) ||
          item.orderNumber.toLowerCase().includes(searchTextLower) ||
          // item.lanuage.toLowerCase().includes(searchTextLower) ||
          // createdDateStr.includes(searchTextLower) ||
          item.phonenumber.includes(searchText) || // phonenumber as string comparison
          item.mail.toLowerCase().includes(searchTextLower)
          //  ||
          // item.artist.toLowerCase().includes(searchTextLower) ||
          // item.instrument.toLowerCase().includes(searchTextLower) ||
          // item.mood.toLowerCase().includes(searchTextLower) ||
          // item.songfrom.toLowerCase().includes(searchTextLower) ||
          // item.songto.toLowerCase().includes(searchTextLower) ||
          // item.occasion.toLowerCase().includes(searchTextLower) ||
          // item.story.toLowerCase().includes(searchTextLower) ||
          // // (item.status.toLowerCase() ?? "").includes(searchTextLower) || // handle dropdown status
          // customerDeliveryDateStr.includes(searchTextLower) ||
          // (item.comments?.toLowerCase() ?? "").includes(searchTextLower) // handle null/undefined comments
        );
      });
      setTableData(filteredData);
    }
  }, [searchText, originalData]);

  // Effect to clear search text when data changes
  useEffect(() => {
    setSearchText(""); // Clear search text when originalData changes
  }, [originalData]);

  // Reset pagination when the table changes
  useEffect(() => {
    setPagination({ current: 1, pageSize: 10 });
  }, [tableData]);

  return (
    <div>
      <Spin
        spinning={loading}
        fullscreen
        className="custom-spin"
        size="large"
        tip="Loading"
      />
      <Input
        size="small"
        className="search fs-14 fw-6 p-2 px-3 my-3"
        placeholder="Search"
        prefix={<i className="bi bi-search"></i>}
        style={{ float: "right", width: "200px" }}
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText} // Controlled input
      />
      <Table
        style={{ fontWeight: "600" }}
        // rowKey="orderID"
        columns={columns}
        dataSource={data}
        scroll={{
          x: 3200,
          y: "60svh",
        }}
        // pagination={true}
        pagination={pagination} // Pass pagination state to the Table component
        onChange={(pagination) => setPagination(pagination)} // Update pagination state when pagination changes
        bordered
      />
    </div>
  );
};
