import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { CopyOutlined, PaperClipOutlined } from "@ant-design/icons";
import { Button, message, Spin, Upload } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import axios from "axios";
import { BASE_URL } from "../..";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Footer from "../Base/Footer";
import { logDOM } from "@testing-library/react";
import logo from "../../assets/images/logo.png";

const Payment = ({
  setActiveTab,
  activeTab,
  userId,
  amountId,
  payKey,
  payOrderId,
  firstPageDate,
  orderTotalAmount,
}) => {
  const [input, setInput] = useState({ Pay_With_UPI: "" });
  const [show, setShow] = useState(false);
  const [charges, setCharges] = useState({});
  const [upiID, setUPIid] = useState("");
  const [qrCodeName, setQRname] = useState("");
  const [qrCodeImage, setQRimage] = useState(null);
  const [orderInfo, setOrderInfo] = useState("");
  const [languageAmount, setLanguageAmount] = useState("");
  const [instrumentAmount, setInstrumentAmount] = useState("");
  const [artistAmount, setArtistAmount] = useState("");
  const [mixingAmount, setMixingAmount] = useState("");
  const [deliveryDateAmount, setDeliveryDateAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const [rzpInstance, setRzpInstance] = useState(null);
  const [retryPaymentId, setRetryPaymentId] = useState("");
  const [retryOrderId, setRetryOrderId] = useState("");
  const [cancelPayment, setCancelPayment] = useState(false);
  const [file1Name, setFile1Name] = useState("Upload ScreeenShot");
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    initializeRazorpay();
    sessionStorage.setItem("paymentUserId", userId);
    if (userId != 0) {
      getOrderData(userId);
    }
    if (amountId != 0) {
      getAmount(amountId);
    }
    //------------------- Getting the UPI QR file name ----------------
    // axios
    //   .get(`${BASE_URL}/v1/payment/`)
    //   .then((res) => {
    //     setUPIid(res.data.data.payments[0].name);
    //     return res.data.data.payments[0].upiPath;
    //   })
    //   .then((res) => {
    //     setQRname(res);
    //     return res;
    //   })
    //   .then(() => {
    //     setCharges({
    //       item1: "Language - English:",
    //       item2: "Female Artist:",
    //       item3: "Proffestional Mixing & Mastering:",
    //       item4: "Simple Guiter Version:",
    //     });
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching data:", error);
    //   });
    // setQRimage(`${BASE_URL}/v1/payment/upi/${qrCodeName}`);
  }, []);
  const getOrderData = (id) => {
    axios
      .get(BASE_URL + "/v1/form/" + id)
      .then((response) => response.data)
      .then((res_data) => {
        setOrderInfo(res_data.data.formRequestDto);
      });
  };
  const getAmount = (id) => {
    axios
      .get(BASE_URL + "/v1/orderSummary/" + id)
      .then((response) => response.data)
      .then((res_data) => {
        setLanguageAmount(res_data.data.orderSummary.langugaeAmount);
        setInstrumentAmount(res_data.data.orderSummary.versionAmount);
        setArtistAmount(res_data.data.orderSummary.artistAmount);
        setMixingAmount(res_data.data.orderSummary.masterAmount);
        setDeliveryDateAmount(res_data.data.orderSummary.deliverydateAmount);
        setTotalAmount(res_data.data.orderSummary.totalAmount);
      });
  };
  const onFileSelected = (event) => {
    const file = event.target.files[0]; // Get the selected file
    setFile1Name(file.name); // Set the selected file in state
    // sendFileAsBlob(file); // Pass the selected file to the sendFileAsBlob function
  };
  const handleInput = (e) => {
    if (e.target.name === "audioFile") {
      setInput({ ...input, audioFile: e.target.files[0] });
    } else {
      setInput({ ...input, [e.target.name]: e.target.value });
    }
  };

  const initializeRazorpay = () => {
    const order_id = cancelPayment ? retryOrderId : payOrderId;
    const key = cancelPayment ? retryPaymentId : payKey;

    const options = {
      order_id: order_id,
      key: "rzp_live_WBH7rGP04GwuQK", // Replace with your actual Razorpay Key ID
      amount: `${totalAmount}`, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "TuneFor2",
      description: "Song Request",
      image: logo,
      // callback_url: "https://api.cavinkare.in/tune/api/v1/masterpayment/handle",
      // step: "Test",
      // This should be the actual Order ID obtained from your backend
      handler: function (response) {
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);
        navigate("/payment-status");
        let data = {
          payment_id: response.razorpay_payment_id,
          order_id: response.razorpay_order_id,
        };
        sessionStorage.setItem("paymentStatus", "true");
        axios
          .post(`${BASE_URL}/v1/masterpayment/handle`, data, {
            headers: {
              "X-Razorpay-Signature": response.razorpay_signature,
            },
          })
          .then((res) => {})
          .catch((error) => {
            console.error("Data Saveing : " + error);
          });
        // sendPaymentDetailsToBackend(response);
      },
      prefill: {
        name: "",
        email: "",
        contact: "",
      },
      notes: {
        address: "",
      },
      theme: {
        color: "#f77b21",
      },
      method: {
        paylater: false,
        wallet: false,
      },
      config: {
        display: {
          hide: [{ method: "paylater" }, { method: "emi" }],
          preferences: { show_default_blocks: true },
        },
      },
      modal: {
        ondismiss: function () {
          setCancelPayment(true);

          const response = axios

            .post(
              BASE_URL +
                "/v1/masterpayment/createOrder?amount=" +
                `${orderTotalAmount}` +
                "&formId=" +
                userId
            )
            .then((response) => {
              setRetryPaymentId(response.data.KeyId);
              setRetryOrderId(response.data.OrderId);
              initializeRazorpay();
            });

          // Perform any action you want on modal dismiss
          // alert("Payment was cancelled by the user.");
          // navigate("/payment-status");
        },
      },
    };

    const rzp = new window.Razorpay(options);
    setRzpInstance(rzp);

    rzp.on("payment.failed", function (response) {
      let data = {
        payment_id: response.error.metadata.payment_id,
        order_id: response.error.metadata.order_id,
      };
      axios
        .post(`${BASE_URL}/v1/masterpayment/handle`, data)
        .then((res) => {})
        .catch((error) => {
          console.error("Data Saveing : " + error);
        });
      // navigate("/payment-status");
    });
  };
  const sendPaymentDetailsToBackend = (response) => {
    fetch("/v1/masterpayment/handle", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        razorpay_payment_id: response.razorpay_payment_id,
        razorpay_order_id: response.razorpay_order_id,
        razorpay_signature: response.razorpay_signature,
      }),
    })
      .then((response) => response.text())
      .then((result) => {
        alert(result); // Handle success/failure notification
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Payment failed");
      });
  };

  const handlePaymentButtonClick = (e) => {
    e.preventDefault();
    if (rzpInstance) {
      rzpInstance.open();
    }
  };
  const handleRetryPaymentButtonClick = (e) => {
    axios
      .post(
        BASE_URL +
          "/v1/masterpayment/createOrder?amount=" +
          `${orderTotalAmount}` +
          "&formId=" +
          userId
      )
      .then((response) => {
        setRetryPaymentId(response.data.KeyId);
        setRetryOrderId(response.data.OrderId);
        initializeRazorpay();
      });

    e.preventDefault();
    if (rzpInstance) {
      rzpInstance.open();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    // Swal.fire({
    //   title: "Order placed successfuly!!!",
    //   text: "We appreciate you for the song order. An order confirmation email will reach you once the payment process is completed.",
    //   imageUrl: require("../../assets/images/image_120.png"),
    //   imageWidth: "auto",
    //   imageHeight: "20vh",
    //  // icon: "success",
    //   imageAlt: "Custom image",
    //   willClose: () => {
    //     navigate("/");
    //   },
    // });
    formData.append("file", document.getElementById("fileInput").files[0]);
    setLoading(true);
    await axios
      .put(
        BASE_URL + "/v1/form/add-screenshot/" + orderInfo.orderNumber,
        formData
      )
      .then(() => {
        Swal.fire({
          title: "Order Placed Successfully!",
          text: "Thank you for placing your song order with us. Your business is valued! Your order will be verified, and you will receive a confirmation email shortly.",
          icon: "success",
          imageAlt: "Order palced Success",
          willClose: () => {
            navigate("/");
          },
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setLoading(false);
        Swal.fire({
          title: "Upload your payment screenshot",
          text: "Please provide valid payment confirmation image..!",
          icon: "warning",
        });
      });
  };
  return (
    <>
      <section className="section1 bg-color DM_Sans">
        {activeTab === 3 && (
          <div>
            <div className="container-fluid">
              <div className="Tab-shape1">
                <img src={require("../../assets/images/shape1.png")} alt="" />
              </div>
              <div className="Tab-shape2 d-none d-md-block">
                <img src={require("../../assets/images/shape2.png")} alt="" />
              </div>
              <div className="dsf">
                <h2 className="mt-5 c_F77B21 fs-36 fw-6 ps-4 ms-3 ms-md-4">
                  Payment Process
                </h2>
              </div>
            </div>
            <div className="conatiner-fluid">
              <div className="payment-section">
                <div className="row ms-md-3 mt-5 row-gap-5">
                  <div className="col-xl-4 col-lg-6 col-md-9 col-12 mx-auto px-5 px-md-3 ps-md-5">
                    <p className="payment_content text-muted d-flex justify-content-center">
                      To complete your order, please use the QR code provided to
                      make your payment. After making the payment, attach and
                      submit the screenshot of the payment confirmation. Our
                      backend team will verify your order upon receiving the
                      payment and you will receive an order confirmation email
                      shortly thereafter.
                    </p>
                    <p className="fs-26 fw-6 c_505A64">Order Summary</p>
                    <hr />

                    <div className="amount-section py-3 c_0A0D13 fw-6 fs-18 fs-16">
                      <p>
                        {languageAmount !== 0 && (
                          <>
                            {orderInfo.lanuage}
                            <span className="float-end">₹{languageAmount}</span>
                          </>
                        )}
                      </p>
                      <p>
                        {instrumentAmount !== 0 && (
                          <>
                            {orderInfo.instrument}
                            <span className="float-end">
                              ₹{instrumentAmount}
                            </span>
                          </>
                        )}
                      </p>
                      <p>
                        {mixingAmount !== 0 && (
                          <>
                            {orderInfo.mixtureMaster}
                            <span className="float-end">₹{mixingAmount}</span>
                          </>
                        )}
                      </p>
                      <p>
                        {deliveryDateAmount !== 0 && (
                          <>
                            {"Delivery"}
                            <span className="float-end">
                              ₹{deliveryDateAmount}
                            </span>
                          </>
                        )}
                      </p>
                      <p className="mb-0">
                        {artistAmount !== 0 && (
                          <>
                            {"Artist"}
                            <span className="float-end">₹{artistAmount}</span>
                          </>
                        )}
                      </p>
                    </div>
                    <hr />
                    <div className="total">
                      <p className="c_0A0D13 fw-6 fs-18 m-0">
                        Total{" "}
                        <span className="float-end c_0A0D13 fw-6">
                          ₹{totalAmount}
                        </span>
                      </p>
                      {/* <small className="c_ACACAC fs-18 d-none d-sm-block">
                        Including ₹90 in taxes
                      </small> */}
                    </div>
                    {/* <div className="my-5">
                      <p className="c_0A0D13 fs-18 fw-6">
                        Razor Pay Order ID:{" "}
                        <span className="c_F77B21 fs-18 fw-6">
                          {payOrderId}
                        </span>
                      </p>
                    </div> */}
                    <div className="col-md-9 my-5">
                      {cancelPayment ? (
                        <button
                          type="button"
                          className="payment-btn fw-6 w-100"
                          onClick={handleRetryPaymentButtonClick}
                        >
                          Retry Payment (₹{totalAmount})
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="payment-btn fw-6 w-100"
                          onClick={handlePaymentButtonClick}
                        >
                          Pay with Razor Pay (₹{totalAmount})
                        </button>
                      )}
                      {/* <Spin
                        spinning={loading}
                        fullscreen
                        className="custom-spin"
                        size="large"
                        tip="Loading"
                      /> */}
                      <p className="fst-italic c_ACACAC fs-10 mt-2 text-start">
                        Your personal data will be used to process your order,
                        support your experience throughout this website, and for
                        other purposes described in our privacy policy.
                      </p>
                    </div>
                  </div>
                  {/* <div className="col-xl-4 col-lg-6 col-md-6 col-12 payment-column ps-4 mx-auto">
                    <p className="fs-26 fw-6 c_505A64 bar-code d-none d-md-block">
                      Scan to pay:
                    </p>
                    <hr className="d-none d-md-block" />
                    <div className="payment-QR text-center py-3 d-none d-md-block">
                      <img
                        src={`${BASE_URL}/v1/payment/upi/${qrCodeName}`}
                        alt="UPI_QrCode"
                        className="qrCode_img"
                      />
                    </div>
                    <p className="text-center fs-20 fw-6 c_505A64 d-none d-md-block mb-0">
                      Or
                    </p>
                    <form
                      action=""
                      onSubmit={handleSubmit}
                      className="row g-3 my-1"
                    >
                      <div>
                        <button
                          id="rzp-button1"
                          onClick={handlePaymentButtonClick}
                        >
                          Pay with Razorpay
                        </button>
                      </div>
                      <div className="col-md-9 mx-auto">
                        <label
                          htmlFor="Pay_With_UPI"
                          className="ms-4 ms-md-2 form-label c_505A64 fs-20 fw-6"
                        >
                          Pay With UPI:
                        </label>
                        <div className="d-flex flex-column py-2">
                          <div className="upiID ps-2">
                            <Paragraph
                              copyable={{
                                icon: [
                                  <CopyOutlined
                                    key="copy-icon"
                                    style={{
                                      fontSize: "20px",
                                      color: "var(--primary-orange)",
                                    }}
                                  />,
                                  <CopyOutlined
                                    key="copied-icon"
                                    style={{
                                      fontSize: "20px",
                                      color: "var(--primary-orange)",
                                    }}
                                  />,
                                ],
                                tooltips: ["Copy", "Copied!!"],
                              }}
                            >
                              {upiID}
                            </Paragraph>
                          </div>
                          <div className="ps-2 mt-2">
                            <label
                              htmlFor="audioFile"
                              className="d-block fs-6 mb-2 text-muted    "
                            >
                              Upload Payment ScreenShot
                              <span className="text-danger">*</span>
                            </label>

                            <div className="side-nav-input1 rounded d-flex justify-content-between align-items-center">
                              <input
                                className="choose-file-hidden"
                                type="file"
                                accept="image/jpeg,image/jpg,image/png,image/gif"
                                id="fileInput"
                                onChange={onFileSelected}
                              />
                              <span>{file1Name}</span>
                              <label
                                htmlFor="fileInput"
                                className="paperClipIcon"
                              >
                                <PaperClipOutlined
                                  style={{
                                    fontSize: "21px",
                                    color: "var(--primary-orange)",
                                    cursor: "pointer",
                                  }}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-9 mx-auto text-center">
                        <button
                          type="submit"
                          className="payment-btn fw-6 text-center w-100"
                        >
                          Confirm Order
                        </button>
                        <Spin
                          spinning={loading}
                          fullscreen
                          className="custom-spin"
                          size="large"
                          tip="Loading"
                        />
                        <p className="fst-italic c_ACACAC fs-10 mt-2 text-start">
                          Your personal data will be used to process your order,
                          support your experience throughout this website, and
                          for other purposes described in our privacy policy.
                        </p>
                      </div>
                    </form>
                  </div> */}
                  <div className="col-xl-4 col-md-4 d-none d-lg-block mx-auto">
                    <div className="payment-bg p-md-5">
                      <img
                        src={require("../../assets/images/TuneFor2Img/self-improvement-song.webp")}
                        alt="self improvement song"
                        title="self improvement song"
                        className="tab4-img img-fluid"
                      />
                    </div>
                    <div className="payment-shape">
                      <img
                        className="img-fluid"
                        src={require("../../assets/images/shape3.png")}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
      <Footer />
    </>
  );
};
export default Payment;
