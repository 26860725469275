import React, { useState, useEffect } from "react";
import { Table, Input } from "antd";
import axios from "axios";
import { message, Popconfirm } from "antd";
import { BASE_URL } from "../../..";

export const TextFields = () => {
  const [searchText, setSearchText] = useState(""); // State for search input
  const [tableData, setTableData] = useState([]);
  const [visible, setVisible] = useState(false);

  const fetchData = () => {
    axios
      .get(`${BASE_URL}/v1/Filed/`)
      .then((response) => {
        setTableData(response.data.data.fileds);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleMandatoryClick = (record) => {
    const formData = new FormData();
    formData.append("name", record.name);
    formData.append("isMandatory", "Mandatory");
    let data = { params: { isMandatory: "Mandatory" } };
    const token = localStorage.getItem("accessToken");

    axios
      .put(BASE_URL + "/v1/Filed/" + record.id + "?isMandatory=Mandatory", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // params: data.params,
        // responseType: "arraybuffer",
      })
      .then(() => {
        setVisible(true);
        confirm(record.name, "Mandatory");
        fetchData();
      })
      .catch((error) => {});
  };
  const confirm = (record, page) => {
    setVisible(false);
    if (page === "Mandatory") {
      message.success(`${record} set as Mandatory`);
    } else if (page === "NotMandatory") {
      message.success(`${record} not set as Mandatory`);
    }
  };
  const handleNotMandatoryClick = (record) => {
    let data = { params: { isMandatory: "NotMandatory" } };
    const token = localStorage.getItem("accessToken");

    axios
      .put(BASE_URL + "/v1/Filed/" + record.id + "?isMandatory=NotMandatory", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // params: data.params,
        // responseType: "arraybuffer",
      })
      .then(() => {
        setVisible(true);
        confirm(record.name, "NotMandatory");
        fetchData();
      })
      .catch((error) => {});
  };

  const columns = [
    {
      title: "SI.No",
      dataIndex: "sno",
      width: 150,
      align: "center",
    },
    {
      title: "Text Fields",
      dataIndex: "name",
      width: 680,
    },

    {
      title: "Actions",
      align: "center",
      dataIndex: "action",
    },
  ];
  const data = tableData
    .filter((item) =>
      item.name.toLowerCase().includes(searchText.toLowerCase())
    )
    .map((item, index) => ({
      name: item.name,
      key: index.toString(),
      sno: index + 1,
      action: (
        <div className="d-flex gap-3 align-items-center justify-content-center">
          <div className="d-flex rounded mandatory p-1 gap-2 fs-16 fw-6">
            <Popconfirm
              open={visible}
              onConfirm={confirm}
              onCancel={() => setVisible(false)}
              okText="Yes"
              cancelText="No"
            >
              <button
                className={`mandatory-mark p-1 rounded ${
                  item.isMandatory === "Mandatory" ? "active" : ""
                }`}
                onClick={() => handleMandatoryClick(item)}
              >
                Mark as Mandatory
              </button>
            </Popconfirm>
            <button
              className={`mandatory-notMark p-1 rounded ${
                item.isMandatory === "NotMandatory" ? "active" : ""
              }`}
              onClick={() => handleNotMandatoryClick(item)}
            >
              Not mark as Mandatory
            </button>
          </div>
        </div>
      ),
    }));

  return (
    <>
      <div className="d-flex justify-content-end">
        <Input
          size="small"
          className="search fs-14 fw-6"
          placeholder="Search"
          prefix={<i className="bi bi-search"></i>}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>

      <Table
        columns={columns}
        dataSource={data}
        bordered
        pagination={true}
        scroll={{
          x: 0,
          y: "37svh",
        }}
      />
    </>
  );
};
