import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { useState } from "react";
import { useEffect } from "react";

const Navbar = ({
  createSong,
  home,
  about,
  contact,
  policy,
  terms,
  ourProducts,
}) => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    let prevScrollPos = window.scrollY;

    const handleScroll = () => {
      const currentScrollPos = window.scrollY;

      // if (prevScrollPos > currentScrollPos) {
      //   // Scrolling up
      //   setScroll(false);
      // } else {
      // Scrolling down
      if (currentScrollPos > 10) {
        setScroll(true);
      } else {
        setScroll(false);
      }
      // }

      prevScrollPos = currentScrollPos;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* <nav
        className={`navbar navbar-expand-lg Montserrat bg-color Poppins ${
          scroll ? "fixed-top" : ""
        }`}
      >
        <div className="container-fluid">
          <Link to={"/"} className="navbar-brand nav-link">
            <img src={logo} alt="Logo" className="img-fluid" width={"100%"} />
          </Link>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNav"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  to={"/"}
                  className={`nav-link fs-18 fw-5 px-4 navcolor ${
                    home ? "active" : ""
                  }`}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={"/about"}
                  className={`nav-link fs-18 fw-5 px-4 navcolor ${
                    about ? "active" : ""
                  }`}
                >
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={"/create-a-song"}
                  className={`nav-link fs-18 fw-5 px-4 navcolor ${
                    createSong ? "active" : ""
                  }`}
                >
                  Create a Song
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={"/contactUs"}
                  className={`nav-link fs-18 fw-5 px-4 navcolor ${
                    contact ? "active" : ""
                  }`}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav> */}

      {/* ========================================= */}
      <nav
        className={`navbar navbar-expand-lg Montserrat bg-color Poppins ${
          scroll ? "sticky-top" : ""
        }`}
      >
        <div className="container-fluid">
          <Link to={"/"} className="navbar-brand">
            <img src={logo} alt="Logo" className="img-fluid" width={"100%"} />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  to={"/"}
                  className={`nav-link fs-18 fw-5 px-4 navcolor ${
                    home ? "active" : ""
                  }`}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={"/about-us"}
                  className={`nav-link fs-18 fw-5 px-4 navcolor ${
                    about ? "active" : ""
                  }`}
                >
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={"/create-a-song"}
                  className={`nav-link fs-18 fw-5 px-4 navcolor ${
                    createSong ? "active" : ""
                  }`}
                >
                  Create a Song
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={"/our-gifts"}
                  className={`nav-link fs-18 fw-5 px-4 navcolor ${
                    ourProducts ? "active" : ""
                  }`}
                >
                  Our Gifts
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={"/contact"}
                  className={`nav-link fs-18 fw-5 px-4 navcolor ${
                    contact ? "active" : ""
                  }`}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
