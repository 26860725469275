import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Collapse, ConfigProvider, theme } from "antd";
const text = `
  You'll provide details about the recipient, like their name, interests, occasion, and the message you want to convey. Our experienced songwriters then craft personalized lyrics and pair them with a fitting genre. You get to choose the genre from a wide selection, ensuring the song perfectly captures their taste.
`;
const getItems = (panelStyle) => [
  {
    key: "1",
    label: (
      <div className="d-flex align-items-center my-3">
        <span className="px-3 fs-20 fw-6 mb-0">01</span>
        <span className="fs-22 fw-6 mb-0">What is a customised song?</span>
      </div>
    ),
    children: (
      <p className="fs-16 fw-5">
        A customised song is a unique piece of music created specifically for
        you or your loved ones, based on your provided details such as memories,
        emotions, and special moments.
      </p>
    ),
    style: panelStyle,
  },
  {
    key: "2",
    label: (
      <div className="d-flex align-items-center my-3">
        <span className="px-3 fs-20 fw-6 mb-0">02</span>
        <span className="fs-22 fw-6 mb-0">How does the process work?</span>
      </div>
    ),
    children: (
      <p className="fs-16 fw-5">
        The process is simple! You share your story and preferences with us,
        choose the style of the song, make the payment, and our talented
        musicians and lyricists will craft a personalized song just for you.
      </p>
    ),
    style: panelStyle,
  },
  {
    key: "3",
    label: (
      <div className="d-flex align-items-center my-3">
        <span className="px-3 fs-20 fw-6 mb-0">03</span>
        <span className="fs-22 fw-6 mb-0">
          What can I use a customised song for?
        </span>
      </div>
    ),
    children: (
      <p className="fs-16 fw-5">
        Customised songs are perfect for special occasions such as weddings,
        anniversaries, birthdays, proposals, or as heartfelt gifts to express
        love and appreciation.
      </p>
    ),
    style: panelStyle,
  },
  {
    key: "4",
    label: (
      <div className="d-flex align-items-center my-3">
        <span className="px-3 fs-20 fw-6 mb-0">04</span>
        <span className="fs-22 fw-6 mb-0">
          How much does a customised song cost?
        </span>
      </div>
    ),
    children: (
      <p className="fs-16 fw-5">
        Our pricing varies based on the style of the song, complexity, and
        additional services chosen. Please contact us for a personalized quote
        based on your specific requirements.
      </p>
    ),
    style: panelStyle,
  },
  {
    key: "5",
    label: (
      <div className="d-flex align-items-center my-3">
        <span className="px-3 fs-20 fw-6 mb-0">05</span>
        <span className="fs-22 fw-6 mb-0">Is there a refund policy?</span>
      </div>
    ),
    children: (
      <p className="fs-16 fw-5">
        Due to the personalized nature of our service, refunds are generally not
        offered once work on your customised song has begun. However, we strive
        for customer satisfaction and will work closely with you to address any
        concerns.
      </p>
    ),
    style: panelStyle,
  },
  {
    key: "6",
    label: (
      <div className="d-flex align-items-center my-3">
        <span className="px-3 fs-20 fw-6 mb-0">06</span>
        <span className="fs-22 fw-6 mb-0">
          Are the lyrics of the customised song original?{" "}
        </span>
      </div>
    ),
    children: (
      <p className="fs-16 fw-5">
        Yes, all lyrics for our customised songs are original and crafted
        uniquely for each customer based on their provided story and sentiments.
      </p>
    ),
    style: panelStyle,
  },
  // {
  //   key: "7",
  //   label: (
  //     <div className="d-flex align-items-center my-3">
  //       <span className="px-3 h4 mb-0">07</span>
  //       <span className="h4 mb-0">
  //         How long does it take to receive my customised song?
  //       </span>
  //     </div>
  //   ),
  //   children: (
  //     <p>
  //       Typically, you will receive your personalised song within [X] days after
  //       finalizing the details and completing the payment. This timeline may
  //       vary based on the complexity of the project.
  //     </p>
  //   ),
  //   style: panelStyle,
  // },

  // {
  //   key: "8",
  //   label: (
  //     <div className="d-flex align-items-center my-3">
  //       <span className="px-3 h4 mb-0">08</span>
  //       <span className="h4 mb-0">
  //         How long does it take to receive my customised song?
  //       </span>
  //     </div>
  //   ),
  //   children: (
  //     <p>
  //       Typically, you will receive your personalised song within [X] days after
  //       finalizing the details and completing the payment. This timeline may
  //       vary based on the complexity of the project.
  //     </p>
  //   ),
  //   style: panelStyle,
  // },
  // {
  //   key: "9",
  //   label: (
  //     <div className="d-flex align-items-center my-3">
  //       <span className="px-3 h4 mb-0">09</span>
  //       <span className="h4 mb-0">
  //         How long does it take to receive my customised song?
  //       </span>
  //     </div>
  //   ),
  //   children: (
  //     <p>
  //       Typically, you will receive your personalised song within [X] days after
  //       finalizing the details and completing the payment. This timeline may
  //       vary based on the complexity of the project.
  //     </p>
  //   ),
  //   style: panelStyle,
  // },
];

const FrequentlyAsked = () => {
  const panelStyle = {
    marginBottom: 24,
    background: "trasnparent",
    borderRadius: "8px",
    border: "1px solid #FFB775",
    fontFamily: "DM Sans",
  };
  return (
    <Collapse
      accordion={true}
      bordered={false}
      defaultActiveKey={["1"]}
      expandIconPosition="end"
      expandIcon={({ isActive }) => (
        <PlusOutlined
          style={{
            background: "#FFB775",
            color: isActive ? "#000" : "#fff",
            height: "100%",
            padding: "23px",
            borderRadius: isActive ? "0px 5px 0px 0px" : "0px 5px 4px 0px",
            transition: ".5s ease",
            fontSize: "20px",
          }}
          rotate={isActive ? 45 : 0}
        />
      )}
      items={getItems(panelStyle)}
    />
  );
};

export default FrequentlyAsked;
