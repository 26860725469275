import React, { useEffect, useState, version } from "react";
import { Table, Button, Drawer, Input } from "antd";
import { Switch } from "antd";
import { Masterdata } from "./MasterData";
import axios from "axios";
import { BASE_URL } from "../../..";
import { message, Popconfirm } from "antd";
import AudioPlayer from "../SongStatus/Components/AudioPlayer";
import image from "../../../assets/images/Boy_singing_in_his_room.png";
import SingleLineText from "../SongStatus/Components/SingleLineText";
// import { Switch } from "antd";

export const Version = () => {
  const [versionData, setVersionData] = useState(Masterdata.version);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false); // Define setLoading
  const [file1Name, setFile1Name] = useState("");
  const [imageData, setImageData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [editID, setEditID] = useState("");
  const [searchText, setSearchText] = useState(""); // State for search input
  const [selectedImg, setSeletedImg] = useState("");
  const [selectedImgTitle, setSelectedImgTitle] = useState("");

  const [editVersionData, setEditVersionData] = useState({
    name: "",
    price: "",
  });
  const [statusActive, setStatusActive] = useState(false);
  const [audio, setAudio] = useState(null);
  const [duration, setDuration] = useState(null);
  const [input, setInput] = useState({
    versionName: "",
    price: "",
    sampleAudio: "",
    description: "",
    instrumentImg: "",
    enable: "checked",
    categoryStatus: "",
    categoryName: "",
  });
  const [open, setOpen] = useState(false);
  const [playingIndex, setPlayingIndex] = useState(null);
  const [imgInstru, setImgInstru] = useState(false);
  const [switchValue, setSwitchValue] = useState(false);

  const handlePlay = (index) => {
    setPlayingIndex(index);
  };

  const handleSwitchChange = (checked) => {
    setSwitchValue(checked);
    if (!checked) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        categoryName: "", // Clear the error message for the file field
      }));
    }
  };

  const handlePause = () => {
    setPlayingIndex(null);
  };
  useEffect(() => {
    fetchData();
    fetchImageData();
  }, []);

  const confirm = (e) => {
    message.success("Deleted Successfully");
    const token = localStorage.getItem("accessToken");
    axios
      .delete(BASE_URL + "/v1/instrument/" + editID, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // responseType: "arraybuffer",
      })
      .then(() => {
        fetchData();
      })
      .catch((error) => {});
  };

  const onFileSelected = (event) => {
    const file = event.target.files[0]; // Get the selected file
    setFile1Name(file.name); // Set the selected file in state
    setErrors((prevErrors) => ({
      ...prevErrors,
      file: "", // Clear the error message for the file field
    }));
    // sendFileAsBlob(file); // Pass the selected file to the sendFileAsBlob function
  };

  const fetchData = () => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(BASE_URL + "/v1/instrument", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // responseType: "arraybuffer",
      })
      .then((response) => response.data)
      .then((response_data) => {
        setTableData(response_data.data.instruments);
        setAudio(response_data.data.instruments[0].audio);
        // setDuration(response_data.data.instruments[0].audioDuration);
        return response_data.data.instruments[0].audio;
      })
      .then((blob) => {
        setAudio(blob);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const fetchImageData = () => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(BASE_URL + "/v1/instrument/files/imagelist", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // responseType: "arraybuffer",
      })
      .then((response) => response.data)
      .then((response_data) => {
        setImageData(response_data.instrumentImageList);
      })
      .then((blob) => {
        // setAudio(blob);
      })
      .catch((error) => {
        // console.error(error);
      });
  };
  const [errors, setErrors] = useState({});
  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};
    if (!input.versionName) {
      errors.versionName = "Name is required";
    }

    //Validate songMood field
    if (!input.price) {
      errors.price = "Price is required";
    }
    //Validate description
    if (!input.description) {
      errors.description = "Description is required";
    }
    //Validate songMood field
    if (!file1Name) {
      errors.file = "Audio file is required";
    }
    if (!input.instrumentImg) {
      errors.instrumentImg = "Image is required";
    }
    if (switchValue) {
      if (!input.categoryName) {
        errors.categoryName = "Instrument category is required";
      }
    }

    // If there are errors, set them to state and prevent form submission
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    const formData = new FormData();
    formData.append("name", input.versionName);
    formData.append("price", input.price);
    formData.append("audio", document.getElementById("fileInput").files[0]);
    formData.append("description", input.description);
    formData.append("image", selectedImg);
    formData.append("imageTitle", selectedImgTitle);
    formData.append("instrumentCategory", input.categoryName);
    formData.append("bestSeller", switchValue);

    const token = localStorage.getItem("accessToken");
    axios
      .post(`${BASE_URL}/v1/instrument`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // responseType: "arraybuffer",
      })
      .then(() => {
        fetchData();
        message.success("New instrument added successfully");
        setOpen(false);
        setErrors({});
        setFile1Name("");
        setImgInstru(false);
        setSwitchValue(false);
      })
      .catch((error) => {
        message.error(error.response.data.message);
      });
  };
  const handleKeyPressNumber = (e) => {
    const charCode = e.charCode;
    if (!/[0-9]/.test(String.fromCharCode(charCode))) {
      e.preventDefault();
    }
  };
  const handleEditSubmit = (e) => {
    e.preventDefault();
    const errors = {};
    if (!input.versionName) {
      errors.versionName = "Name is required";
    }

    //Validate songMood field
    if (!input.price) {
      errors.price = "Price is required";
    }
    //Validate songMood field
    if (!file1Name) {
      errors.file = "Audio file is required";
    }

    //Validate description
    if (!input.description) {
      errors.description = "Description is required";
    }
    if (!input.instrumentImg) {
      errors.instrumentImg = "Image is required";
    }
    if (switchValue) {
      if (!input.categoryName) {
        errors.categoryName = "Instrument category is required";
      }
    }

    // If there are errors, set them to state and prevent form submission
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    const formData = new FormData();
    formData.append("name", input.versionName);
    formData.append("price", input.price);
    // formData.append('audio',file1Name);

    if (
      document.getElementById("fileInput").files[0] &&
      document.getElementById("fileInput").files[0] !== "undefined"
    ) {
      formData.append("audio", document.getElementById("fileInput").files[0]);
    }

    formData.append("description", input.description);
    formData.append("image", selectedImg);
    formData.append("imageTitle", selectedImgTitle);
    formData.append("instrumentCategory", input.categoryName);
    formData.append("bestSeller", switchValue);
    const token = localStorage.getItem("accessToken");
    axios
      .put(BASE_URL + "/v1/instrument/" + editID, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // responseType: "arraybuffer",
      })
      .then(() => {
        fetchData();
        message.success("Updated Successfully");
        setErrors({});
        setOpen(false);
        setFile1Name("");
        setImgInstru(false);
        setSwitchValue(false);
      })
      .catch((error) => {
        message.error(error.response.data.message);
      });
  };

  const sendFileAsBlob = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const fileData = reader.result; // File data as ArrayBuffer
        const blob = new Blob([fileData], { type: file.type });
      };
      reader.readAsArrayBuffer(file); // Read file as ArrayBuffer
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });

    // Clear the error message for this field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    if (e.target.name === "instrumentImg") {
      for (let index = 0; index < imageData.length; index++) {
        const element = imageData[index];

        setSeletedImg(e.target.value);
        setSelectedImgTitle(e.target.value);
        setImgInstru(true);
      }
    }
  };
  const handleKeyPressName = (e) => {
    const charCode = e.charCode;
    if (!/[a-zA-Z. ]/.test(String.fromCharCode(charCode))) {
      e.preventDefault();
    }
  };
  const addInstrument = () => {
    setEditID(0);
    // Handle edit action here
    setInput({
      versionName: "",
      price: "",
      sampleAudio: "",
      description: "",
      instrumentImg: "",
      categoryName: "",
    });
    setFile1Name("");
    setOpen(true); // Open the drawer for editing
    setSwitchValue(false);
  };

  const handleActive = (id) => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(BASE_URL + "/v1/instrument/" + id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // responseType: "arraybuffer",
      })
      .then((response) => response.data)
      .then((res_data) => {
        if (res_data.data.instrument.status === "ACTIVE") {
          let data = { params: { status: "INACTIVE" } };
          const token = localStorage.getItem("accessToken");
          axios
            .put(BASE_URL + "/v1/instrument/action-control/" + id, null, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              params: data.params,
            })
            .then(() => {
              fetchData();
            });
        } else {
          let data = { params: { status: "ACTIVE" } };
          const token = localStorage.getItem("accessToken");

          axios
            .put(BASE_URL + "/v1/instrument/action-control/" + id, null, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: data.params,
              // responseType: "arraybuffer",
            })
            .then(() => {
              fetchData();
            });
        }
      })
      .catch((error) => {});
  };

  const handleEdit = (id) => {
    setEditID(id);
    setLoading(true);
    const token = localStorage.getItem("accessToken");
    axios
      .get(BASE_URL + "/v1/instrument/" + id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // responseType: "arraybuffer",
      })
      .then((response) => response.data)
      .then((res_data) => {
        var name = res_data.data.instrument.name;
        var price = res_data.data.instrument.price;
        var sampleAudio = res_data.data.instrument.audio;
        var description = res_data.data.instrument.description;
        var image = res_data.data.instrument.image;
        var category =
          res_data.data.instrument.instrumentCategory !== "null"
            ? res_data.data.instrument.instrumentCategory
            : "";
        setSwitchValue(res_data.data.instrument.bestSeller);
        setSeletedImg(image);
        setSelectedImgTitle(image);
        setImgInstru(true);
        // for (let index = 0; index < imageData.length; index++) {
        //   const element = imageData[index];
        //   if (element.imageTitle === res_data.data.instrument.imageTitle) {
        //     var instrumentImg = element.imageTitle;
        //     setSeletedImg(element.image);
        //     setSelectedImgTitle(element.imageTitle);
        //     setImgInstru(true);
        //   }
        // }

        setInput({
          versionName: name,
          price: price,
          sampleAudio: sampleAudio,
          description: description,
          instrumentImg: image,
          categoryName: category,
        });
        const updatedRes = res_data.data.instrument.audio.substring(
          res_data.data.instrument.audio.indexOf("-") + 1
        );
        setFile1Name(updatedRes);
        setLoading(false);
      })
      .catch((error) => {});

    setOpen(true); // Open the drawer for editing
  };

  const handleDelete = (id) => {
    setEditID(id);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const handleEnableChange = (checked, record) => {
    const updatedData = versionData.map((item) =>
      item.sno === record.sno
        ? { ...item, enable: checked ? "checked" : true }
        : item
    );
    setVersionData(updatedData);
    Masterdata.version = updatedData;
  };

  // drawer

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setErrors({});
    setInput({
      versionName: "",
      price: "",
      sampleAudio: "",
      description: "",
      instrumentImg: "",
      categoryName: "",
    });
    setImgInstru(false);
    setOpen(false);
  };

  const handleRowSelectionChange = (selectedRowKeys, selectedRows) => {
    setSelectedRows(selectedRows);
  };

  const selectedVersionNames = selectedRows.map((row) => row.versionName);
  const deleteSelectedRows = () => {
    const idsToDelete = [];
    for (let i = 0; i < selectedRows.length; i++) {
      const row = selectedRows[i];
      idsToDelete.push(row.id);
    }
    let data = idsToDelete;
    const token = localStorage.getItem("accessToken");
    axios
      .delete(BASE_URL + "/v1/instrument/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      })
      .then(() => {
        fetchData();
      })
      .catch((error) => {});

    setSelectedRows([]); // Clear selected rows after deletion
  };
  const handleEnableSeletedChange = () => {
    const idsToDelete = [];
    for (let i = 0; i < selectedRows.length; i++) {
      const row = selectedRows[i];
      idsToDelete.push(row.id);
    }
    let data = idsToDelete;
    const token = localStorage.getItem("accessToken");
    axios
      .put(BASE_URL + "/v1/instrument/action-controls?status=ACTIVE", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // responseType: "arraybuffer",
      })
      .then(() => {
        fetchData();
      })
      .catch((error) => {});

    setSelectedRows([]);
  };

  const hasSelected = selectedRows.length > 1;

  const columns = [
    {
      title: "SI.No",
      dataIndex: "sno",
      width: 100,
      align: "left",
      fixed: "left",
    },
    {
      title: "Instrument Name",
      dataIndex: "versionName",
      width: 300,
    },
    {
      title: "Price",
      dataIndex: "price",
      width: 150,
    },
    {
      title: "Sample audio file",
      dataIndex: "sampleAudio",
      width: 400,
    },
    {
      title: "Instument Category Status",
      dataIndex: "bestSeller",
      align: "center",
      width: 150,
    },
    {
      title: "Instrument Category",
      dataIndex: "instrumentCategory",
      width: 150,

      // render: (instrumentCategory) =>
      //   instrumentCategory ? <span>{instrumentCategory}</span> : <span>-</span>,
    },

    {
      title: "Description",
      dataIndex: "description",
      width: 400,
      render: (description) => {
        return <SingleLineText text={description} />;
      },
    },
    {
      title: "Image",
      dataIndex: "image",
      width: 200,
      align: "center",
    },

    {
      title: "Action",
      align: "center",
      dataIndex: "actions",
      width: 180,
      fixed: "right",
    },
  ];

  const data = tableData
    .filter((item) =>
      item.name.toLowerCase().includes(searchText.toLowerCase())
    )
    .map((item, index) => ({
      ...item,
      key: index.toString(),
      sno: index + 1,
      versionName: item.name,
      price: item.price,
      sampleAudio: item.audio,
      actions: (
        <>
          <Button
            type="link"
            className="edit-hover"
            onClick={() => handleEdit(item.id)}
          >
            <i className="bi bi-pencil-fill sec-clr edit-icon-hover"></i>
          </Button>
          <Switch
            // checked={item.status == 'ACTIVE' ? "checked" : ''}
            checked={item.status == "ACTIVE" ? "checked" : null}
            onChange={() => handleActive(item.id)}
            className="pri-clr"
            size="small"
          />
          <Popconfirm
            title="Are you sure to delete?"
            // description="Are you sure to delete?"
            onConfirm={confirm}
            // onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="link"
              className="edit-hover"
              onClick={() => handleDelete(item.id)}
            >
              <i className="bi bi-trash3-fill sec-clr delete-icon-hover"></i>
            </Button>
          </Popconfirm>
        </>
      ),
      instrumentCategory:
        item.instrumentCategory !== "null" ? (
          <span>{item.instrumentCategory}</span>
        ) : (
          "-"
        ),

      sampleAudio: (
        <div>
          {
            <AudioPlayer
              src={BASE_URL + "/v1/form/song/" + item.audio}
              item={item}
              isPlaying={playingIndex === index}
              onPlay={() => handlePlay(index)}
              onPause={handlePause}
            />
          }
          {/* {audioDuration} */}
        </div>
      ),
      image: (
        <div>
          <img
            src={`${BASE_URL}/v1/instrument/instrumentimage/${item.image}`}
            alt=""
            style={{ maxHeight: "150px" }}
            className="img-fluid"
          />
        </div>
      ),
      bestSeller: (
        <div>
          <Switch size="small" disabled checked={item.bestSeller} />
        </div>
      ),

      enable: "checked",
    }));

  return (
    <>
      <div className="d-flex justify-content-end">
        <Input
          size="small"
          className="search fs-14 fw-6"
          placeholder="Search"
          prefix={<i className="bi bi-search"></i>}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <Button
          type="primary"
          size="large"
          className="button-bg"
          onClick={addInstrument}
        >
          <i className="bi bi-plus"></i>
          New Instrument
        </Button>
      </div>
      <div className="">
        {hasSelected && (
          <div className="d-flex  whole-delete">
            <div className="fw-6 fs-16">{selectedRows.length} Selected</div>
            <div>
              <Switch
                onChange={handleEnableSeletedChange}
                className="pri-clr whole-enable"
                size="small"
              />
              <button onClick={deleteSelectedRows} className="whole-delete-btn">
                <i className="bi bi-trash3-fill pri-clr"></i>
              </button>
            </div>
          </div>
        )}
      </div>
      <Table
        // rowSelection={rowSelection}
        rowSelection={{
          type: "checkbox",
          onChange: handleRowSelectionChange,
          selectedRowKeys: selectedRows.map((row) => row.key),
        }}
        scroll={{
          x: 0,
          y: "50svh",
        }}
        columns={columns}
        dataSource={data}
        loading={loading} // Use loading state in Table component if needed
        bordered
        pagination={true}
      />
      <Drawer open={open} closable={false}>
        <div className="nav-content">
          <div className="d-flex justify-content-between sec-clr nav-title align-items-center">
            <h2 className="pri-clr fw-7 fs-30">
              {!editID ? "Add New Instrument" : "Edit Instrument"}
            </h2>
            <i
              className="bi bi-x fs-26 fw-bold close-btn"
              onClick={onClose}
            ></i>
          </div>
          <div className="">
            <form
              action=""
              onSubmit={editID != "" ? handleEditSubmit : handleSubmit}
            >
              <div>
                <label
                  htmlFor="name"
                  className="side-nav-label fw-5 fs-16 c_454F5B"
                >
                  Instrument Name
                </label>
                <span className="text-danger">*</span>
                <br />
                <input
                  type="text"
                  className="side-nav-input rounded"
                  name="versionName"
                  placeholder="Enter Version Name"
                  id="versionName"
                  onChange={handleInput}
                  onKeyPress={handleKeyPressName}
                  value={input.versionName || ""}
                />
                {errors.versionName && (
                  <div className="text-danger">{errors.versionName}</div>
                )}
              </div>
              <div>
                <label
                  htmlFor="name"
                  className="side-nav-label fw-5 fs-16 mt-4 c_454F5B"
                >
                  Price
                </label>
                <span className="text-danger">*</span>
                <br />
                <input
                  type="text"
                  className="side-nav-input rounded"
                  name="price"
                  placeholder="Enter Price"
                  id="price"
                  onChange={handleInput}
                  onKeyPress={handleKeyPressNumber}
                  value={input.price || ""}
                />
                {errors.price && (
                  <div className="text-danger">{errors.price}</div>
                )}
              </div>
              <div>
                <label
                  htmlFor="name"
                  className="side-nav-label fw-5 fs-16 mt-4 c_454F5B"
                >
                  Sample Audio File
                </label>
                <span className="text-danger">*</span>
                <br />
                <div className="side-nav-input rounded">
                  <label htmlFor="fileInput" className="file-field">
                    <div className="choose-file-btn">Choose File</div>
                    <input
                      className="choose-file-hidden"
                      type="file"
                      id="fileInput"
                      accept="audio/mp3,audio/wav"
                      onChange={onFileSelected}
                    />
                  </label>
                  <span>{file1Name}</span>
                </div>
                {errors.file && (
                  <div className="text-danger">{errors.file}</div>
                )}
              </div>
              <div className="mt-3">
                <label
                  htmlFor="name"
                  className="side-nav-label fw-5 fs-16 c_454F5B"
                >
                  Instrument Category
                </label>
                {switchValue && <span className="text-danger">*</span>}

                <br />
                <Switch
                  size="small"
                  onChange={handleSwitchChange}
                  checked={switchValue}
                />
                <input
                  type="text"
                  className="side-nav-input-instrument rounded"
                  name="categoryName"
                  placeholder="Enter Instrument Category"
                  id="categoryName"
                  onChange={handleInput}
                  // onKeyPress={handleKeyPressName}
                  value={input.categoryName || ""}
                />
                {errors.categoryName && (
                  <div className="text-danger">{errors.categoryName}</div>
                )}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="name"
                  className="side-nav-label fw-5 fs-16 mt-4 c_454F5B"
                >
                  Description
                </label>
                <span className="text-danger">*</span>
                <br />
                <textarea
                  className="side-nav-input rounded"
                  name="description"
                  placeholder="Enter description"
                  onChange={handleInput}
                  value={input.description}
                  rows={"5"}
                  maxLength={750}
                />

                {errors.description && (
                  <div className="text-danger">{errors.description}</div>
                )}
              </div>
              <div>
                <label
                  htmlFor="name"
                  className="side-nav-label fw-5 fs-16 c_454F5B"
                >
                  Image
                </label>
                <span className="text-danger">*</span>
                <br />
                <select
                  name="instrumentImg"
                  onChange={handleInput}
                  value={input.instrumentImg || "select"}
                  className="side-nav-input rounded c_454F5B mb-3"
                >
                  <option value="select" disabled>
                    Select Image
                  </option>
                  {imageData.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>

                {errors.instrumentImg && (
                  <div className="text-danger">{errors.instrumentImg}</div>
                )}
              </div>
              {imgInstru && (
                <div>
                  <label
                    htmlFor="name"
                    className="side-nav-label fw-5 fs-16 c_454F5B"
                  >
                    Image Preview
                  </label>
                  <span className="text-danger">*</span>
                  <br />
                  <img
                    src={`${BASE_URL}/v1/instrument/instrumentimage/${selectedImg}`}
                    alt=""
                    style={{ height: "240px", width: "440px" }}
                  />
                </div>
              )}

              <div className="button-side-nav d-flex justify-content-center">
                <Button size="large" className="button-bg" htmlType="submit">
                  {!editID ? "Add" : "Update"}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Drawer>
      <div id="audio-container"></div>
    </>
  );
};
