import React, { useState } from "react";
import { Tabs } from "antd";
import { Version } from "./Version";
import { Languages } from "./Languages";
import { Mood } from "./Mood";
import { TextFields } from "../AdminSettings/TextFields";
import { Button, Drawer } from "antd";
import { Payment } from "./Payment";
import { MixingDelivery } from "./MixingDelivery";
import { Artist } from "./Artist";

export const MasterBase = () => {
  //tab
  const { TabPane } = Tabs;

  const items = [
    { key: "1", label: "Instrument", children: <Version /> },
    { key: "2", label: "Languages", children: <Languages /> },
    // { key: "3", tab: "Mood", content: <Mood /> },
    { key: "6", label: "Artist", children: <Artist /> },
    // { key: "4", tab: "Payment", content: <Payment /> },
    {
      key: "5",
      label: "MixingMaster & DeliveryDate",
      children: <MixingDelivery />,
    },
  ];

  // State to store the current active tab key
  const [activeTab, setActiveTab] = useState("1");

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <div className="masterBase">
      {/* Button with dynamic text */}

      {/* Tabs */}
      {/* <Tabs
        defaultActiveKey="1"
        indicator={{ size: 30, align: "center" }}
        onChange={handleTabChange}
      >
        {items.map(({ key, tab, content }) => (
          <TabPane tab={tab} key={key}>
            {content}
          </TabPane>
        ))}
      </Tabs> */}
      <Tabs
        defaultActiveKey="1"
        activeKey={activeTab}
        onChange={handleTabChange}
        items={items} // Use the `items` prop
      />
    </div>
  );
};
