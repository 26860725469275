import Navbar from "../Base/Navbar";
import re_imge3 from "../../assets/images/pageimg.png";
import de_img1 from "../../assets/images/pay-dialog-bg-img1-.png";
import de_img2 from "../../assets/images/pay-dialog-bg-img2-.png";
import { Flex, Rate } from "antd";
import {
  CameraFilled,
  UserSwitchOutlined,
  VideoCameraFilled,
} from "@ant-design/icons";
import { Button, message, Upload } from "antd";
// import { Upload, Button, message } from 'antd';
import { UploadOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../..";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export const AddReview = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    mobileNumber: "",
    email: "",
    artist: "",
    version: "",
    mood: "",
    songto: "",
    occasion: "",
    songFrom: "",
    mixingMaster: "",
    masterRequest: "",
    deliveryDate: "",
    deliveryChrg: "",
    story: "",
    clipPath: "",
    adminSong: "",
    orderNumber: "",
    customerDeliveryDate: "",
  });

  const params = useParams();

  const id = params.id;

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .get(BASE_URL + "/v1/form/rating-review/" + id)
      .then((response) => response.data)
      .then((res_data) => {
        setFormData({
          artist: res_data.data.formRequestDto.artist,
          version: res_data.data.formRequestDto.instrument,
          mood: res_data.data.formRequestDto.mood,
          songto: res_data.data.formRequestDto.songto,
          occasion: res_data.data.formRequestDto.occasion,
          songFrom: res_data.data.formRequestDto.songfrom,
          masterRequest: res_data.data.formRequestDto.masterRequest,
          mixingMaster: res_data.data.formRequestDto.mixtureMaster,
          deliveryDate: res_data.data.formRequestDto.deliveryDate,
          deliveryChrg: res_data.data.formRequestDto.deliveryDate,
          story: res_data.data.formRequestDto.story,
          clipPath: res_data.data.formRequestDto.clipPath,
          orderNumber: res_data.data.formRequestDto.orderNumber,
          name: res_data.data.formRequestDto.name,
          mobileNumber: res_data.data.formRequestDto.phonenumber,
          email: res_data.data.formRequestDto.mail,
          adminSong: res_data.data.formRequestDto.adminClipPath,
          customerDeliveryDate:
            res_data.data.formRequestDto.customerDeliveryDate,
        });
        if (
          res_data.data.formRequestDto.rating !== "0" ||
          res_data.data.formRequestDto.review !== null ||
          res_data.data.formRequestDto.videoPath !== null ||
          res_data.data.formRequestDto.images !== null
        ) {
          Swal.fire({
            title: "You have already reviewed",
            allowOutsideClick: false,
            allowEscapeKey: false,
            confirmButtonText: "OK",
            willClose: () => {
              navigate("/");
            },
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [rate, setRate] = useState(0); //ratting
  const [reviewText, setReviewText] = useState(""); //comment
  const count = ["1", "2", "3", "4", "5"];
  // const [fileList, setFileList] = useState([]); //image
  const [file1Name, setFile1Name] = useState();
  const [fileListVideo, setFileListVideo] = useState([]); //video
  const [fileList, setFileList] = useState([]);
  const [fileNames, setFileNames] = useState([]);

  const handleText = (e) => {
    setReviewText(e.target.value);
  };
  const onFileSelected1 = (event) => {
    const files = Array.from(event.target.files);

    setFileList(files);
    // setFileList((prevFileList) => [...prevFileList, ...files]);
    setFileNames((prevFileNames) => [
      ...prevFileNames,
      ...files.map((file) => file.name),
    ]);
  };
  const uploadFiles = async () => {
    try {
      const formData = new FormData();
      // fileList.forEach((file) => {
      //   formData.append("files", file);
      // });

      // const response = await axios.post(
      //   "https://your-upload-endpoint.com/upload",
      //   formData,
      //   {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }
      // );

      message.success("Files uploaded successfully");
    } catch (error) {
      message.error("File upload failed");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      text: reviewText,
      rate: rate,
      img: fileList,
      video: fileListVideo,
    };

    const formData = new FormData();

    // formData.append("video", document.getElementById("fileInput").files[0]);
    // fileList.forEach((file, index) => {
    //   formData.append(`image`, file);
    // });
    fileList.forEach((file) => {
      formData.append("images", file.originFileObj);
    });
    fileListVideo.forEach((file) => {
      formData.append("video", file.originFileObj);
    });

    axios
      .put(
        BASE_URL +
          "/v1/form/rating-review/" +
          id +
          "?rating=" +
          rate +
          "&review=" +
          reviewText,

        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        message.success("Review submitted successfully!");
        navigate("/");
      })
      .catch((error) => {
        message.error(error.response.data.message);
      });
  };
  const onFileSelected = (event) => {
    const file = event.target.files[0]; // Get the selected file
    setFile1Name(file.name); // Set the selected file in state
    // sendFileAsBlob(file); // Pass the selected file to the sendFileAsBlob function
  };

  // Function to validate image upload

  const handleChange = (info) => {
    let newFileList = [...info.fileList];
    setFileList(newFileList);

    newFileList.forEach((file) => {});
  };
  const handleChangeVid = (info) => {
    let newFileList = [...info.fileList].slice(-1); // keep only the last file
    setFileListVideo(newFileList);
  };

  const customRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const customRequestVid = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <>
      <Navbar />
      <section className=" bg-color reviewScr DM_Sans of-hidden ">
        <div className="container-fluid ms-xl-5 ms-lg-5 ms-md-5 ms-sm-2">
          <div className="row ">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="heading c_F77B21">Add Review</h2>
                  <div className="row">
                    <h6>
                      <span
                        className=" orderdetail fw-5"
                        style={{ fontSize: "20px" }}
                      >
                        Order Detail-
                      </span>
                      <span className="orderdetail orderid c_F77B21">
                        Order ID: {formData.orderNumber}
                      </span>
                    </h6>
                  </div>
                </div>
              </div>
              <div className="container artistdetail bg-white p-3">
                <div className="row mt-4">
                  <div className="col-md-4">
                    <label className=" fw-6 c_666666">Name</label>
                    <span className=" fw-6 c_0A0D13 ps-4">
                      : {formData.name}
                    </span>
                  </div>
                  <div className="col-md-4">
                    <label className=" fw-6 c_666666">Number</label>
                    <span className=" fw-6 c_0A0D13 ps-3">
                      : {formData.mobileNumber}
                    </span>
                  </div>
                  <div className="col-md-4">
                    <label className="  fw-6 c_666666">Email</label>
                    <span className=" fw-6 c_0A0D13 ps-3">
                      : {formData.email}
                    </span>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-4">
                    <label className=" fw-6 c_666666">Artist</label>
                    <span className=" fw-6 c_0A0D13 ps-4">
                      : {formData.artist}
                    </span>
                  </div>
                  <div className="col-md-4">
                    <label className=" fw-6 c_666666">Version</label>
                    <span className=" fw-6 c_0A0D13 ps-3">
                      : {formData.version}
                    </span>
                  </div>
                  <div className="col-md-4">
                    <label className="  fw-6 c_666666">Mood</label>
                    <span className=" fw-6 c_0A0D13 ps-3">
                      : {formData.mood}
                    </span>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-4">
                    <label className="  fw-6 c_666666">Songto</label>
                    <span className=" fw-6 c_0A0D13 ps-3">
                      : {formData.songto}
                    </span>
                  </div>
                  <div className="col-md-4">
                    <label className=" fw-6 c_666666">SongFrom</label>
                    <span className=" fw-6 c_0A0D13 ps-3">
                      : {formData.songFrom}
                    </span>
                  </div>
                  <div className="col-md-4">
                    <label className="  fw-6 c_666666">Occassion</label>
                    <span className=" fw-6 c_0A0D13 ps-3">
                      : {formData.occasion}
                    </span>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-6">
                    <label className="  fw-6 c_666666">
                      Select an Mixing Master
                    </label>
                    <span className=" fw-6 c_0A0D13 ps-3">
                      : {formData.mixingMaster}
                    </span>
                  </div>
                  <div className="col-md-6">
                    <label className="  fw-6 c_666666">Song Delivery</label>
                    <span className=" fw-6 c_0A0D13 ps-3">
                      {formData.deliveryDate
                        ? formData.deliveryDate
                        : formData.customerDeliveryDate}
                    </span>
                  </div>
                </div>
                <div className="row mt-4 order-review">
                  <div className="col-md-6">
                    <label className=" fw-6 c_666666">Your story:</label>
                    <span className=" fw-6 c_0A0D13 mt-3">
                      <p> {formData.story}</p>
                    </span>
                  </div>
                  <div className="col-md-6" id="order-review">
                    <label className="fw-6 c_666666 d-block">
                      Your story audio
                    </label>
                    <audio
                      src={BASE_URL + "/v1/form/song/" + formData.clipPath}
                      //   src="crowd-cheering.mp3"
                      className="audio-data"
                      controls
                      controlsList="nodownload noplaybackrate"
                    ></audio>
                  </div>
                </div>
              </div>

              <div className="row mt-4 text-xl-start text-lg-start text-md-start text-center">
                <div className="col-lg-6" id="order-review">
                  <h6 className=" fw-bold c_0A0D13 d-block text-start">
                    Our Composed song for you:
                  </h6>
                  <div className="audio-container text-start ps-xs-2 ">
                    <audio
                      src={BASE_URL + "/v1/form/song/" + formData.adminSong}
                      // src="crowd-cheering.mp3"
                      className="audio-data"
                      controls
                      controlsList="nodownload noplaybackrate"
                    ></audio>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-xl-start text-lg-start text-md-start">
                  <h6 className=" fw-bold c_0A0D13 text-start">Rate a song:</h6>
                  {/* <div className="ratting"> */}
                  <div className=" py-2 px-3 ratting bg-white">
                    <Flex gap="middle" vertical>
                      <Rate
                        className="custom-rate"
                        tooltips={count}
                        onChange={setRate}
                        value={rate}
                      />
                      {/* <span className="fw-bold c_0A0D13"> Ratting:</span> */}
                    </Flex>
                  </div>
                </div>
              </div>
              <form action="" onSubmit={handleSubmit}>
                <div className="row py-3">
                  <div className="col-md-6">
                    <h6 className=" fw-6 c_0A0D13">Write a Review:</h6>
                    <textarea
                      className="w-100 bg-color ps-3 d-block mt-3 custom-textarea "
                      placeholder="Your feedback matters!How has the product influenced you?"
                      onChange={handleText}
                      value={reviewText}
                    >
                      {reviewText}
                    </textarea>
                  </div>
                  <div className="col-md-6 ">
                    <div className="d-xl-flex d-lg-flex d-md-flex d-sm-block gap-3">
                      <Upload
                        customRequest={customRequest}
                        listType="picture"
                        fileList={fileList}
                        onChange={handleChange}
                        multiple={true}
                        className="custom-upload"
                      >
                        <Button
                          icon={<CameraFilled className="icon" />}
                          className="uploadButton no-bg"
                        >
                          Upload Image
                        </Button>
                      </Upload>

                      <Upload
                        customRequest={customRequestVid}
                        listType="video"
                        fileList={fileListVideo}
                        onChange={handleChangeVid}
                        multiple={false}
                        className="custom-upload"
                      >
                        <Button
                          icon={<VideoCameraFilled className="icon" />}
                          className="uploadButton no-bg"
                        >
                          Upload Video
                        </Button>
                      </Upload>
                    </div>
                  </div>
                </div>
                <button type="submit" className="continue-btn d-block mt-2">
                  Submit
                </button>
              </form>
            </div>

            <div className="col-sm-4  d-none d-md-block">
              <img src={de_img2} alt="img" className="dg_img img-fluid" />
              <img src={de_img1} alt="img" className="dg_img img-fluid" />
              <img src={re_imge3} alt="img" className="pageimg img-fluid" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
