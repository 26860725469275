import React, { useState, useEffect } from "react";
import { Table, Input, Button, message, DatePicker, Spin } from "antd";
import SingleLineText from "./Components/SingleLineText";
import AudioPlayer from "./Components/AudioPlayer";
import { CheckOutlined } from "@ant-design/icons";
import axios from "axios";
import { BASE_URL } from "../../..";
import { Select, Tag } from "antd";
import moment from "moment";
import {
  DownloadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import { Image, Space } from "antd";

export const AllSongs = ({
  handleFieldClick,
  setSavedSongCount,
  setConfirmedSongCount,
  setcomposingSongCount,
  setRecordingSongCount,
  setDeliveredSongCount,
  additionalProp1,
}) => {
  const [tableData, setTableData] = useState([]); // Initialize as an empty array
  const [totalElements, setTotalElements] = useState(""); // Initialize as an empty array
  const [statusValue, setStatusValue] = useState("");
  const [oldStatusValue, setOldStatusValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [reloadKey, setReloadKey] = useState(0);
  const [loading, setLoading] = useState(false);
  const [playingIndex, setPlayingIndex] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [originalData, setOriginalData] = useState([]); // Original unfiltered data
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 }); // Pagination state
  const [adminId, setadminId] = useState(localStorage.getItem("adminId"));
  const [dates, setDates] = useState("");
  const [datesForDisable, setDatesForDisable] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { RangePicker } = DatePicker;
  const [searchValue, setSearchValue] = useState("");

  const handlePlay = (index) => {
    setPlayingIndex(index);
  };

  const handlePause = () => {
    setPlayingIndex(null);
  };
  const handleReload = () => {
    setReloadKey((prevKey) => prevKey + 1); // Incrementing key to force re-render
  };

  const fetchData = (page = 0, size = 10, search = "") => {
    setLoading(true);
    const token = localStorage.getItem("accessToken");
    axios
      .get(
        `${BASE_URL}/v1/form/paginated?page=${page}&size=${size}&sortBy=orderPosition.updatedDate&sortDirection=desc&query=${search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          // responseType: "arraybuffer",
        }
      )
      .then((response) => response.data)
      .then((res_data) => {
        setTotalElements(res_data.data.formRequestDtos.totalElements);
        setTableData(res_data.data.formRequestDtos.forms);
        setOriginalData(res_data.data.formRequestDtos.forms);
        setLoading(false);
        // setPagination((prevPagination) => ({
        //   ...prevPagination,
        //   total: res_data.data.formRequestDtos.totalElements,
        // }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    // setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));
    fetchData();
  }, [additionalProp1 == "1", reloadKey]);

  const handleStatusChange = (value, record) => {
    setOldStatusValue(record);
    setStatusValue(value);
    // Handle the status change here, e.g., update the state or send an API request
    // setSelectedSongStatus((prev) => ({ ...prev, [record.orderNumber]: value }));
  };
  const changeStatus = (record, data) => {
    const formData = new FormData();
    if (statusValue === "") {
    } else {
      formData.append("orderPosition", statusValue);
      const token = localStorage.getItem("accessToken");
      axios
        .put(BASE_URL + "/v1/form/" + record.id, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          // responseType: "arraybuffer",
        })
        .then(() => {
          setVisible(true);
          confirm();
          fetchData();
          handleReload();
          getCount();
        })
        .catch((error) => {
          console.error("Data Saveing : " + error);
        });
      setStatusValue("");
    }

    // formData.append("orderPosition", "ORDER_CONFIRMED");
  };
  const onDownload = (src) => {
    fetch(src)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = "image.png";
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      });
  };

  const statusDisplayMap = {
    ORDER_SAVED: "Order Saved",
    ORDER_CONFIRMED: "Order Confirmed",
    SONG_COMPOSING: "Song Composing",
    SONG_RECORDING: "Song Recording",
    DELIVERED: "Delivered",
  };

  const dropdown = (item) => {
    let tagColor;
    switch (item.status) {
      case "ORDER_SAVED":
        tagColor = "blue";
        break;
      case "ORDER_CONFIRMED":
        tagColor = "orange";
        break;
      case "SONG_COMPOSING":
        tagColor = "error";
        break;
      case "SONG_RECORDING":
        tagColor = "purple";
        break;
      case "DELIVERED":
        tagColor = "green";
        break;
      default:
        tagColor = "grey";
    }

    const displayText = statusDisplayMap[item.status] || item.status;

    return <Tag color={tagColor}>{displayText}</Tag>;
  };

  const getCount = () => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(BASE_URL + "/v1/form/?position=" + statusValue, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // responseType: "arraybuffer",
      })
      .then((response) => response.data)
      .then((res_data) => {
        if (statusValue === "ORDER_SAVED") {
          setSavedSongCount(res_data.data.formDtos.count);
        } else if (statusValue === "ORDER_CONFIRMED") {
          setConfirmedSongCount(res_data.data.formDtos.count);
        } else if (statusValue === "SONG_COMPOSING") {
          setcomposingSongCount(res_data.data.formDtos.count);
        } else if (statusValue === "SONG_RECORDING") {
          setRecordingSongCount(res_data.data.formDtos.count);
        } else if (statusValue === "DELIVERED") {
          setDeliveredSongCount(res_data.data.formDtos.count);
        }
        // setSavedSongCount(res_data.data.formDtos.count);
      });
    getOldStatus();
  };
  const getOldStatus = () => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(BASE_URL + "/v1/form/?position=" + oldStatusValue, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // responseType: "arraybuffer",
      })
      .then((response) => response.data)
      .then((res_data) => {
        if (oldStatusValue === "ORDER_SAVED") {
          setSavedSongCount(res_data.data.formDtos.count);
        } else if (oldStatusValue === "ORDER_CONFIRMED") {
          setConfirmedSongCount(res_data.data.formDtos.count);
        } else if (oldStatusValue === "SONG_COMPOSING") {
          setcomposingSongCount(res_data.data.formDtos.count);
        } else if (oldStatusValue === "SONG_RECORDING") {
          setRecordingSongCount(res_data.data.formDtos.count);
        } else if (oldStatusValue === "DELIVERED") {
          setDeliveredSongCount(res_data.data.formDtos.count);
        }
        // setSavedSongCount(res_data.data.formDtos.count);
      });
  };
  const statusTag = (status) => {
    let color = "default";
    let text = "N/A";

    switch (status) {
      case "paid":
        color = "green";
        text = "Success";
        break;
      case "attempted":
        color = "red";
        text = "Failed";
        break;
      default:
        color = "grey";
        text = "Pending";
    }

    return <Tag color={color}>{text}</Tag>;
  };
  const confirm = () => {
    setVisible(false);
    message.success("Updated Successfully");
  };

  // xxxxxxxxxxxxxxxxxx  Table Column Header Data End xxxxxxxxxxxxxxxxxx

  // =====================  Table Body Data =======================

  const data = tableData.map((item, index) => ({
    ...item,
    key: index.toString(),
    sno: index + 1,
    status: dropdown(item),
    // deliveryDate1: <DateSelector />,
    screenshot:
      item.screenshot !== null ? (
        <Image
          width={140}
          src={BASE_URL + "/v1/form/song/" + item.screenshot}
          style={{ maxWidth: "50%", maxHeight: "50%" }}
          preview={{
            toolbarRender: (
              _,
              {
                transform: { scale },
                actions: {
                  onFlipY,
                  onFlipX,
                  onRotateLeft,
                  onRotateRight,
                  onZoomOut,
                  onZoomIn,
                },
              }
            ) => (
              <Space size={12} className="toolbar-wrapper">
                <DownloadOutlined
                  onClick={() =>
                    onDownload(BASE_URL + "/v1/form/song/" + item.screenshot)
                  }
                />
                <SwapOutlined rotate={90} onClick={onFlipY} />
                <SwapOutlined onClick={onFlipX} />
                <RotateLeftOutlined onClick={onRotateLeft} />
                <RotateRightOutlined onClick={onRotateRight} />
                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
              </Space>
            ),
          }}
        />
      ) : (
        "-"
      ),

    // <img
    //   src={BASE_URL + "/v1/form/song/" + item.screenshot} // Assuming upiPath contains the URL of the image
    //   alt=""
    //   style={{ maxWidth: "50%", maxHeight: "50%" }}
    // />
    comments: item.comments !== null ? item.comments : "-",
    deliveryDate: item.deliveryDate !== null ? item.deliveryDate : "-",
    orderTotal: item.orderTotal !== null ? item.orderTotal : "-",
    // <DateSelector value={null} />
    action: (
      <Button
        size="small"
        onClick={() => changeStatus(item, item.status)}
        icon={<CheckOutlined />}
      />
    ),

    clipPath: (
      <AudioPlayer
        src={BASE_URL + "/v1/form/song/" + item.clipPath}
        item={item}
        isPlaying={playingIndex === index}
        onPlay={() => handlePlay(index)}
        onPause={handlePause}
      />
    ),
  }));
  // =====================  Table Column Header Data =======================
  const originalColumns = [
    {
      title: "Created Date",
      width: 110,
      dataIndex: "createdDate",
      fixed: "left",
      render: (createdDate) => moment(createdDate).format("DD-MM-YYYY"),
    },
    {
      title: "Order Number",
      width: 100,
      dataIndex: "orderNumber",
      fixed: "left",
    },
    {
      title: "Client Name",
      width: 150,
      fixed: "left",
      dataIndex: "name",
    },
    {
      title: "Mobile Number",
      dataIndex: "phonenumber",
      width: 150,
    },
    {
      title: (
        <p>
          Email ID <br />
          <span className="small">Click the email id to copy</span>
        </p>
      ),
      dataIndex: "mail",
      width: 250,
      render: (email) => {
        return (
          <a
            onClick={(e) => handleFieldClick(e)}
            href={`mailto:${email}`}
            key={email}
            className="text-dark"
          >
            {email}
          </a>
        );
      },
    },
    {
      title: "Language",
      dataIndex: "lanuage",
      width: 130,
    },
    {
      title: "Artist",
      dataIndex: "artist",
      width: 130,
    },
    {
      title: "Instrument",
      dataIndex: "instrument",
      width: 150,
    },
    // {
    //   title: "Inst. Version",
    //   dataIndex: "instVersion",
    //   width: 150,
    // },
    // {
    //   title: "Mood",
    //   dataIndex: "mood",
    //   width: 150,
    // },
    {
      title: "Song From",
      dataIndex: "songfrom",
      width: 150,
    },
    {
      title: "Song To",
      dataIndex: "songto",
      width: 150,
    },
    {
      title: "Occasion",
      dataIndex: "occasion",
      width: 150,
    },
    {
      title: "Story",
      dataIndex: "story",
      width: 400,
      render: (story) => {
        return <SingleLineText text={story} />;
      },
    },
    {
      title: "Audio File",
      dataIndex: "clipPath",
      width: 280,
      // render: (mp3) => {
      //   return <AudioPlayer src={mp3} />;
      // },
    },
    {
      title: "Payment Order Id",
      dataIndex: "paymentOrderId",
      width: 250,
    },
    {
      title: "Order Total",
      align: "center",
      width: 150,
      dataIndex: "orderTotal",
    },
    {
      title: "Payment Status",
      align: "center",
      width: 150,
      dataIndex: "paymentStatus",
      render: (status) => statusTag(status),
    },
    {
      title: "Status",
      width: 200,
      dataIndex: "status",
      align: "center",
    },
    {
      title: "Customer's Selected Date",
      dataIndex: "customerDeliveryDate",
      width: 150,
    },
    // {
    //   title: "Delivery Date",
    //   dataIndex: "deliveryDate",
    //   width: 150,
    // },
    // {
    //   title: "Comment",
    //   dataIndex: "comments",
    //   width: 200,
    // },
    // {
    //   title: "Action",
    //   fixed: "right",
    //   dataIndex: "action",
    //   width: 100,
    //   align: "center",
    //   render: (_, record) => (
    //     <Popconfirm
    //       open={visible}
    //       onConfirm={confirm}
    //       onCancel={() => setVisible(false)}
    //       okText="Yes"
    //       cancelText="No"
    //     >
    //       <Button
    //         size="small"
    //         onClick={() => changeStatus(record)}
    //         icon={<CheckOutlined />}
    //       />
    //     </Popconfirm>
    //   ),
    // },
  ];
  let columns = originalColumns;
  const admin = localStorage.getItem("token");
  if (admin != 1) {
    const columnsToRemove = [
      "phonenumber",
      "screenshot",
      "orderTotal",
      "mail",
      "paymentOrderId",
    ];
    columns = originalColumns.filter(
      (column) => !columnsToRemove.includes(column.dataIndex)
    );
  }
  const handleRangeChange = (dates, dateStrings) => {
    setDates(dateStrings);
    setDatesForDisable(dates);
  };

  // UseEffect to filter the table data based on search text
  useEffect(() => {
    if (searchText === "") {
      setTableData(originalData); // Reset to original data if search text is empty
    } else {
      const filteredData = originalData.filter((item) => {
        const searchTextLower = searchText.toLowerCase();
        const createdDateStr = moment(item.createdDate)
          .format("DD-MM-YYYY")
          .toLowerCase();
        const customerDeliveryDateStr = moment(item.customerDeliveryDate)
          .format("DD-MM-YYYY")
          .toLowerCase();
        const statusDisplay = statusDisplayMap[item.status] || item.status;
        return (
          item.name.toLowerCase().includes(searchTextLower) ||
          item.orderNumber.toLowerCase().includes(searchTextLower) ||
          item.lanuage.toLowerCase().includes(searchTextLower) ||
          createdDateStr.includes(searchTextLower) ||
          item.phonenumber.includes(searchText) || // phonenumber as string comparison
          item.mail.toLowerCase().includes(searchTextLower) ||
          item.artist.toLowerCase().includes(searchTextLower) ||
          item.instrument.toLowerCase().includes(searchTextLower) ||
          item.mood.toLowerCase().includes(searchTextLower) ||
          item.songfrom.toLowerCase().includes(searchTextLower) ||
          item.songto.toLowerCase().includes(searchTextLower) ||
          item.occasion.toLowerCase().includes(searchTextLower) ||
          item.story.toLowerCase().includes(searchTextLower) ||
          statusDisplay.toLowerCase().includes(searchTextLower) ||
          customerDeliveryDateStr.includes(searchTextLower) ||
          (item.comments?.toLowerCase() ?? "").includes(searchTextLower) // handle null/undefined comments
        );
      });
      setTableData(filteredData);
    }
  }, [searchText, originalData]);
  const searchForTable = (e) => {
    setSearchValue(e.target.value);
    fetchData(0, pageSize, e.target.value);
    setCurrentPage(1);
    // (e.target.value);
    // const token = localStorage.getItem("accessToken");
    // axios
    //   .get(BASE_URL + "/v1/form/genericFormSearch?query=" + e.target.value, {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //     // responseType: "arraybuffer",
    //   })
    //   .then((response) => {
    //     // setTableData(response.data);
    //     setTableData(response.data.data.formRequestDtos.forms);
    //     setTotalElements(response.data.data.formRequestDtos.totalElements);
    //     // setTableTotal(response.data.OrdersCount);
    //   });
  };
  const handleTableChange = async (pagination, filters, sorter, extra) => {
    try {
      setCurrentPage(pagination.current);
      setPageSize(pagination.pageSize);
      await fetchData(pagination.current - 1, pagination.pageSize, searchValue);
    } catch (error) {}
  };

  // Effect to clear search text when data changes
  useEffect(() => {
    setSearchText(""); // Clear search text when originalData changes
  }, [originalData]);
  useEffect(() => {
    setPagination({ current: 1, pageSize: 10 });
  }, [tableData]);

  return (
    <div>
      <Spin
        spinning={loading}
        fullscreen
        className="custom-spin"
        size="large"
        tip="Loading"
      />

      <Input
        size="small"
        className="search fs-14 fw-6 p-2 px-3 my-3"
        placeholder="Search"
        prefix={<i className="bi bi-search"></i>}
        style={{ float: "right", width: "200px" }}
        // onChange={(e) => setSearchText(e.target.value)}
        onChange={searchForTable}

        // value={searchText}
      />
      {/* <RangePicker
        size="large"
        className="rangePicker mx-3 my-3"
        onChange={handleRangeChange}
      />

      <Button
        icon={<DownloadOutlined />}
        className="rangePicker sec-clr downloadHover my-3"
        size="large"
        disabled={!datesForDisable || datesForDisable.length === 0}
      >
        Export
      </Button> */}
      <Table
        style={{ fontWeight: "600" }}
        columns={columns}
        dataSource={data}
        scroll={{ x: 3500, y: "50svh" }}
        // pagination={pagination} // Pass pagination state to the Table component
        // onChange={(pagination) => setPagination(pagination)} // Update pagination state when pagination changes
        // onChange={handleTableChange}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: totalElements, // Total number of data items
        }}
        bordered
        onChange={handleTableChange}
      />
    </div>
  );
};
