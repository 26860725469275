import React, { useState, useEffect } from "react";
import Navbar from "../Base/Navbar";
import TextArea from "antd/es/input/TextArea";
import { Button, Spin } from "antd";
import {
  MailOutlined,
  UserOutlined,
  InstagramOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URL } from "../..";
import Footer from "../Base/Footer";
import { Helmet, HelmetProvider } from "react-helmet-async";

const ContactUs = () => {
  const [input, setInput] = useState({
    name: "",
    mobileNumber: "",
    email: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = {}; // Initialize a new errors object

    if (!input.name.trim()) {
      errors.name = "Name is required";
    }
    if (!input.mobileNumber.trim()) {
      errors.mobileNumber = "Mobile Number is required";
    }
    if (!input.email.trim()) {
      errors.email = "Email is required";
    }
    if (!input.message.trim()) {
      errors.message = "Message is required";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors); // Update the state with validation errors
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("name", input.name);
    formData.append("mobileNumber", input.mobileNumber);
    formData.append("message", input.message);
    formData.append("email", input.email);

    try {
      await axios.post(BASE_URL + "/v1/form/contactus", formData).then(() => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Email sent successfully!",
          imageAlt: "Success",
        });
        setInput({
          name: "",
          mobileNumber: "",
          email: "",
          message: "",
        });
        setErrors({}); // Clear the errors after successful submission
      });
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...! Something went wrong, Retry",
        imageAlt: "Failed",
      });
      console.error("Error creating post:", error);
      // Handle error during form submission
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    sessionStorage.removeItem("bookedInstrument");
  }, []);

  const handleKeyPressName = (e) => {
    const charCode = e.charCode;
    if (!/[a-zA-Z. ]/.test(String.fromCharCode(charCode))) {
      e.preventDefault();
    }
  };
  const handleKeyPressNumber = (e) => {
    const charCode = e.charCode;
    if (!/[0-9]/.test(String.fromCharCode(charCode))) {
      e.preventDefault();
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    let errorMessage = "";
    if (e.target.name === "message") {
      if (e.target.value.length >= 750) {
        errorMessage = "Message cannot exceed 750 characters";
      }
    }
    setInput({ ...input, [name]: value });

    // Remove the error message for the field being edited
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage, // Clear the error message for this field
    }));
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>Contact Us for Your Customised Song Gifts| Tune for 2</title>
        <meta
          name="description"
          content="Get in touch with Tune for 2 for personalized custom songs. Contact us for song inquiries, and orders, We're here to help you create the perfect musical gift."
          data-rh="true"
        />
        <link rel="canonical" href="https://tunefor2.com/contact"></link>
      </Helmet>
      <>
        <section id="contactUsPage" className="bg-color">
          <Navbar contact={true} />
          <div className="contact_content px-3">
            <div className="container-fluid p-md-5">
              <h2 className="c_F77B21 fs-36 fw-6">Contact Us</h2>
              <div className="row">
                <div className="col-lg-7">
                  <form action="" className="mt-5" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-12 col-md-5 pe-md-5">
                        <div className="form-group">
                          <label htmlFor="name" className="form-label">
                            Name<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control form-input"
                            name="name"
                            placeholder="Enter Name"
                            id="name"
                            onChange={handleInput}
                            onKeyPress={handleKeyPressName}
                            value={input.name}
                          />
                          {errors.name && (
                            <div className="text-danger">{errors.name}</div>
                          )}
                        </div>
                        <div className="form-group margin-top">
                          <label htmlFor="mobileNumber" className="form-label">
                            Mobile Number<span className="text-danger">*</span>
                          </label>
                          <input
                            type="tel"
                            className="form-control form-input"
                            name="mobileNumber"
                            onChange={handleInput}
                            onKeyPress={handleKeyPressNumber}
                            value={input.mobileNumber}
                            placeholder="Enter Mobile Number"
                            id="mobileNumber"
                          />
                          {errors.mobileNumber && (
                            <div className="text-danger">
                              {errors.mobileNumber}
                            </div>
                          )}
                        </div>
                        <div className="form-group margin-top">
                          <label htmlFor="email" className="form-label">
                            Your Email<span className="text-danger">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control form-input"
                            name="email"
                            placeholder="Enter Email"
                            id="email"
                            onChange={handleInput}
                            value={input.email}
                          />
                          {errors.email && (
                            <div className="text-danger">{errors.email}</div>
                          )}
                        </div>
                      </div>

                      <div className="col col-md-7">
                        <label htmlFor="message" className="form-label">
                          Message<span className="text-danger">*</span>
                        </label>
                        <TextArea
                          name="message"
                          value={input.message}
                          onChange={handleInput}
                          placeholder="Write your thoughts"
                          autoSize={{
                            minRows: 6,
                            maxRows: 5,
                          }}
                          style={{
                            padding: "20px",
                            background: "transparent",
                            border: "2px solid #c7c7c7 ",
                          }}
                          maxLength={750}
                        />
                        {errors.message && (
                          <div className="text-danger">{errors.message}</div>
                        )}
                        <div className="submit_btn">
                          <Spin
                            spinning={loading}
                            fullscreen
                            className="custom-spin"
                            size="large"
                            tip="Loading"
                          />

                          <button
                            type="submit"
                            className="btn p-1 px-5 mt-3 mt-md-5 d-block mx-auto ms-md-0"
                            style={{
                              background: "var(--primary-orange)",
                              color: "#fff",
                            }}
                          >
                            Send Message
                          </button>
                        </div>
                      </div>
                      <div className="col-12 accent_bg rounded mt-5 mb-4">
                        <div className="links row p-2 p-lg-4 text-center align-items-baseline row-gap-4">
                          <div className="col-md-4">
                            <Button
                              type="link"
                              href="https://www.instagram.com/martinkartenjer?igsh=dHZ4Ynd0cmg4bmN2"
                              className="icon-content"
                              target="_blank"
                              icon={
                                <InstagramOutlined
                                  style={{
                                    fontSize: "24px",
                                  }}
                                />
                              }
                            >
                              martinkartenjer
                            </Button>
                          </div>

                          <div className="col-md-4">
                            <Button
                              className="icon-content"
                              type="link"
                              href="mailto:smartinkartenjer@gmail.com"
                              icon={
                                <MailOutlined
                                  style={{
                                    fontSize: "24px",
                                  }}
                                />
                              }
                            >
                              smartinkartenjer@gmail.com
                            </Button>
                          </div>
                          <div className="col-md-4">
                            <Button
                              className="icon-content"
                              type="link"
                              href="tel:+91 9363224089"
                              icon={
                                <PhoneOutlined
                                  style={{
                                    fontSize: "24px",
                                    transform: "scaleX(-1)",
                                    filter: "FlipH",
                                    msFilter: "FlipH",
                                  }}
                                />
                              }
                            >
                              {/* <i
                                className="bi bi-telephone"
                                style={{
                                  fontSize: "24px",
                                }}
                              ></i> */}
                              +91 93632 24089
                            </Button>
                          </div>
                          <div className="col-12 col-md-6 offset-md-3">
                            <a
                              href="https://maps.app.goo.gl/PC6V4kMJjwtN3U2F7"
                              target="_blank"
                              rel="noopener noreferrer"
                              id="location"
                              className="d-flex justify-content-center"
                            >
                              <EnvironmentOutlined
                                style={{
                                  fontSize: "24px",
                                  transform: "scaleX(-1)",
                                  filter: "FlipH",
                                  msFilter: "FlipH",
                                }}
                              />
                              No. 300/25A, MTH road, Kamarajapuram,
                              Ambattur,Chennai 600053
                            </a>
                            {/* </Button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-5 d-none d-lg-block p-4 text-center">
                  <img
                    src={require("../../assets/images/TuneFor2Img/personalized-singer.webp")}
                    className="img-fluid bg-img"
                    alt="personalized singer"
                    title="personalized singer"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container-fluid bg-color">
          <Footer />
        </div>
      </>
    </HelmetProvider>
  );
};

export default ContactUs;
