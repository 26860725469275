import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min";
import "./assets/css/App.css";
import "./assets/css/aboutUs.css";
import "./assets/css/contactUs.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./assets/css/mailTemplate.css";
import "./assets/css/mailTemplate2.css";
import "./assets/css/addReview.css";
import "./assets/css/homePage.css";
import "./assets/css/PolicyAndCondition.css";
import "./assets/css/trackOrder.css";
import "./assets/css/ourProducts.css";
import { HelmetProvider } from "react-helmet-async";

export const BASE_URL = "https://tunefor2.com/tune/api";
// export const BASE_URL = "https://api.cavinkare.in/tune/api";
// export const BASE_URL = "https://qa.cavinkare.in/tune/api";
// export const BASE_URL = "http://localhost:8042/tune/api";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HelmetProvider>
    <Router>
      <App />
    </Router>
  </HelmetProvider>
);
