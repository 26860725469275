import React, { useEffect, useState } from "react";
import UserDetails from "./UserDetails";
import Navbar from "../Base/Navbar";
import Tab from "../Create_song/Tab";
import UserDetailsTab from "./UserDetailsTab";
import VerifyDetails from "./VerifyDetails";
import Payment from "./Payment";
import { Helmet, HelmetProvider } from "react-helmet-async";

const CreateSongMain = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [userId, setUserID] = useState(0);
  const [amountOrderNo, setAmountOrderNo] = useState(0);
  const [amountId, setAmountID] = useState(0);
  const [firstPageDate, setFirstPageData] = useState("");
  const [secondPageData, setSecondPageData] = useState("");
  const [payKey, setPayKey] = useState("");
  const [payOrderId, setPayOrderId] = useState("");
  const [orderTotalAmount, setOrderTotalAmount] = useState("");
  const [deliveryDateAmount, setDeliveryDateAmount] = useState("");

  useEffect(() => {
    // Function to clear session storage on reload
    const clearSessionStorageOnReload = () => {
      sessionStorage.clear();
    };

    // Attach the event listener to the beforeunload event
    window.addEventListener("beforeunload", clearSessionStorageOnReload);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", clearSessionStorageOnReload);
    };
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Sing Your Story- Best customised song for Music Gifts| Tune for 2
        </title>
        <meta
          name="description"
          content="Express your deepest emotions with personalized custom songs by Tune for 2, capturing love, joy, sadness, celebrations, and all your heartfelt feelings."
          data-rh="true"
        />
        <link rel="canonical" href="https://tunefor2.com/create-a-song"></link>
      </Helmet>
      <>
        <section className="section1 bg-color DM_Sans">
          {/* Render Navbar with createSong prop */}
          <Navbar createSong={true} />

          {/* Render Tab component with activeTab and setActiveTab props */}
          <Tab activeTab={activeTab} setActiveTab={setActiveTab} />

          {/* Content for the tabs */}
          {activeTab === 0 && (
            <div className="container-fluid">
              <UserDetails
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                userId={userId}
                amountId={amountId}
                firstPageDate={firstPageDate}
                setFirstPageData={setFirstPageData}
              />
            </div>
          )}
          {activeTab === 1 && (
            <div className="container-fluid">
              <UserDetailsTab
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                setUserID={setUserID}
                userId={userId}
                amountId={amountId}
                amountOrderNo={amountOrderNo}
                setAmountID={setAmountID}
                firstPageDate={firstPageDate}
                setSecondPageData={setSecondPageData}
                secondPageData={secondPageData}
                deliveryDateAmount={deliveryDateAmount}
                setDeliveryDateAmount={setDeliveryDateAmount}
              />
            </div>
          )}
          {activeTab === 2 && (
            <div className="container-fluid">
              <VerifyDetails
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                userId={userId}
                setAmountOrderNo={setAmountOrderNo}
                setPayKey={setPayKey}
                setPayOrderId={setPayOrderId}
                setOrderTotalAmount={setOrderTotalAmount}
              />
            </div>
          )}
          {activeTab === 3 && (
            <div className="container-fluid">
              <Payment
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                userId={userId}
                amountId={amountId}
                payKey={payKey}
                payOrderId={payOrderId}
                firstPageDate={firstPageDate}
                orderTotalAmount={orderTotalAmount}
              />
            </div>
          )}
        </section>
      </>
    </HelmetProvider>
  );
};
export default CreateSongMain;
