import React, { useEffect } from "react";
import logo from "../assets/images/logo.png";
import Footer from "../pages/Base/Footer.jsx";
import Navbar from "../pages/Base/Navbar";
import { useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

export const TermsAndCondition = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigateHome = () => {
    navigate("/");
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>Tune for 2 | Terms and Conditions</title>
        <meta
          name="description"
          content="Explore the Terms and Conditions of Tune for 2, detailing policies and guidelines for our personalized song services and customized gift offerings."
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://tunefor2.com/terms-and-conditions"
        ></link>
      </Helmet>
      <>
        <section id="privacyPolicyPage" className="bg-color">
          <Navbar terms={true} />
          {/* <div className="px-3 pt-3">
              <img src={logo} alt="" />
            </div> */}
          <div className="title mb-5 img_logo">
            <h2 className="fw-7">Terms and Conditions</h2>
          </div>
          <div className="whole_padding">
            <div className="line_height fs-20 fw-4">
              These Terms and Conditions ("Terms") govern your use of the Tune
              For 2 website and services provided by Tune For 2 ("we", "us",
              "our"). By accessing or using our website and services, you agree
              to these Terms. If you do not agree with any part of these Terms,
              please do not use our website or services.
            </div>
            <div className="line_height fs-24 fw-7">1. Service Description</div>
            <div className="line_height fs-20 fw-4">
              Tune For 2 offers customised song creation services where users
              can collaborate with our team to create personalized songs based
              on their stories, memories, and emotions.
            </div>
            <div className="line_height fs-24 fw-7">
              2. User Responsibilities{" "}
            </div>
            <div className="line_height">
              <ul className="no-bullets">
                <li>
                  <div>
                    <span className="fs-20 fw-7">Accuracy of Information:</span>
                    <span className="fs-20 fw-4 mx-3">
                      You are responsible for providing accurate and complete
                      information necessary for the creation of your customised
                      song.
                    </span>
                  </div>
                </li>
                <li>
                  <div>
                    <span className="fs-20 fw-7">
                      Respect for Intellectual Property:
                    </span>
                    <span className="fs-20 fw-4 mx-3">
                      You agree not to submit any content (lyrics, melodies,
                      etc.) that infringes on the intellectual property rights
                      of others.
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            <div className="line_height fs-24 fw-7">
              3. Song Ownership and Rights
            </div>
            <div>
              <ul className="no-bullets">
                <li>
                  <div>
                    <span className="fs-20 fw-7">Ownership:</span>
                    <span className="fs-20 fw-4 mx-3">
                       Upon completion and delivery of the customised song,
                      ownership of the song's intellectual property rights,
                      including copyrights, will be transferred to you, the
                      customer.
                    </span>
                  </div>
                </li>
                <li>
                  <div className="line_height">
                    <span className="fs-20 fw-7">License to Use: </span>
                    <span className="fs-20 fw-4 mx-2">
                      Tune For 2 retains the right to use the song for
                      promotional purposes unless otherwise agreed upon in
                      writing.
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            <div className="line_height fs-24 fw-7">4. Payment</div>
            <div className="line_height">
              <ul className="no-bullets">
                <li>
                  <span className="fs-20 fw-7">Fees: </span>
                  <span className="fs-20 fw-4 mx-3">
                    Prices for our services are clearly stated on our website.
                    Payment is required in full before the commencement of work
                    on your customised song.
                  </span>
                </li>
                <li>
                  <span className="fs-20 fw-7">Refunds: </span>
                  <span className="fs-20 fw-4 mx-3">
                    Due to the personalized nature of our services, refunds are
                    generally not offered once work on your customised song has
                    begun. Refund requests may be considered on a case-by-case
                    basis.
                  </span>
                </li>
              </ul>
            </div>
            <div className="line_height fs-24 fw-7">5. Delivery </div>
            <div className="line_height">
              <ul className="no-bullets">
                <li>
                  <span className="fs-20 fw-7">Timeline:</span>
                  <span className="fs-20 fw-4 mx-3">
                    We strive to deliver the digital copy of the customised song
                    within the agreed-upon timeframe. Delays may occur due to
                    unforeseen circumstances, in which case we will communicate
                    with you promptly.
                  </span>
                </li>
                <li>
                  <span className="fs-20 fw-7">Approval: </span>
                  <span className="fs-20 fw-4 mx-3">
                    You will have the opportunity to review and provide feedback
                    on a preview of the song before final delivery. Minor
                    revisions may be accommodated.
                  </span>
                </li>
              </ul>
            </div>
            <div className="line_height fs-24 fw-7">6. Privacy Policy </div>
            <div className="line_height">
              <ul className="no-bullets">
                <li>
                  <span className="fs-20 fw-7">Personal Information:</span>
                  <span className="fs-20 fw-4 mx-3">
                    We collect and process personal information in accordance
                    with our Privacy Policy. By using our services, you consent
                    to the collection and use of your information as described
                    therein.
                  </span>
                </li>
              </ul>
            </div>
            <div className="line_height fs-24 fw-7">
              7. Limitation of Liability{" "}
            </div>
            <div className="line_height">
              <ul className="no-bullets">
                <li>
                  <span className="fs-20 fw-7">Disclaimer:</span>
                  <span className="fs-20 fw-4 mx-3">
                    Tune For 2 is not liable for any indirect, incidental,
                    special, consequential, or punitive damages arising out of
                    or in connection with our services.
                  </span>
                </li>
              </ul>
            </div>
            <div className="line_height fs-24 fw-7">8. Amendments</div>
            <div className="line_height">
              <ul className="no-bullets">
                <li>
                  <span className="fs-20 fw-7">Changes to Terms:</span>
                  <span className="fs-20 fw-4 mx-3">
                    We reserve the right to update or modify these Terms at any
                    time. Changes will be effective immediately upon posting on
                    our website. Continued use of our website or services after
                    changes constitute your acceptance of the revised Terms.
                  </span>
                </li>
              </ul>
            </div>
            <div className="line_height fs-24 fw-7">Contact Us </div>
            <div className="line_height">
              <ul className="no-bullets">
                <li>
                  <span className="fs-20 fw-7">Questions:</span>
                  <span className="fs-20 fw-4 mx-3">
                    If you have any questions or concerns about these Terms or
                    our services, please contact us at
                    smartinkartenjer@gmail.com , DM us on Instagram @TuneFor2,
                    or visit us at No. 300/25A, MTH Road, Kamarajapuram,
                    Ambattur, Chennai 600053.
                  </span>
                </li>
              </ul>
            </div>
            <div className="title pb-4 line_height_top">
              <img
                src={logo}
                alt=""
                className="cursorPointer"
                onClick={navigateHome}
              />
            </div>
          </div>
        </section>
        <div className="container-fluid bg-color">
          <Footer />
        </div>
      </>
    </HelmetProvider>
  );
};
