import React, { useRef, useState } from "react";
import { Button, Modal } from "antd";
import Navbar from "../Base/Navbar";
import { Link } from "react-router-dom";
import { Space, Spin } from "antd";
import { AudioFilled, SmileOutlined, StarFilled } from "@ant-design/icons";
import logo from "../../assets/images/logo.png";
import Footer from "../Base/Footer";
import FrequentlyAsked from "../Admin/SongStatus/Components/FrequentlyAsked";
import { useEffect } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import axios from "axios";
import { BASE_URL } from "../..";
import Carousel from "react-bootstrap/Carousel";
import video from "../../assets/video/sampleVideo.mp4";
import sample from "../../assets/video/status.mp4";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [modalShow, setModalShow] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [modelDisplay, setModelDisplay] = useState(false);
  const [duration, setDuration] = useState(0); // State for audio duration
  const [testimonialData, setTestimonialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [individualData, setIndividualData] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [imgCount, setImgCount] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [posts, setPosts] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [lightBoxVideo, setLightBoxVideo] = useState("");
  const [lightBoxVideoMobile, setLightBoxVideoMobile] = useState("");
  const audioRef = useRef(null);
  const videoRef = useRef(null);
  const textRef = useRef(null);
  const navigate = useNavigate();

  const placeholderRef = useRef(null);
  const elementRef = useRef(null);
  const [isFixed, setIsFixed] = useState(false);
  const [screenResolution, setScreenResolution] = useState({
    width: window.screen.width,
    height: window.screen.height,
  });

  const handleScroll = () => {
    if (elementRef.current && placeholderRef.current) {
      const top = placeholderRef.current.getBoundingClientRect().top;
      if (top <= 100) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    }
  };

  useEffect(() => {
    fetchLightBoxVideo();

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // fetchData();
    scrollTop();
    // openModal();
    getInstaVideo();
  }, []);

  useEffect(() => {}, [testimonialData]);

  const scrollTop = () => window.scrollTo(0, 0);

  const getInstaVideo = async () => {
    try {
      const token = "effff4a33c96df6954742568f7433a8f";
      const token1 =
        "IGQWRPb2V3WjN1Nkx5ZAG1XY2NDWi1pUDhQd2Q2NkpZAM3R2VGVoV1M2N2lkR0dPRXZAVdEI3SE9xbUdSZAzlaQnVuZAjEwMzNvNE9vdWU0am1jbkxrS0hfcmo0aVRjUXF3ZAjhJUGZA0X0t6OUUzVnh3YlBGTWtUYVBtamsZD";
      // const url = `https://graph.instagram.com/me/media?fields=id,caption,media_url,permalink,media_type,children%7Bmedia_url,thumbnail_url,permalink%7D&access_token=${token1}`;
      const url = BASE_URL + "/v1/instaIntegration/posts";
      const postsResponse = await axios.get(url);
      setPosts(postsResponse.data);
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code outside of the range of 2xx
      } else if (error.request) {
        // The request was made but no response was received
      } else {
        // Something happened in setting up the request that triggered an Error
      }
    }
  };

  // fetchInstagramData();

  // const fetchData = async () => {
  //   axios
  //     .get(BASE_URL + "/v1/instrument/get-AllInstrument-Active")
  //     .then((response) => response.data)
  //     .then((res_data) => {
  //       setTesting(res_data.data.instruments[9].audio);
  //     });
  // };

  const routefaq = () => {
    navigate("/faq");
  };

  const getReviewData = async (id) => {
    //  await axios.get(BASE_URL + "/v1/form/?position=DELIVERED")
    await axios
      .get(`${BASE_URL}/v1/form/rating-review/${id}`)
      .then((res) => {
        setLoading(false);
        return res.data.data.formRequestDto;
      })
      .then((res) => {
        setIndividualData(res);
      });
  };
  const showModal = (id) => {
    setModalShow(true);
    setLoading(true);
    getReviewData(id);
  };
  useEffect(() => {
    sessionStorage.removeItem("bookedInstrument");

    const checkOverflow = () => {
      if (textRef.current) {
        setIsOverflowing(
          textRef.current.scrollHeight > textRef.current.clientHeight
        );
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => window.removeEventListener("resize", checkOverflow);
  }, []);

  const fetchLightBoxVideo = () => {
    // const token = localStorage.getItem("accessToken");
    axios
      .get(BASE_URL + "/v1/welcomeVideo/getAllWelcomeVideos")
      .then((response) => response.data)
      .then((res_data) => {
        const videos = res_data.data.welcomeVideoResponse.welcomeVideos.map(
          (video, index) => ({
            ...video,
            key: video.id || index, // Ensure each item has a unique key
          })
        );
        setIsChecked(videos[0]?.videoFlag);
        const value = sessionStorage.getItem("popup");

        // if (value && videos[0]?.videoFlag) {
        //   // setIsChecked(true);
        //   setModelDisplay(false);
        // } else {
        //   setModelDisplay(true);
        // }

        if (videos[0]?.videoFlag && value !== "false") {
          setModelDisplay(true);
        } else {
          setModelDisplay(false);
        }

        setLightBoxVideo(
          res_data.data.welcomeVideoResponse.welcomeVideos[0].videoName
        );
        if (window.screen.width <= 425) {
          setLightBoxVideoMobile(
            res_data.data.welcomeVideoResponse.welcomeVideos[1].videoName
          );
        } else {
          setLightBoxVideoMobile(
            res_data.data.welcomeVideoResponse.welcomeVideos[0].videoName
          );
        }

        // setData(videos); // Set the data for the table
      });
  };
  const audio = new Audio();
  const toggleText = () => {
    setExpanded(!expanded);
  };
  //=================== Audio Play/Pause Function
  const togglePlayPause = () => {
    if (!audioRef.current) {
      audioRef.current = new Audio();
    }

    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.src =
        BASE_URL + "/v1/instrument/song/" + individualData.adminClipPath;
      audioRef.current.play();
    }

    setIsPlaying(!isPlaying);
  };
  const formatDuration = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  // const openModal = () => {
  //   const value = sessionStorage.getItem("popup");

  //   if (value) {
  //     // setIsChecked(true);
  //     // setModelDisplay(false);
  //   } else {
  //     // setModelDisplay(true);
  //   }
  // };
  const closeModal = () => {
    sessionStorage.setItem("popup", "false");
    setModelDisplay(false);
    // setIsChecked(false);
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0; // Optionally reset the playback position
    }
  };
  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Tune for 2: Personalized Custom Songs | Best Customised Gift
        </title>
        <meta
          name="description"
          content="We turn your ideas into music as a customised gift. Personalized songs crafted for all special events. Share your story, we'll create best customized songs."
          data-rh="true"
        />
        <link rel="canonical" href="https://tunefor2.com/"></link>
      </Helmet>
      <div className="bg-color" id="home">
        <Navbar home={true} />
        <section className="home-content container-fluid px-md-5 overflow-x-hidden">
          <Modal
            className="custom-modal"
            centered
            open={modelDisplay}
            closable={false}
            footer={false}
          >
            <div>
              <div className="d-flex justify-content-end position-absolute w-100 cursor">
                <div className="fs-20 fw-5">
                  <i
                    className="bi bi-x fs-24 fw-5 px-2 cursor text-danger"
                    onClick={closeModal}
                  ></i>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <video
                  width="100%"
                  controls
                  preload="auto"
                  ref={videoRef}
                  disablePictureInPicture
                  src={`${BASE_URL}/v1/welcomeVideo/video/${lightBoxVideoMobile}`}
                  controlsList="nodownload"
                >
                  Your browser does not support the video element.
                </video>
              </div>
            </div>
          </Modal>

          <Spin
            spinning={loading}
            fullscreen
            className="custom-spin"
            size="large"
            tip="Loading"
          />

          {/* ====================================== Hero Section =================================== */}
          <div className="hero px-md-4">
            <div className="row">
              <div className="col-6">
                <img
                  src={require("../../assets/images/hero_decor_bg.png")}
                  className="img-fluid multiply"
                  alt="heart image"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 pt-md-5">
                <p className="DM_Sans fw-5 fs-18">
                  Your Story, Your Song, Forever in Harmony
                </p>
                <h1 className="display-3 f_sacramento">
                  Make your moments unforgettable with a customized song today
                  that will last for a life time
                </h1>
                <p className="text-muted">
                  Welcome to <span className="c_0A0D13 fw-6">Tune For 2</span>,
                  where your stories become the soundtrack of your life. Imagine
                  capturing the essence of your most cherished memories,
                  heartfelt celebrations, and special occasions in a song that's
                  uniquely yours.
                  {/* Ever wished you could express yourself with more than just
                words? At <span className="c_0A0D13 fw-6">Tune For 2</span> , we
                turn your emotions, memories, and stories into beautiful,
                custom-made songs. */}
                </p>
                <div className="d-flex text-center" ref={placeholderRef}>
                  <Link
                    to={"/create-a-song"}
                    className={`text-decoration-none py-3 btn bg_FFB775 mt-3 fw-5 fs-18 DM_Sans mx-auto ms-md-1 ${
                      isFixed ? "fixed" : ""
                    }`}
                    ref={elementRef}
                  >
                    Create Your Song
                  </Link>
                  {/* <a
                    href="https://tunefor2.com"
                    className={`text-decoration-none py-3 btn bg_FFB775 mt-3 fw-5 fs-18 DM_Sans mx-auto ms-md-1 ${
                      isFixed ? "fixed" : ""
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                    ref={elementRef}
                  >
                    Create Your Song
                  </a> */}
                </div>
                <div className="infos_wrapper mt-md-5 pt-5">
                  <div className="d-flex flex-column flex-sm-row gap-4">
                    <Space className="info_card" size={"middle"}>
                      <div className="info_icon">
                        <SmileOutlined />
                      </div>
                      <div className="info_content">
                        <p>100+</p>
                        <p>Customers</p>
                      </div>
                    </Space>
                    <Space className="info_card" size={"middle"}>
                      <div className="info_icon">
                        <StarFilled />
                      </div>
                      <div className="info_content">
                        <p>10+</p>
                        <p>Genre</p>
                      </div>
                    </Space>
                    <Space size={"middle"} className="info_card">
                      <div className="info_icon">
                        <AudioFilled />
                      </div>
                      <div className="info_content">
                        <p>8+</p> <p>years of Industry Experience</p>
                      </div>
                    </Space>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <img
                  src={require("../../assets/images/TuneFor2Img/personalised-love-song.webp")}
                  className="img-fluid multiply"
                  alt="personalised love song"
                  title="personalised love song"
                />
              </div>
            </div>
          </div>
          {/* ====================================== Hero section end =============================== */}

          {/* ====================================== Whisper of Melody Section ====================== */}
          {isChecked && (
            <div className="whisper_of_melody px-md-4 row pe-md-0 mb-5 pt-4 pt-md-0">
              <div className="col-11 mx-auto pt-md-5">
                <h2 className="DM_Sans display-5 fw-bold text-center pt-md-5">
                  Hear A Whisper Of Melody: Your
                  <span className="c_FFB775"> Sample The Magic </span>
                </h2>
                <div className="col-md-6 offset-md-3 my-4">
                  <p className="text-muted text-center">
                    Eager to experience the power of a custom song? Take a peek
                    behind the curtain and listen to a snippet of a song we
                    crafted for a special someone. It's just a taste of what we
                    can create for you!
                  </p>
                </div>
              </div>
              <div className="row pe-md-0 mx-auto">
                <div className="col-lg-4 offset-lg-4 text-center">
                  <div className="d-flex justify-content-center">
                    <video
                      width="100%"
                      controls
                      disablePictureInPicture
                      controlsList="nodownload"
                    >
                      <source
                        src={`${BASE_URL}/v1/welcomeVideo/video/${lightBoxVideo}`}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
                <div className="col-lg-4 offset-0 text-end pe-md-0 position-relative d-none d-lg-block">
                  <img
                    src={require("../../assets/images/shape2.png")}
                    alt=""
                    className="img-fluid multiply w-75"
                  />
                </div>
              </div>
            </div>
          )}

          {/* ====================================== Whisper of Melody Section End ================== */}
          {/* ====================================== Unleash The Melody Section 1  ================== */}

          <div className="song_creation_journy px-md-4 row mb-5 pt-5">
            <div className="col-11 mx-auto">
              <h2 className="DM_Sans display-5 fw-bold text-center">
                Sing Your Story : Unique
                <span className="c_FFB775"> Customized Gifts </span>
              </h2>
              <div className="col-md-6 offset-md-3 my-4">
                <p className="text-muted text-center">
                  A customized song is the ultimate gift - Make every occasion
                  unforgettable with a custom songs. At Tune for 2, we craft
                  personalized melodies for weddings, anniversaries, birthdays,
                  and more, turning your special moments into timeless gifts.
                  {/* Ever wished you could express yourself with more than just
                words? At{" "}
                <span className="text-dark fw-semibold">Tune For 2</span> , we
                turn your emotions, memories, and stories into beautiful,
                custom-made songs. */}
                </p>
              </div>
            </div>
            <div className="row pe-md-0 my-5 py-lg-5 mx-auto">
              <div className="col-lg-6 offset-lg-3 text-center">
                <img
                  src={require("../../assets/images/TuneFor2Img/heart-family.webp")}
                  alt="heart family"
                  className="img-fluid heart_family p-5"
                  title="heart family"
                />
              </div>
              <div className="col-lg-3 position-relative relative-img d-none d-lg-block">
                <img
                  src={require("../../assets/images/shape2.png")}
                  alt=""
                  className="img-fluid multiply w-100"
                  style={{ right: "-4rem" }}
                />
              </div>
            </div>
          </div>
          {/* ====================================== Unleash The Melody Section 1 End  ================== */}
          <div className="song_creation_journy px-md-4 row">
            <div className="col-11 mx-auto">
              <h2 className="DM_Sans display-5 fw-bold text-center">
                Find Your Melody : Your
                <span className="c_FFB775"> Song Creation </span>
                Journey
              </h2>
              <div className="col-md-6 offset-md-3 my-4">
                <p className="text-muted text-center">
                  At <span className="text-dark fw-semibold">Tune For 2</span>,
                  we transform your emotions, memories, and stories into
                  beautiful, custom-made songs.
                </p>
              </div>
            </div>
            <div className="row pe-md-0 my-5 pb-5 pt-3">
              <div className="col-lg-5 offset-md-1 ps-5 text-end d-flex align-items-center">
                <img
                  src={require("../../assets/images/TuneFor2Img/custom-family-song.webp")}
                  alt="custom family song"
                  className="img-fluid multiply"
                  title="custom family song"
                />
              </div>
              <div className="col-lg-6 steps">
                <div className="row mt-5 mt-lg-0 ">
                  <div className="col-4 col-md-2 text-muted fw-5">Step 1</div>
                  <div className="col-lg-7 col-8">
                    <h5>Tell us your Story</h5>
                    {/* <p className="text-muted fs-14 fw-5">
                      Share details about the recipient, occasion, and desired
                      message.
                    </p> */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 col-md-2 text-muted fw-5">Step 2</div>
                  <div className="col-lg-7 col-8">
                    <h5>Select Your Song Version</h5>
                    {/* <p className="text-muted fs-14 fw-5">
                      Tamil, English,Hindi, Malayalam,Telugu - we have styles to
                      suit every taste.
                    </p> */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 col-md-2 text-muted fw-5">Step 3</div>
                  <div className="col-lg-7 col-8">
                    <h5>Make Payment</h5>
                    {/* <p className="text-muted fs-14 fw-5">Make Payment</p> */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 col-md-2 text-muted fw-5">Step 4</div>
                  <div className="col-lg-7 col-8">
                    <h5>Receive Your Song</h5>
                    {/* <p className="text-muted fs-14 fw-5">
                      Get a downloadable, high-quality recording to share and
                      cherish.
                    </p> */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 col-md-2 text-muted fw-5">Step 5</div>
                  <div className="col-lg-7 col-8">
                    <h5>Surprise Your Loved One</h5>
                    {/* <p className="text-muted fs-14 fw-5">
                      Get a downloadable, high-quality recording to share and
                      cherish.
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ====================================== Unleash The Melody Section 2 End================== */}
          {/* ====================================== Frequently asked questions Section ================== */}
          <div className="song_creation_journy px-md-4 row">
            <div className="col-11 mx-auto">
              <h2 className="DM_Sans display-5 fw-bold text-center">
                Frequently Asked
                <span className="c_FFB775"> Questions </span>
              </h2>
              <div className="col-md-6 offset-md-3 my-4">
                <p className="text-muted text-center">
                  Ever wished you could express yourself with more than just
                  words? At{" "}
                  <span className="text-dark fw-semibold">Tune For 2</span>, we
                  turn your emotions, memories, and stories into beautiful,
                  custom-made songs.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 pt-md-5">
                {/*====================== Accordian ===============*/}
                <FrequentlyAsked />
                <div className="fs-20 fw-5 text-center">
                  <span className="cursor see-more" onClick={routefaq}>
                    See More
                  </span>
                </div>
              </div>
              <div className="col-lg-6">
                <img
                  src={require("../../assets/images/TuneFor2Img/guitarist.webp")}
                  className="img-fluid multiply"
                  alt="guitarist"
                  title="guitarist"
                />
              </div>
            </div>
          </div>
          {/* ====================================== Frequently asked questions Section End ================== */}
        </section>
        {/* ======================================== Testimonial ============================== */}
        {/* <div className="container-fluid px-1 testimonial_section overflow-x-hidden">
          <div className="row testimonial-wrapper h-100 w-100">
            <div className="col-11 mx-auto carousel-wrapper my-auto">
              <div className="container-fluid">
                <div className="row mt-5">
                  <div className="col-md-11 mx-auto mb-5">
                    <h2 className="DM_Sans display-5 fw-bold text-center">
                      Spectrum Of
                      <span className="c_FFB775"> Emotions</span>
                    </h2>
                  </div>
                  <div
                    className="col-md-10 zindex p-0 offset-md-1 post-items masonryWrapper"
                    style={{
                      maxHeight: "70vh",
                      overflowY: "scroll",
                    }}
                  >
                    <ResponsiveMasonry
                      columnsCountBreakPoints={{ 350: 2, 750: 2, 900: 4 }}
                    >
                      <Masonry className="post-items-wrapper">
                        {posts.map((post, index) => (
                          <div
                            key={post.id}
                            className={index % 2 === 1 ? "even" : "odd"}
                          >
                            {post.media_type === "VIDEO" ? (
                              <video
                                src={post.media_url}
                                controls
                                className="img-fluid post-item"
                                disablePictureInPicture
                              ></video>
                            ) : (
                              <img
                                src={post.media_url}
                                alt={post.caption || "Instagram media"}
                                className="img-fluid post-item"
                              />
                            )}
                          </div>
                        ))}
                        <div>
                          <a
                            href="https://www.instagram.com/tunefor2_/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View our profile
                          </a>
                        </div>
                      </Masonry>
                    </ResponsiveMasonry>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* =============================================================================  */}
        <div className="container-fluid">
          <div className="row mt-md-5 pt-md-5 align-items-center flex-row">
            <div className="d-none d-md-block col-md-4 offset-md-1">
              <img
                src={require("../../assets/images/TuneFor2Img/custom-music-box.webp")}
                alt="custom music box"
                className="img-fluid multiply"
                title="custom music box"
              />
            </div>
            <div className="col-md-6 text-center text-md-start offset-md-1">
              <h2 className="display-6 fw-bold DM_Sans">
                Give the gift that gets hearts soaring
              </h2>
              <p className="text-muted w-75">
                Give the gift that truly keeps on giving a{" "}
                <span className="c_FFB775">one-of-a-kind song </span>
                uniquely crafted to celebrate someone you love.
              </p>
              <div className="d-flex justify-content-center justify-content-md-start">
                <Link
                  to={"/create-a-song"}
                  className="text-decoration-none py-3 btn bg_FFB775 mt-3 DM_Sans fw-5 mx-auto ms-md-1"
                >
                  Create Your Song
                </Link>
              </div>
            </div>
            <div className="col-12 text-center">
              <a onClick={scrollTop} className="navbar-brand nav-link mt-5">
                <img
                  src={require("../../assets/images/TuneFor2Img/tune-for-2-logo.webp")}
                  alt="tune for 2 logo"
                  className="img-fluid mb-2 cursor "
                />
              </a>
            </div>
            <Footer />
          </div>
        </div>
        {/* =============================================================================  */}
      </div>
    </HelmetProvider>
  );
};
export default Home;
