import React, { useEffect, useState, useRef } from "react";
import Navbar from "../Base/Navbar";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../..";
import Footer from "../Base/Footer";
import { Link } from "react-router-dom";

export const OurProductsMob = () => {
  const navigate = useNavigate();
  const [giftCardArr, setGiftCArdArr] = useState([]);
  const audioRefs = useRef([]);
  const [isPlaying, setIsPlaying] = useState({});
  const [currentTimes, setCurrentTimes] = useState({});
  const [durations, setDurations] = useState({});
  const [currentIndex, setCurrentIndex] = useState(null);
  const [playingIndex, setPlayingIndex] = useState(null);
  const [activeItems, setActiveItems] = useState({});
  useEffect(() => {
    getInstrumentData();
    sessionStorage.removeItem("bookedInstrument");
  }, []);

  const bookInstrument = (item, index) => {
    sessionStorage.setItem("bookedInstrument", item.name);
    if (playingIndex) togglePlayPause(playingIndex);

    navigate("/create-a-song");
  };

  const lineRowHandle = (index) => {
    console.log(index, "Indexxxx");
    console.log(activeItems, "Active Items");

    setActiveItems((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  const getInstrumentData = () => {
    axios
      .get(BASE_URL + "/v1/instrument/get-AllInstrument-Active")
      .then((response) => response.data)
      .then((res_data) => {
        setGiftCArdArr(res_data.data.instruments);
        audioRefs.current = res_data.data.instruments.map(() => new Audio());

        res_data.data.instruments.forEach((item, index) => {
          const audio = audioRefs.current[index];
          if (audio) {
            audio.src = BASE_URL + "/v1/form/song/" + item.audio;
            audio.preload = "metadata";

            audio.addEventListener("loadedmetadata", () => {
              setDurations((prevDurations) => ({
                ...prevDurations,
                [index]: formatDuration(audio.duration),
              }));
            });

            audio.addEventListener("timeupdate", () => {
              setCurrentTimes((prevTimes) => ({
                ...prevTimes,
                [index]: audio.currentTime,
              }));
            });
          }
        });

        return () => {
          audioRefs.current.forEach((audio, index) => {
            audio.pause();
            audio.removeEventListener("loadedmetadata", () => {});
            audio.removeEventListener("timeupdate", () => {});
          });
        };
      });
  };

  const togglePlayPause = (index) => {
    const audio = audioRefs.current[index];
    setPlayingIndex(index);

    if (audio.paused) {
      if (currentIndex !== null && currentIndex !== index) {
        audioRefs.current[currentIndex].pause();
        setIsPlaying((prevPlaying) => ({
          ...prevPlaying,
          [currentIndex]: false,
        }));
      }

      audio.play();
      setIsPlaying((prevPlaying) => ({
        ...prevPlaying,
        [index]: true,
      }));
      setCurrentIndex(index);
    } else {
      audio.pause();
      setIsPlaying((prevPlaying) => ({
        ...prevPlaying,
        [index]: false,
      }));
    }
  };

  const formatDuration = (time) => {
    if (!isFinite(time) || isNaN(time)) {
      return "0:00";
    }
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>Our Gifts : Unique Customized Musical Gifts | Tune for 2</title>
        <meta
          name="description"
          content="Explore unique, personalized musical gifts for every occasion. Our custom songs capture your story, creating unforgettable memories for your loved ones."
          data-rh="true"
        />
        <link rel="canonical" href="https://tunefor2.com/our-gifts" />
      </Helmet>
      <>
        <section id="contactUsPage" className="bg-color">
          <Navbar ourProducts={true} sample={"sample"} />
          <div className="container-fluid bg-color" id="aboutUs">
            <div className="container-fluid px-3 px-md-5">
              <div className="row">
                <div className="text-center col-md-10 col-lg-9 mx-auto py-5 text-dark">
                  <h1 className="fw-7 display-4 my-4 mt-2 title_style">
                    Our Gifts
                  </h1>
                  <p className="fw-semibold fs-18 c_9F9F9F">
                    Welcome to Tune for 2, where music meets emotions and
                    memories transform into melodies. At Tune For 2, we
                    specialize in creating personalized, custom-made songs that
                    celebrate life's special moments and heartfelt emotions.
                  </p>
                </div>
              </div>
            </div>
            {/* ============== Gift Cards ================ */}
            <div className="container-fluid px-md-5 d-flex flex-column row-gap-5">
              {/* ==================== card-1 */}
              {giftCardArr.map((item, index) => (
                <div
                  className="row p-2 px-md-5 py-4 bg-white position-relative gift-product rounded-4"
                  style={{
                    "--bg-image": `url('${BASE_URL}/v1/instrument/instrumentimage/${item.image}')`,
                  }}
                  key={index}
                >
                  {item.bestSeller && (
                    <div className="position-absolute p-2 ps-md-4 gift-i">
                      <div className="text-light fw-semibold px-4 rounded text-center">
                        {item.instrumentCategory}
                      </div>
                    </div>
                  )}

                  {/* <div className="position-absolute p-2 ins-tag rounded-5 m-2 m-lg-5">
                    <div className="text-light px-2 rounded text-center flex">
                      <i className="bi bi-music-note"></i>
                      {item.image ? item.image.split(".")[0] : ""}
                    </div>
                  </div> */}
                  <div className="col-lg-6 gift-content fs-5 pt-5 mt-5 order-3 order-md-2">
                    <div className="mb-0 d-flex flex-wrap px-2">
                      <div className="display-1">{item.name}</div>
                    </div>
                    <div className="mb-2 d-flex flex-wrap flex-lg-nowrap px-2 description">
                      <div
                        className={`clr-gray-100 description_p ${
                          activeItems[index] ? "active" : ""
                        }`}
                        onClick={() => lineRowHandle(index)}
                        title="Read more"
                      >
                        {item.description}
                      </div>
                    </div>
                    {/* <div className="mb-4 d-flex flex-wrap flex-lg-nowrap px-2 clr-gray-100">
                      <span className="gift-lable">Price </span>{" "}
                      <div>₹{item.price}</div>
                    </div> */}
                    <div className="d-flex flex-column flex-lg-row mb-3 flex-wrap p-md-2 gap-4">
                      <button
                        className="btn text-white amount-btn p-3 py-2 order-2 order-lg-1 mx-2"
                        onClick={() => bookInstrument(item, index)}
                      >
                        Book Now
                      </button>
                      <div
                        className="d-flex align-items-center sample-audio-div px-3 mt-2 mt-md-0 order-1 order-lg-2 mx-auto mx-sm-0"
                        style={{
                          width: "max-content",
                          boxShadow: "0px 0px 3px #444",
                        }}
                      >
                        <p className="sample_audio">Sample Audio</p>
                        <button
                          type="button"
                          onClick={() => togglePlayPause(index)}
                          className="sample-play-btn"
                        >
                          {isPlaying[index] ? (
                            <i className="bi bi-pause-fill pause-icon"></i>
                          ) : (
                            <i className="bi bi-play-fill play-icon"></i>
                          )}
                        </button>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          className="mono-space"
                        >
                          <input
                            type="range"
                            min={0}
                            max={Math.round(
                              audioRefs.current[index]?.duration || 0
                            )}
                            value={Math.round(currentTimes[index] || 0)}
                            onChange={(e) => {
                              const newTime = e.target.value;
                              audioRefs.current[index].currentTime = newTime;
                              setCurrentTimes((prevTimes) => ({
                                ...prevTimes,
                                [index]: newTime,
                              }));
                            }}
                            className="flex-grow-1"
                          />
                          <span className="audio-duration ms-1 text-light">
                            {formatDuration(currentTimes[index] || 0)} /{" "}
                            {durations[index] || "0:00"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-12 text-center">
              <Link to={"/"} className="navbar-brand nav-link mt-5">
                <img
                  src={require("../../assets/images/TuneFor2Img/tune-for-2-logo.webp")}
                  alt="tune for 2 logo"
                  className="img-fluid mb-2 cursor"
                />
              </Link>
            </div>
            <Footer />
          </div>
        </section>
      </>
    </HelmetProvider>
  );
};

export default OurProductsMob;
