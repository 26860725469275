import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Drawer,
  Input,
  message,
  Popconfirm,
  Switch,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import "./login.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../..";
import Swal from "sweetalert2";

export const NewPassword = () => {
  const [input, setInput] = useState({ password: "", confirmPassword: "" });
  const [email, setEmail] = useState("");
  const { login } = useAuth();
  const navigate = useNavigate();
  const params = useParams();

  const id = params.id;

  useEffect(() => {
    fetchEmail();
  }, []);
  const goLogin = () => {
    navigate("/login");
  };
  const fetchEmail = () => {
    axios
      .get(BASE_URL + "/v1/users/reset-password?token=" + id)
      .then((response) => response.data)
      .then((res_data) => {
        setEmail(res_data.email);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          Swal.fire({
            // title: "Order placed successfuly!!!",
            allowOutsideClick: false,
            allowEscapeKey: false,
            text: error.response.data,
            icon: "warning",
            // imageAlt: "Order palced Success",
            willClose: () => {
              navigate("/forgotPassword");
            },
          });
        }
      });
  };
  const [errors, setErrors] = useState({});
  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {};
    if (input.password) {
      if (input.password.length < 8) {
        errors.password = "Must have minimum 8 characters";
      }
    } else if (!input.password) {
      errors.password = "password is required";
    }

    //Validate songMood field
    if (input.confirmPassword) {
      if (input.password != input.confirmPassword) {
        errors.confirmPassword = "Passwords do not match";
      }
    } else if (!input.confirmPassword) {
      errors.confirmPassword = "confirmPassword is required";
    }

    // If there are errors, set them to state and prevent form submission
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    // login();
    const formData = new FormData();
    formData.append("email", email);
    formData.append("newPassword", input.password);
    formData.append("resetToken", id);

    try {
      await axios.post(`${BASE_URL}/v1/users/reset-password`, formData);
      localStorage.removeItem("accessToken");
      Swal.fire({
        title: "Password Reset Successfully!!!",
        allowOutsideClick: false,
        allowEscapeKey: false,
        // text: "We have sent the reset password link to your registered email. Kindly use that link to update your new password.",
        icon: "success",
        // imageAlt: "Order palced Success",
        willClose: () => {
          navigate("/login");
        },
      });
      // navigate("/login");
    } catch (error) {
      console.error("Error creating post:", error);
    }

    setErrors({});
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });

    // Clear the error message for this field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear the error message for this field
    }));
  };

  return (
    <div className="login_background">
      <div className="newPassword-bg">
        <div className="container">
          <div className="row">
            <div className="col-6 img-fluid">
              <img
                src={require("../../../assets/images/image_143.png")}
                className="side_image"
                alt=""
              />
            </div>
            <div className="col-6">
              <div className="login-entry flex-center">
                <div className="login_outer">
                  <div className="password_content flex-center">
                    <img
                      src={require("../../../assets/images/logo.png")}
                      alt=""
                      className=""
                    />

                    <div className="login_top flex-center">
                      Set New Password
                    </div>
                  </div>
                  <div className="newPassword_content1  flex-center">
                    You can set a new password here
                  </div>
                  <div className="newPassword_content2 flex-center">
                    {email}
                  </div>

                  <div className="">
                    <form onSubmit={handleSubmit}>
                      <div>
                        <label
                          htmlFor="password"
                          className="side-nav-label fw-5 fs-16 c_454F5B"
                        >
                          Password
                        </label>
                        <span className="text-danger">*</span>
                        <br />
                        <Input.Password
                          type="text"
                          className="rounded input_field"
                          name="password"
                          placeholder="Must have minimum 8 characters"
                          id="password"
                          onChange={handleInput}
                          value={input.password}
                        />
                        {errors.password && (
                          <div className="text-danger">{errors.password}</div>
                        )}
                      </div>
                      {/* {!errors.password && (
                        <div className="newPassword_rules">
                          Must have minimum 8 characters
                        </div>
                      )} */}

                      <div>
                        <label
                          htmlFor="confirmPassword"
                          className="side-nav-label mt-4 fw-5 fs-16 c_454F5B"
                        >
                          Confirm Password
                        </label>
                        <span className="text-danger">*</span>
                        <br />
                        <Input.Password
                          type="text"
                          className="rounded input_field"
                          name="confirmPassword"
                          placeholder="Enter Confirm Password"
                          id="confirmPassword"
                          onChange={handleInput}
                          value={input.confirmPassword}
                        />
                        {errors.confirmPassword && (
                          <div className="text-danger">
                            {errors.confirmPassword}
                          </div>
                        )}
                      </div>
                      {/* <div className="forgot_pw">Forgot password?</div> */}
                      <div className="">
                        <Button
                          size="large"
                          className="login_btn mt-4 mb-4"
                          htmlType="submit"
                        >
                          Reset Password
                        </Button>
                      </div>
                      <div className=" mt-4 back_login" onClick={goLogin}>
                        <i className="bi bi-arrow-left cursor"></i>
                        <span className=" cursor">
                          &nbsp;&nbsp; Back to login
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
