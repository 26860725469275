import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Drawer,
  Input,
  message,
  Popconfirm,
  Switch,
} from "antd";
import axios from "axios";
import { BASE_URL } from "../../..";
import { Masterdata } from "./MasterData";

export const Languages = () => {
  const [languageData, setLanguageData] = useState(Masterdata.language);
  const [editedId, setEditedId] = useState("");
  const [editLanguageData, setEditLanguageData] = useState({
    name: "",
    price: "",
  });
  const [editID, setEditID] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState("Add New Language");
  const [drawerButton, setDrawerButton] = useState("Add Language");
  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [statusActive, setStatusActive] = useState("");
  const [input, setInput] = useState({ name: "", price: "" });
  const [open, setOpen] = useState(false);
  const [newopen, setNewOpen] = useState(false);
  const [editopen, setEditopen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const fetchData = () => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(`${BASE_URL}/v1/language`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // responseType: "arraybuffer",
      })
      .then((response) => response.data)
      .then((res_data) => {
        setTableData(res_data.data.languages);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onClose = () => {
    setNewOpen(false);
    setEditopen(false);
    setErrors({});
  };
  const [errors, setErrors] = useState({});
  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {};
    if (!input.name) {
      errors.name = "Name is required";
    }

    //Validate songMood field
    if (!input.price) {
      errors.price = "Price is required";
    }

    // If there are errors, set them to state and prevent form submission
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    const formData = new FormData();
    formData.append("name", input.name);
    formData.append("price", input.price);
    try {
      const token = localStorage.getItem("accessToken");
      await axios.post(`${BASE_URL}/v1/language`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // responseType: "arraybuffer",
      });
      message.success("Added Successfully");
      fetchData();
    } catch (error) {
      console.error("Error creating post:", error);
    }
    setNewOpen(false);
    setErrors({});
    setInput({ name: "", price: "" });
  };

  const confirm = (e) => {
    message.success("Deleted Successfully");
    const token = localStorage.getItem("accessToken");
    axios
      .delete(`${BASE_URL}/v1/language/` + editedId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // params: data.params,
        // responseType: "arraybuffer",
      })
      .then(() => {
        fetchData();
      });
  };

  const handleActive = (id) => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(BASE_URL + "/v1/language/" + id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // params: data.params,
        // responseType: "arraybuffer",
      })
      .then((response) => response.data)
      .then((res_data) => {
        setStatusActive(res_data.data.language.status);

        if (res_data.data.language.status === "ACTIVE") {
          let data = { params: { status: "INACTIVE" } };
          axios
            .put(BASE_URL + "/v1/language/action-control/" + id, null, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: data.params,
              // responseType: "arraybuffer",
            })
            .then(() => {
              fetchData();
            });
        } else {
          let data = { params: { status: "ACTIVE" } };
          axios
            .put(BASE_URL + "/v1/language/action-control/" + id, null, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: data.params,
              // responseType: "arraybuffer",
            })
            .then(() => {
              fetchData();
            });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    const errors = {};
    if (!editLanguageData.name) {
      errors.name = "Name is required";
    }

    //Validate songMood field
    if (!editLanguageData.price) {
      errors.price = "Price is required";
    }

    // If there are errors, set them to state and prevent form submission
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    let data = { name: editLanguageData.name, price: editLanguageData.price };
    const token = localStorage.getItem("accessToken");
    axios
      .put(BASE_URL + "/v1/language/" + editID, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // params: data.params,
        // responseType: "arraybuffer",
      })
      .then(() => {
        message.success("Updated Successfully");
        fetchData();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        message.error(error.response.data.message);
      });
    setNewOpen(false);
    setErrors({});
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });

    // Clear the error message for this field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear the error message for this field
    }));
  };

  const addLanguage = () => {
    setDrawerTitle("Add New Language");
    setDrawerButton("Add Language");
    setEditID("");
    setInput({ name: "", price: "" });
    setNewOpen(true);
  };

  const handleEdit = (id) => {
    setDrawerButton("Submit Language");
    setEditID(id);
    setNewOpen(true);
    const token = localStorage.getItem("accessToken");
    axios
      .get(BASE_URL + "/v1/language/" + id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // params: data.params,
        // responseType: "arraybuffer",
      })
      .then((response) => response.data)
      .then((res_data) => {
        setEditLanguageData(res_data.data.language || { name: "", price: "" });
      });
  };

  const handleEditInput = (e) => {
    const { name, value } = e.target;
    setEditLanguageData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear the error message for this field
    }));
  };

  const handleDelete = (id) => {
    setEditedId(id);
  };

  const closeDrawer = () => {
    setOpen(false);
  };
  const handleKeyPressNumber = (e) => {
    const charCode = e.charCode;
    if (!/[0-9]/.test(String.fromCharCode(charCode))) {
      e.preventDefault();
    }
  };
  const handleKeyPressName = (e) => {
    const charCode = e.charCode;
    if (!/[a-zA-Z. ]/.test(String.fromCharCode(charCode))) {
      e.preventDefault();
    }
  };

  const handleEnableChange = (checked, record) => {
    const updatedData = languageData.map((item) =>
      item.sno === record.sno
        ? { ...item, enable: checked ? "checked" : true }
        : item
    );
    setLanguageData(updatedData);
    Masterdata.language = updatedData;
  };

  const handleRowSelectionChange = (selectedRowKeys, selectedRows) => {
    setSelectedRows(selectedRows);
  };

  const deleteSelectedRows = () => {
    const idsToDelete = selectedRows.map((row) => row.id);
    const token = localStorage.getItem("accessToken");
    axios
      .delete(BASE_URL + "/v1/language/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: idsToDelete,
      })
      .then(() => {
        fetchData();
      })
      .catch((error) => {
        console.error("Error deleting languages:", error);
      });

    setSelectedRows([]);
  };

  const handleEnableSeletedChange = () => {
    const idsToDelete = selectedRows.map((row) => row.id);
    const token = localStorage.getItem("accessToken");
    axios
      .put(
        BASE_URL + "/v1/language/action-controls?status=ACTIVE",
        idsToDelete,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          // params: data.params,
          // responseType: "arraybuffer",
        }
      )
      .then(() => {
        fetchData();
      })
      .catch((error) => {
        console.error("Error deleting languages:", error);
      });

    setSelectedRows([]);
  };

  const hasSelected = selectedRows.length > 1;

  const columns = [
    { title: "SI.No", dataIndex: "sno", width: 150, align: "center" },
    { title: "Language Name", dataIndex: "name", width: 250 },
    { title: "Price", dataIndex: "price", width: 750 },
    {
      title: "Action",
      align: "center",
      dataIndex: "actions",
      fixed: "right",
      width: 200,
    },
  ];

  const data = tableData
    .filter((item) =>
      item.name.toLowerCase().includes(searchText.toLowerCase())
    )
    .map((item, index) => ({
      ...item,
      key: index.toString(),
      sno: index + 1,
      actions: (
        <div>
          {!hasSelected && (
            <div>
              <Button
                type="link"
                onClick={() => handleEdit(item.id)}
                className="edit-hover"
              >
                <i className="bi bi-pencil-fill sec-clr edit-icon-hover"></i>
              </Button>
              <Switch
                checked={item.status === "ACTIVE"}
                onChange={() => handleActive(item.id)}
                className="pri-clr"
                size="small"
              />
              <Popconfirm
                title="Are you sure to delete?"
                onConfirm={confirm}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="link"
                  className="edit-hover"
                  onClick={() => handleDelete(item.id)}
                >
                  <i className="bi bi-trash3-fill sec-clr delete-icon-hover"></i>
                </Button>
              </Popconfirm>
            </div>
          )}
        </div>
      ),
    }));

  return (
    <>
      <div className="d-flex justify-content-end">
        <Input
          size="small"
          className="search fs-14 fw-6"
          placeholder="Search"
          prefix={<i className="bi bi-search"></i>}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <Button
          type="primary"
          size="large"
          className="button-bg"
          onClick={addLanguage}
        >
          <i className="bi bi-plus"></i>
          New Language
        </Button>
      </div>
      <div className="">
        {hasSelected && (
          <div className="d-flex whole-delete">
            <div className="fw-6 fs-16">{selectedRows.length} Selected</div>
            <div>
              <Switch
                onChange={handleEnableSeletedChange}
                className="pri-clr whole-enable"
                size="small"
              />
              <button onClick={deleteSelectedRows} className="whole-delete-btn">
                <i className="bi bi-trash3-fill pri-clr"></i>
              </button>
            </div>
          </div>
        )}
      </div>
      <Table
        rowSelection={{
          type: "checkbox",
          onChange: handleRowSelectionChange,
          selectedRowKeys: selectedRows.map((row) => row.key),
        }}
        scroll={{ x: 0, y: "50svh" }}
        columns={columns}
        dataSource={data}
        loading={loading}
        bordered
        pagination={true}
      />
      <Drawer open={newopen} closable={false} onClose={onClose}>
        <div className="nav-content">
          <div className="d-flex justify-content-between sec-clr nav-title align-items-center">
            <h2 className="pri-clr fw-7 fs-30">
              {editID ? "Edit Language" : "Add New Language"}
            </h2>
            <i
              className="bi bi-x fs-26 fw-bold close-btn"
              onClick={onClose}
            ></i>
          </div>
          <div className="">
            <form onSubmit={editID ? handleEditSubmit : handleSubmit}>
              <div>
                <label
                  htmlFor="name"
                  className="side-nav-label fw-5 fs-16 c_454F5B"
                >
                  Language Name
                </label>
                <span className="text-danger">*</span>
                <br />
                <Input
                  type="text"
                  className="side-nav-input rounded"
                  name="name"
                  placeholder="Enter Language Name"
                  id="name"
                  onKeyPress={handleKeyPressName}
                  onChange={editID ? handleEditInput : handleInput}
                  value={editID ? editLanguageData.name : input.name}
                />
                {errors.name && (
                  <div className="text-danger">{errors.name}</div>
                )}
              </div>
              <div>
                <label
                  htmlFor="price"
                  className="side-nav-label mt-4 fw-5 fs-16 c_454F5B"
                >
                  Price
                </label>
                <span className="text-danger">*</span>
                <br />
                <Input
                  type="text"
                  className="side-nav-input rounded"
                  name="price"
                  placeholder="Enter Price"
                  id="price"
                  onChange={editID ? handleEditInput : handleInput}
                  onKeyPress={handleKeyPressNumber}
                  value={editID ? editLanguageData.price : input.price}
                />
                {errors.price && (
                  <div className="text-danger">{errors.price}</div>
                )}
              </div>
              <div className="button-side-nav d-flex justify-content-center">
                <Button
                  size="large"
                  className="button-bg"
                  onClick={closeDrawer}
                  htmlType="submit"
                >
                  {editID ? "Update" : "Add Language"}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Drawer>
    </>
  );
};
