import React, { useState } from "react";
import { Table, Button, Drawer, Input, message, Popconfirm, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import "./login.css";
import Swal from "sweetalert2";
import axios from "axios";
import { BASE_URL } from "../../..";

const ForgotPassword = () => {
  const [input, setInput] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();

  // const handleLogin = () => {
  //   // Perform your login logic here (e.g., API call)
  //   // If login is successful, call the login function
  //   login();
  // };
  const [errors, setErrors] = useState({});
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!input.email) {
      errors.email = "EmailID is required";
    }

    // If there are errors, set them to state and prevent form submission
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("email", input.email);
    // formData.append("price", input.price);
    try {
      await axios.post(
        `${BASE_URL}/v1/users/forgot-password?email=${input.email}`
      );
      localStorage.removeItem("accessToken");
      setLoading(false);
      Swal.fire({
        // title: "Order placed successfuly!!!",
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: "We have sent the reset password link to your registered email. Kindly use that link to update your new password.",
        icon: "success",
        // imageAlt: "Order palced Success",
        willClose: () => {
          navigate("/login");
        },
      });
      // message.success("Added Successfully");
    } catch (error) {
      console.error("Error creating post:", error);
      setLoading(false);
      if (error.response.status === 404) {
        message.error("Mail Not Found");
      }
    }
    setErrors({});
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });

    // Clear the error message for this field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear the error message for this field
    }));
  };

  return (
    <div className="login_background">
      <Spin
        spinning={loading}
        fullscreen
        className="custom-spin"
        size="large"
        tip="Loading"
      />
      <div className="login-bg">
        <div className="container">
          <div className="row">
            <div className="col-6 img-fluid">
              <img
                src={require("../../../assets/images/image_143.png")}
                className="side_image"
                alt=""
              />
            </div>
            <div className="col-6">
              <div className="login-entry flex-center">
                <div className="login_outer">
                  <div className="login_content logo_top flex-center">
                    <img
                      src={require("../../../assets/images/logo.png")}
                      alt=""
                      className=""
                    />

                    <div className="login_top flex-center">Forgot Password</div>
                  </div>
                  <p className="forgot_top flex-center">
                    No worries, we'll send you reset
                  </p>

                  <div className="">
                    <form onSubmit={handleSubmit}>
                      <div>
                        <label
                          htmlFor="email"
                          className="side-nav-label fw-5 fs-16 c_454F5B"
                        >
                          Email
                        </label>
                        <span className="text-danger">*</span>
                        <br />
                        <Input
                          type="email"
                          className="rounded input_field"
                          name="email"
                          placeholder="Enter Email ID"
                          id="email"
                          onChange={handleInput}
                          value={input.email}
                        />
                        {errors.email && (
                          <div className="text-danger">{errors.email}</div>
                        )}
                      </div>

                      <div className="">
                        <Button
                          size="large"
                          className="login_btn forgotpwd_btn"
                          htmlType="submit"
                        >
                          Send Request
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
