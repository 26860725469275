import { Input, Table, Modal, Tag, Tabs, Button, message, Flex } from "antd";
import axios from "axios";
import { BASE_URL } from "../../..";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
//  ================= Payment Table =======================
const PaymentTable = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableTotal, setTableTotal] = useState("");
  const [selectedValue, setSelectedValue] = useState("all"); // Default value
  const [searchValue, setSearchValue] = useState("");

  const handleSelect = (event) => {
    setSelectedValue(event.target.value);
    fetchData(0, searchValue, event.target.value);
    setCurrentPage(1);
    // const statusValue = event.target.value;
    // const token = localStorage.getItem("accessToken");
    // if (statusValue === "all") {
    //   fetchData(0);
    // } else {
    //   setLoading(true);
    //   axios
    //     .get(
    //       `${BASE_URL}/v1/masterpayment/getOrdersByStatus?status=${statusValue}`,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${token}`,
    //         },
    //         // responseType: "arraybuffer",
    //       }
    //     )
    //     .then((response) => response.data)
    //     .then((res_data) => {
    //       setLoading(false);
    //       setTableData(res_data.OrderData);
    //       setTableTotal(res_data.OrdersCount);
    //     });
    // }

    // setTableData(response.data.OrderData);
  };

  // Field click funtion to copy OrderId
  const handleFieldClick = (orderId, e) => {
    e.stopPropagation();
    // Prevent row click event from firing
    navigator.clipboard
      .writeText(orderId)
      .then(() => {
        Swal.fire({
          position: "top",
          icon: "success",
          title: "Order ID copied to clipboard",
          showConfirmButton: false,
          timer: 1000,
        });
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
      });
  };
  const fetchData = async (page = 0, search = "", select = "all") => {
    try {
      // const page = currentPage - 1;
      setLoading(true);
      const token = localStorage.getItem("accessToken");
      const response = await axios.get(
        BASE_URL +
          "/v1/masterpayment/getallorders?page=" +
          page +
          "&size=10&sortBy=createdAt&sortDirection=desc&status=" +
          select +
          "&searchQuery=" +
          search,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          // responseType: "arraybuffer",
        }
        // "http://localhost:1000/api/tunefor2/payments"
      );

      setTableData(response.data.OrderData);
      setTableTotal(response.data.OrdersCount);
      // setTableData(response.data);
    } catch (error) {
      console.error("Error fetching payment data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = async (pagination, filters, sorter, extra) => {
    try {
      setCurrentPage(pagination.current);

      await fetchData(pagination.current - 1, searchValue, selectedValue);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  const statusTag = (status) => {
    let color = "default";
    let text = "N/A";

    switch (status) {
      case "paid":
        color = "green";
        text = "Success";
        break;
      case "attempted":
        color = "red";
        text = "Failed";
        break;
      case "failed":
        color = "red";
        text = "Failed";
        break;
      case "captured":
        color = "green";
        text = "Success";
        break;
      default:
        color = "grey";
        text = "Pending";
    }

    return <Tag color={color}>{text}</Tag>;
  };

  // ================= Main Table's Column =======================
  const columns = [
    {
      title: "Payment Date",
      dataIndex: "createdAt",
      align: "center",
      render: (createdAt) => new Date(createdAt).toLocaleDateString(),
    },
    {
      title: "Order Number",
      dataIndex: "formOrderNumber",
      align: "center",
    },
    {
      title: "Order ID",
      dataIndex: "orderId",
      align: "center",
      render: (orderId) => (
        <p
          onDoubleClick={(e) => handleFieldClick(orderId, e)}
          style={{ margin: 0, cursor: "pointer" }} // Added margin and cursor style
          className="text-dark"
        >
          {orderId}
        </p>
      ),
    },
    {
      title: "Customer Name",
      dataIndex: "userName",
      align: "center",
    },
    {
      title: "Customer Email",
      dataIndex: "contactMail",
      align: "center",
    },
    {
      title: "Customer Number",
      dataIndex: "contactNumber",
      align: "center",
    },
    {
      title: "Amount",
      align: "center",
      dataIndex: "amountPaid",
      render: (amount) => `₹${amount}`,
    },
    {
      title: "No of Attempts",
      align: "center",
      dataIndex: "orderAttempts",
    },
    {
      title: "Status",
      align: "center",
      dataIndex: "orderStatus",
      render: (orderStatus) => statusTag(orderStatus),
      // filters: [
      //   { text: "Success", value: "paid" },
      //   { text: "Failed", value: "attempted" },
      //   { text: "Pending", value: "created" },
      // ],
      // onFilter: (value, record) => {
      //   const token = localStorage.getItem("accessToken");
      //   const response = axios.get(
      //     BASE_URL + "/v1/masterpayment/getOrdersByStatus?status=" + value,
      //     {
      //       headers: {
      //         Authorization: `Bearer ${token}`,
      //       },
      //       // responseType: "arraybuffer",
      //     }
      //     // "http://localhost:1000/api/tunefor2/payments"
      //   );

      //   // setTableData(response.data.OrderData);
      //   // setTableTotal(response.data.OrdersCount);
      //   // setTableData(response.data);
      // },

      // // const status = record.orderStatus || "N/A";
      // // return status === value;

      // render: (text, record) => {
      //   const status = record.orderStatus || "N/A";
      //   return statusTag(status);
      // },
    },
  ];
  const searchForTable = (e) => {
    // (e.target.value);
    setSearchValue(e.target.value);
    fetchData(0, e.target.value, selectedValue);
    setCurrentPage(1);
    // const token = localStorage.getItem("accessToken");
    // axios
    //   .get(
    //     BASE_URL +
    //       "/v1/masterpayment/getPayments/generalOrderSearch?query=" +
    //       e.target.value,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //       // responseType: "arraybuffer",
    //     }
    //   )

    //   .then((response) => {
    //     setTableData(response.data.OrderData);
    //     setTableTotal(response.data.OrdersCount);
    //   });
  };
  // Handle row click to avoid issues when payments array is null
  const handleRowClick = (record) => {
    if (record.payments) {
      setSelectedOrder(record);
      setModalVisible(true);
    } else {
      // Handle the case when payments is null
      // console.warn("Payments array is null for this order:", record.orderId);
      setSelectedOrder({
        ...record,
        payments: [], // Assign an empty array to avoid errors
      });
      setModalVisible(true);
    }
  };
  const handleModalClose = () => {
    setModalVisible(false);
    setSelectedOrder(null);
  };

  //  ===================== Mpdal Table's Column Data ======================
  const paymentColumns = [
    {
      title: "Payment ID",
      dataIndex: "paymentId",
      align: "center",
      width: 180,
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (status) => statusTag(status),
    },
    {
      title: "Type",
      dataIndex: "type",
      align: "center",
    },
    {
      title: "Error Description",
      dataIndex: "errorDescription",
      align: "center",
      width: 250,
      render: (error) => (error ? error : "-"),
    },
    {
      title: "Error Source",
      dataIndex: "errorSource",
      align: "center",
      render: (error) => (error ? error : "-"),
    },
    {
      title: "Fee",
      dataIndex: "fee",
      align: "center",
      render: (fee) => `₹${fee}`,
    },
    {
      title: "Tax",
      dataIndex: "tax",
      align: "center",
      render: (tax) => `₹${tax}`,
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      align: "center",
      render: (date) => new Date(date).toLocaleDateString(),
    },
  ];

  return (
    <div className="masterBase">
      <div className="d-flex justify-content-end mb-3">
        <div>
          <select
            name="language"
            id="language"
            className="mx-5 px-5 py-1 fs-16 statusField"
            value={selectedValue}
            onChange={handleSelect}
          >
            <option value="paid">Success</option>
            <option value="attempted">Failure</option>
            <option value="created">Pending</option>
            <option value="all">All</option>
          </select>
        </div>

        <Input
          size="small"
          className="search fs-14 fw-6"
          placeholder="Search"
          prefix={<i className="bi bi-search"></i>}
          onChange={searchForTable}
        />
      </div>

      <Table
        columns={columns}
        bordered
        // pagination
        pagination={{
          current: currentPage,
          pageSize: 10,
          total: tableTotal, // Total number of data items
        }}
        dataSource={tableData}
        loading={loading}
        rowKey={(record) => record.orderId}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
        onChange={handleTableChange}
      />

      {/* Modal for displaying payment details */}
      <Modal
        title={
          <div style={{ padding: "20px" }}>
            {" "}
            {/* Add custom padding here */}
            {`Customer: ${selectedOrder?.userName}, Order ID: ${selectedOrder?.orderId}`}
          </div>
        }
        open={modalVisible}
        onCancel={handleModalClose}
        footer={null}
        width={1000}
      >
        <Table
          columns={paymentColumns}
          dataSource={selectedOrder?.payments}
          pagination={false}
          rowKey={(record) => record.paymentId}
          scroll={{ y: "80vh", x: "80vw" }}
        />
      </Modal>
    </div>
  );
};

// =================== Refund Table =====================

const RefundTable = () => {
  const [refundData, setRefundData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchRefundData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "http://localhost:1000/api/tunefor2/refunds"
      );
      setRefundData(response.data);
    } catch (error) {
      console.error("Error fetching refund data:", error);
      message.error("Failed to load refund data.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRefundData();
  }, []);

  const handleApprove = async (record) => {
    try {
      const response = await axios.post(
        `http://localhost:1000/api/tunefor2/refunds/${record._id}/approve`
      );
      message.success("Refund approved successfully!");
      fetchRefundData(); // Refresh data after update
    } catch (error) {
      console.error("Error approving refund:", error);
      message.error("Failed to approve refund.");
    }
  };

  const handleReject = async (record) => {
    try {
      const response = await axios.post(
        `http://localhost:1000/api/tunefor2/refunds/${record._id}/reject`
      );
      message.success("Refund rejected successfully!");
      fetchRefundData(); // Refresh data after update
    } catch (error) {
      console.error("Error rejecting refund:", error);
      message.error("Failed to reject refund.");
    }
  };

  const refundStatusTag = (status) => {
    let color = "default";
    switch (status) {
      case "approved":
        color = "green";
        break;
      case "pending":
        color = "orange";
        break;
      case "rejected":
        color = "red";
        break;
      default:
        color = "default";
    }
    return <Tag color={color}>{status.toUpperCase()}</Tag>;
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      align: "center",
      render: (createdAt) => new Date(createdAt).toLocaleDateString(),
    },
    {
      title: "ID",
      dataIndex: "orderId",
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "userName",
      align: "center",
    },
    {
      title: "Email",
      dataIndex: "email",
      align: "center",
    },
    {
      title: "Refund Amount",
      dataIndex: "refundAmount",
      align: "center",
      render: (amount) => `₹${amount}`,
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      align: "center",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "refundStatus",
      align: "center",
      render: (status) => refundStatusTag(status),
    },
    {
      title: "Actions",
      align: "center",
      render: (text, record) => (
        <div>
          <Flex gap="small">
            <Button
              type="primary"
              onClick={() => handleApprove(record)}
              ghost={record.refundStatus === "approved"}
              disabled={record.refundStatus === "approved"}
              style={{ marginRight: 8 }}
              size="small"
            >
              Approve
            </Button>
            <Button
              type="primary"
              danger
              onClick={() => handleReject(record)}
              disabled={record.refundStatus === "rejected"}
              size="small"
            >
              Reject
            </Button>
          </Flex>
        </div>
      ),
    },
  ];

  return (
    <div className="masterBase">
      <Table
        columns={columns}
        bordered
        pagination
        dataSource={refundData}
        loading={loading}
        rowKey={(record) => record._id}
      />
    </div>
  );
};
// ===================== Payment Base ===============
const Payment = () => {
  const { TabPane } = Tabs;
  const items = [
    { key: "1", label: "Payments List", children: <PaymentTable /> },
    // { key: "2", tab: "Refund List", content: <RefundTable /> },
  ];
  const [activeTab, setActiveTab] = useState("1");
  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <div className="masterBase">
      {/* <Tabs
        defaultActiveKey="1"
        indicator={{ size: 30, align: "center" }}
        onChange={handleTabChange}
      >
        {items.map(({ key, tab, content }) => (
          <TabPane tab={tab} key={key}>
            {content}
          </TabPane>
        ))}
      </Tabs> */}
      <Tabs
        defaultActiveKey="1"
        activeKey={activeTab}
        onChange={handleTabChange}
        items={items} // Use the `items` prop
      />
    </div>
  );
};

export default Payment;
