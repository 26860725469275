import React, { useState, useEffect } from "react";
import { Steps, Input, Button } from "antd";
import NavLogo from "./Base/NavLogo";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "..";
import moment from "moment";
import { Helmet, HelmetProvider } from "react-helmet-async";

const { Step } = Steps;
const NumericInput = (props) => {
  const { onChange } = props;
  const handleChange = (e) => {
    const { value: inputValue } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(inputValue) || inputValue === "" || inputValue === "-") {
      onChange(inputValue);
    }
  };

  return (
    <Input
      {...props}
      onChange={handleChange}
      placeholder="Input a number"
      maxLength={10}
    />
  );
};

const Order = () => {
  const [current, setCurrent] = useState(0);
  const [value, setValue] = useState("");
  const [orderNo, setOrderNo] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [status, setStatus] = useState("");
  const [composeData, setComposeData] = useState("");
  const [confirmData, setComfirmData] = useState("");
  const [recordedData, setRecordedData] = useState("");
  const [deliverData, setDeliverData] = useState("");
  const params = useParams();

  const id = params.id;
  useEffect(() => {
    window.scrollTo(0, 0);

    fetchDataById();
    // fetchOrderTracker();
  }, []);

  const fetchDataById = () => {
    axios
      .get(BASE_URL + "/v1/form/" + id)
      .then((response) => response.data)
      .then((res_data) => {
        setOrderNo(res_data.data.formRequestDto.orderNumber);
        setMobileNo(res_data.data.formRequestDto.phonenumber);
        setStatus(res_data.data.formRequestDto.status);
        setValue(res_data.data.formRequestDto);
        let phoneNumber = res_data.data.formRequestDto.phonenumber;
        let sanitizedPhoneNumber = phoneNumber.replace("+", "");

        axios
          .get(
            BASE_URL +
              "/v1/form/orderTracker?mobileNumber=" +
              "%2B" +
              sanitizedPhoneNumber +
              "&orderNumber=" +
              res_data.data.formRequestDto.orderNumber
          )
          .then((response) => response.data)
          .then((res_data) => {
            setComfirmData(res_data.data.tracker[0][1]);
            setComposeData(res_data.data.tracker[0][2]);
            setRecordedData(res_data.data.tracker[0][3]);
            setDeliverData(res_data.data.tracker[0][4]);
            for (
              let index = 0;
              index < res_data.data.tracker[0].length;
              index++
            ) {
              const element = res_data.data.tracker[0].length - 1;
              if (index === element) {
                if (
                  res_data.data.tracker[0][index].orderPosition ===
                  "ORDER_CONFIRMED"
                ) {
                  setCurrent(0);
                } else if (
                  res_data.data.tracker[0][index].orderPosition ===
                  "SONG_COMPOSING"
                ) {
                  setCurrent(1);
                } else if (
                  res_data.data.tracker[0][index].orderPosition ===
                  "SONG_RECORDING"
                ) {
                  setCurrent(2);
                } else if (
                  res_data.data.tracker[0][index].orderPosition === "DELIVERED"
                ) {
                  setCurrent(3);
                }
              }
            }
          });
      });
  };

  const navigate = useNavigate();

  return (
    <HelmetProvider>
      <Helmet>
        <title>Track Order | Tune for 2</title>
        <link rel="icon" href="/logo-img.ico" />
      </Helmet>
      <>
        <div className="section1 position-relative">
          <NavLogo />
          <img
            src={require("../assets/images/pay-dialog-bg-img1-.png")}
            alt=""
            className="order_design img-fluid"
          />
          <img
            src={require("../assets/images/pay-dialog-bg-img2-.png")}
            alt=""
            className="order_design2 img-fluid "
          />
          <img
            src={require("../assets/images/pay-dialog-bg-img2-.png")}
            alt=""
            className="order_design3 img-fluid d-sm-none d-md-block"
          />
          <img
            src={require("../assets/images/hero_decor_bg.png")}
            alt=""
            className="order_design4 img-fluid multiply"
          />
          <div className="container-fluid">
            <div className="row ps-xl-5 ps-sm-1 ps-0  ms-0">
              <div className="col-12  ms-0">
                <h1 className="c_FE7E4F DM_Sans fs-36 ps-xl-5 ps-ms-2 fw-6 ps-1">
                  Track your Order
                </h1>
              </div>
            </div>
          </div>
          <div className="container ">
            <div className="row">
              <div className="col-xl-12">
                <div className="text-center tune_img ">
                  <img
                    src={require("../assets/images/order_tracking.png")}
                    alt=""
                    className="img-fluid multiply"
                  />
                </div>
              </div>
              <form action="" method="post">
                <div className="container pb-4">
                  <div className="row">
                    <div className="col-xl-2 col-lg-2 col-md-1 col-sm-12 col-12"></div>
                    <div className="col-xl-4 col-lg-4 col-md-5 col-6 ">
                      <div className="form_input mx-0 mx-xl-3 mx-lg-3 mx-sm-2  ">
                        <label htmlFor="">Order Number</label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          name="order_number"
                          placeholder="MS9878"
                          value={orderNo}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-5 col-6 ">
                      <div className="form_input mx-0 mx-xl-3 mx-lg-3 mx-sm-2  ">
                        <label htmlFor="">Mobile Number</label>
                        <NumericInput
                          value={mobileNo}
                          // onChange={setValue}
                          className="form-control mt-2"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-1 col-sm-12 col-12"></div>
                  </div>
                </div>
              </form>
              <div className="col-xl-10 col-lg-10 col-md-10  col-12 offset-md-1">
                <div className="order_process my-4 mt-5">
                  <div className="order_process ">
                    <Steps
                      current={current}
                      className="custom-steps p-0"
                      // onChange={handleStepChange}
                    >
                      <Step
                        className="OrderStep p-0 orderProcessing"
                        icon={<i className="bi bi-music-note-beamed"></i>}
                        description={
                          confirmData?.updatedDate || confirmData?.comments
                            ? `${
                                confirmData?.updatedDate
                                  ? moment(confirmData.updatedDate).format(
                                      "DD-MM-YYYY"
                                    )
                                  : ""
                              }${
                                confirmData?.comments
                                  ? ` / ${confirmData.comments}`
                                  : ""
                              }`
                            : ""
                        }
                      />
                      <Step
                        className="OrderStep p-0 orderConfirmed"
                        icon={<i className="bi bi-music-note-beamed"></i>}
                        description={
                          composeData?.updatedDate || composeData?.comments
                            ? `${
                                composeData?.updatedDate
                                  ? moment(composeData.updatedDate).format(
                                      "DD-MM-YYYY"
                                    )
                                  : ""
                              }${
                                composeData?.comments
                                  ? ` / ${composeData.comments}`
                                  : ""
                              }`
                            : ""
                        }
                      />
                      <Step
                        className="OrderStep p-0 orderComposing"
                        icon={<i className="bi bi-music-note-beamed"></i>}
                        description={
                          recordedData?.updatedDate || recordedData?.comments
                            ? `${
                                recordedData?.updatedDate
                                  ? moment(recordedData.updatedDate).format(
                                      "DD-MM-YYYY"
                                    )
                                  : ""
                              }${
                                recordedData?.comments
                                  ? ` / ${recordedData.comments}`
                                  : ""
                              }`
                            : ""
                        }
                      />

                      {current === 3 ? (
                        <Step
                          className="OrderStep p-0 orderDelivered"
                          icon={<i className="bi bi-music-note-beamed"></i>}
                          description={
                            deliverData?.updatedDate || deliverData?.comments
                              ? `${
                                  deliverData?.updatedDate
                                    ? moment(deliverData.updatedDate).format(
                                        "DD-MM-YYYY"
                                      )
                                    : ""
                                }${
                                  deliverData?.comments
                                    ? ` / ${deliverData.comments}`
                                    : ""
                                }`
                              : ""
                          }
                        />
                      ) : (
                        <Step
                          className="OrderStep p-0 orderDelivery"
                          icon={<i className="bi bi-music-note-beamed"></i>}
                          description={
                            deliverData?.updatedDate || deliverData?.comments
                              ? `${
                                  deliverData?.updatedDate
                                    ? moment(deliverData.updatedDate).format(
                                        "DD-MM-YYYY"
                                      )
                                    : ""
                                }${
                                  deliverData?.comments
                                    ? ` / ${deliverData.comments}`
                                    : ""
                                }`
                              : ""
                          }
                        />
                      )}
                    </Steps>
                  </div>
                </div>
              </div>
              <div className="text-center py-5">
                {current === 3 && (
                  <span
                    className="f77b21 text-white rate_btn p-3"
                    onClick={() => {
                      window.location.href =
                        "https://g.page/r/CUB5xRdEoie5EBI/review";
                    }}
                  >
                    Feel free to share your rating with us
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </HelmetProvider>
  );
};

export default Order;
