import React, { useEffect } from "react";
import Navbar from "../Base/Navbar";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import Footer from "../Base/Footer";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Space } from "antd";
const AboutUs = () => {
  useEffect(() => {
    scrollTop();
    sessionStorage.removeItem("bookedInstrument");
  }, []);
  const scrollTop = () => window.scrollTo(0, 0);
  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Heartfelt Customised Song to Gift Your Loved Ones | Tune for 2
        </title>
        <meta
          name="description"
          content="Tune for 2: best custom song service, offering Personalized custom songs for birthdays | anniversaries | weddings | love proposals & all special moments."
          data-rh="true"
        />
        <link rel="canonical" href="https://tunefor2.com/about-us"></link>
      </Helmet>
      <>
        <Navbar about={true} />
        <div className="container-fluid bg-color" id="aboutUs">
          <div className="container-fluid  px-3 px-md-5">
            <div className="row">
              <div className="text-center col-md-9 mx-auto py-5 text-dark">
                <h1 className="fw-7 display-4 my-4 mt-5 title_style">
                  About Us
                </h1>
                <p className="fw-semibold c_9F9F9F">
                  Welcome to Tune for 2, where music meets emotions and memories
                  transform into melodies. At Tune For 2, we specialize in
                  creating personalized, custom-made songs that celebrate life's
                  special moments and heartfelt emotions.
                </p>
              </div>
              <div className="col-12">
                <img
                  src={require("../../assets/images/TuneFor2Img/tune-for-2-customized-songs.webp")}
                  // src={require("../../assets/images/")}
                  title="tune for 2 customized songs"
                  alt="tune for 2 customised songs"
                  className="img-fluid mb-5"
                />
              </div>
              <div className="text-center col-md-9 mx-auto py-md-5 text-dark">
                <h2 className="fw-7 display-4 my-4 mt-5 title_style">
                  What <span className="c_FFB775">We Do</span>
                </h2>
                <p className="fw-semibold c_9F9F9F">
                  Our team of skilled musicians, lyricists, and producers
                  collaborates closely with each customer to craft a customised
                  song that captures the essence of their experiences. Whether
                  it's a wedding anniversary, a birthday surprise, a proposal,
                  or a tribute to a loved one, we transform your personal
                  stories and emotions into beautifully composed music.
                </p>
              </div>
              <div className="col-md-6 d-flex align-items-center">
                <img
                  src={require("../../assets/images/TuneFor2Img/piano-tune-for-2.webp")}
                  alt="piano tune for 2"
                  className="img-fluid"
                  title="piano tune for 2"
                />
              </div>
              <div className="col-md-6 mt-md-5">
                <div className="about_us_do mt-md-5 px-md-3">
                  <div className="fw-semibold fs-24 mb-3">
                    Why Choose <span className="c_FFB775">Tune For 2?</span>
                  </div>
                  <h5 className="fw-semibold fs-18 mb-1">
                    Personalised Approach:
                  </h5>
                  <p className="fw-semibold c_666666 mb-4">
                    We believe in the power of personalization. Each song is
                    tailored to reflect your individuality and the emotions you
                    wish to express.
                  </p>
                  <h5 className="fw-semibold fs-18 mb-1">
                    Professional Excellence:
                  </h5>
                  <p className="fw-semibold c_666666 mb-4">
                    Our talented team ensures top-quality music production and
                    lyrical craftsmanship, delivering songs that are not only
                    heartfelt but also professionally polished.
                  </p>
                  <h5 className="fw-semibold fs-18 mb-1">
                    Memorable Experiences:
                  </h5>
                  <p className="fw-semibold c_666666 mb-4">
                    We create more than just songs; we create cherished
                    memories. Our goal is to make your special moments
                    unforgettable through the gift of music.
                  </p>
                  <h5 className="fw-semibold fs-18 mb-1">
                    Customer Satisfaction:
                  </h5>
                  <p className="fw-semibold c_666666 mb-4">
                    Your satisfaction is our priority. We strive to exceed your
                    expectations and provide a seamless, enjoyable experience
                    from start to finish.{" "}
                  </p>
                </div>
              </div>

              <div className="text-center col-md-8 mx-auto text-dark">
                <h2 className="fw-7 display-4 my-4 mt-5 title_style">
                  Meet The <span className="c_FFB775">Founder</span>
                </h2>
                <p className="fw-semibold c_9F9F9F">
                  <span style={{ color: "#000" }}>Martin Kartenjer,</span> the
                  visionary founder of Tune For 2, is a passionate musician and
                  entrepreneur driven by a deep appreciation for the emotional
                  power of music. With a background in both music composition
                  and business management, Martin brings a unique blend of
                  creativity and strategic acumen to the world of customised
                  songs.
                </p>
              </div>
            </div>
          </div>
          <div className="container-fluid  px-3 px-md-5 py-3 pb-md-5">
            <div className="row">
              <div className="col-md-6 pt-md-4 ps-0 ps-lg-5">
                <div className="about_us_meet mt-md-5 p-0 p-lg-5 px-md-3 ">
                  <div className="about_content ps-0 ps-lg-5">
                    <div className="fw-semibold fs-20 mb-1">
                      The Journey to Tune For 2
                    </div>
                    <p className="fw-semibold c_666666 mb-4">
                      Martin's journey began with a profound belief in music's
                      ability to evoke emotions, capture memories, and connect
                      people on a personal level.
                    </p>
                    <p className="fw-semibold c_666666 mb-5">
                      Inspired by his own experiences and those of friends and
                      family, he envisioned a service that would allow
                      individuals to express their deepest feelings through
                      bespoke musical compositions.
                    </p>
                    <div className="fw-semibold fs-20 mb-1">
                      Founding Tune For 2
                    </div>
                    <p className="fw-semibold c_666666 mb-4">
                      In 2022, Martin founded Tune For 2 with a clear mission:
                      to transform personal stories into beautifully crafted
                      songs that resonate with authenticity and heart.
                    </p>
                    <p className="fw-semibold c_666666 mb-4">
                      His vision was to create a platform where customers could
                      collaborate with talented musicians and lyricists to
                      create one-of-a-kind songs for special occasions and
                      meaningful moments in life.
                    </p>
                    <div className="fw-semibold fs-20 mb-1">
                      Stay Connected with Martin Kartenjer!
                    </div>
                    <p className="fw-semibold c_666666 mb-4">
                      Don't miss out on Martin Kartenjer, latest releases and
                      behind-the-scenes content. Here’s how you can stay
                      connected and enjoy Martin’s Music.
                    </p>
                    <div className="fw-semibold">
                      <div className="social_icon">
                        {/* <Space> */}
                        <a
                          href="https://open.spotify.com/artist/3OY61Sme8AYjtKYCzmReCw"
                          target="_blank"
                          className="margin-right"
                        >
                          <i className="bi bi-spotify c_0A0D13"></i>
                        </a>
                        {/* <a href="https://www.twitter.com" target="_blank">
                <i className="bi bi-twitter c_0A0D13"></i>
              </a> */}
                        <a
                          href="https://www.instagram.com/tunefor2_/"
                          target="_blank"
                          className="margin-right"
                        >
                          <i className="bi bi-instagram c_0A0D13"></i>
                        </a>
                        <a
                          href="https://www.youtube.com/results?search_query=martin+kartenjer"
                          target="_blank"
                          className="margin-right"
                        >
                          <i className="bi bi-youtube c_0A0D13"></i>
                        </a>
                        {/* </Space> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 pt-md-5 text-center d-flex align-items-center">
                <img
                  src={require("../../assets/images/TuneFor2Img/martin-customised-song-creator.webp")}
                  alt="martin customised song creator"
                  title="martin customised song creator"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="container-fluid secondary-bg-color">
            <div className="row">
              <div className="col-md-6 pt-md-4 ps-0 ps-lg-5">
                <div className=" mt-md-5 p-0 p-lg-5 px-md-3 ">
                  <div className="about_content ps-4 ps-lg-5 ps-md-5">
                    <div className="fw-semibold fs-20 mb-1">
                      <h2 className="fw-7 display-4 mb-5 mt-0 title_style ">
                        Impact <span className="c_FFB775">And Vision</span>
                      </h2>
                    </div>
                    <p className="fw-semibold c_666666 mb-5">
                      Today, Tune For 2 stands as a testament to Martin's vision
                      and dedication. The company has touched the lives of
                      countless individuals and couples, offering them the
                      opportunity to preserve their cherished memories and
                      emotions in the form of customised songs.
                    </p>
                    <p className="fw-semibold c_666666 mb-5">
                      Martin continues to innovate and expand Tune For 2's
                      offerings, ensuring that each song created is a true
                      reflection of its creator's story and sentiment.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 py-md-5 py-2 text-center d-flex align-items-center justify-content-center">
                <img
                  src={require("../../assets/images/TuneFor2Img/boy-singing.webp")}
                  alt="boy singing"
                  title="boy singing"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="container-fluid  px-3 px-md-5">
            <div className="row mx-auto mt-md-5 pt-md-5 align-items-center">
              <div className="col-md-5 offset-md-2">
                <img
                  src={require("../../assets/images/TuneFor2Img/custom-music-box.webp")}
                  alt="custom music box"
                  className="img-fluid multiply"
                  title="custom music box"
                />
              </div>
              <div className="col-md-7 text-center text-md-start">
                <h2 className="display-6 fw-bold DM_Sans">
                  Give the gift that gets hearts soaring
                </h2>
                <p className="text-muted w-75">
                  Give the gift that truly keeps on giving a{" "}
                  <span className="c_FFB775">one-of-a-kind song </span>
                  uniquely crafted to celebrate someone you love.
                </p>
                <div className="d-flex">
                  <Link
                    to={"/create-a-song"}
                    className="text-decoration-none py-3 btn bg_FFB775 mt-3 DM_Sans fw-6 mx-auto ms-md-1"
                  >
                    Create Your Song
                  </Link>
                </div>
              </div>
              <div className="col-12 text-center">
                <Link to={"/"} className="navbar-brand nav-link mt-5">
                  <img
                    src={require("../../assets/images/TuneFor2Img/tune-for-2-logo.webp")}
                    alt="tune for 2 logo"
                    className="img-fluid mb-2 cursor"
                  />
                </Link>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </>
    </HelmetProvider>
  );
};

export default AboutUs;
