import { Space } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const termsAndConditionPage = () => {
    navigate("/terms-and-conditions");
  };
  const privacyPolicyPage = () => {
    navigate("/privacy-policy");
  };
  const faq = () => {
    navigate("/faq");
  };

  return (
    <div>
      <div id="footer" className="pt-5 pb-3 row row-gap-3">
        <div className="col-12 col-md-4 fw-semibold text-center text-md-start">
          <p> &copy; {currentYear} All Rights Reserved</p>
        </div>
        <div className="col-12 col-md-4 text-center fw-semibold pb-4">
          {/* <Space size={"middle"}>
            <a
              className="text-decoration-none text-dark"
              onClick={termsAndConditionPage}
            >
              Terms & Conditions
            </a>
            <a
              onClick={privacyPolicyPage}
              className="text-decoration-none text-dark"
            >
              Privacy Policy
            </a>
            <a className="text-decoration-none text-dark" onClick={faq}>
              FAQ
            </a>
          </Space> */}
          <div className="d-flex flex-column justify-content-between flex-sm-row flex-wrap">
            <a
              className="text-decoration-none text-dark flex-grow-1 cursor"
              onClick={termsAndConditionPage}
            >
              Terms & Conditions
            </a>
            <a
              onClick={privacyPolicyPage}
              className="text-decoration-none text-dark flex-grow-1 cursor"
            >
              Privacy Policy
            </a>
            <a
              className="text-decoration-none text-dark flex-grow-1 cursor"
              onClick={faq}
            >
              FAQ
            </a>
          </div>
        </div>
        <div className="col-12 col-md-4 text-center text-md-end fw-semibold">
          <div className="social_icons">
            <Space>
              <a
                href="https://open.spotify.com/artist/2ekD3rCzFvv0WBRO0FXeJa?si=EH-09VFbReSyYAvfH3Hthg"
                target="_blank"
              >
                <i className="bi bi-spotify c_0A0D13"></i>
              </a>
              <a href="https://www.instagram.com/tunefor2_/" target="_blank">
                <i className="bi bi-instagram c_0A0D13"></i>
              </a>
              <a
                href="https://www.youtube.com/results?search_query=martin+kartenjer"
                target="_blank"
              >
                <i className="bi bi-youtube c_0A0D13"></i>
              </a>
            </Space>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
