import React, { useEffect, useState } from "react";
import { Tabs, Tag } from "antd";
import { AllSongs } from "./AllSongs";
import { Processing } from "./OrderSaved";
import { Composing } from "./Composing";
import { Delivered } from "./Delivered";
import { Rejected1 } from "./OrderConfirmed";
import { Recording } from "./Recording";
import { Rejected } from "./Rejected";
import { Songdata } from "./SongData";
import Swal from "sweetalert2";
import axios from "axios";
import { BASE_URL } from "../../..";

export const SongStatus = () => {
  // const [childCount, setChildCount] = useState(null);
  // const handleCount = (data) => {
  //   setChildCount(data);
  // };

  const [allSongCount, setAllSongCount] = useState(null);
  const [savedSongCount, setSavedSongCount] = useState(null);
  const [confirmedSongCount, setConfirmedSongCount] = useState(null);
  const [composingSongCount, setcomposingSongCount] = useState(null);
  const [recordingSongCount, setRecordingSongCount] = useState(null);
  const [deliveredSongCount, setDeliveredSongCount] = useState(null);
  const [rejectedSongCount, setRejectedSongCount] = useState(null);
  const [selectedTab, setSelectedTab] = useState("1");
  // const [savedCount, setSavedCount] = useState("");

  const handleTabChange = (key) => {
    setSelectedTab(key);
  };

  const fetchAllSongsLength = () => {
    // const token = "";
    const token = localStorage.getItem("accessToken");
    axios
      .get(BASE_URL + "/v1/form/paginated", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // responseType: "arraybuffer",
      })
      .then((response) => response.data)
      .then((res_data) => {
        setAllSongCount(res_data.data.formRequestDtos.totalElements);
      });
  };
  const fetchSavedLength = () => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(BASE_URL + "/v1/form/?position=ORDER_SAVED", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // responseType: "arraybuffer",
      })
      .then((response) => response.data)
      .then((res_data) => {
        setSavedSongCount(res_data.data.formDtos.count);
      });
  };
  const fetchConfirmedLength = () => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(BASE_URL + "/v1/form/?position=ORDER_CONFIRMED", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // responseType: "arraybuffer",
      })
      .then((response) => response.data)
      .then((res_data) => {
        setConfirmedSongCount(res_data.data.formDtos.count);
      });
  };
  const fetchComposingLength = () => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(BASE_URL + "/v1/form/?position=SONG_COMPOSING", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // responseType: "arraybuffer",
      })
      .then((response) => response.data)
      .then((res_data) => {
        setcomposingSongCount(res_data.data.formDtos.count);
      });
  };
  const fetchRecordingLength = () => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(BASE_URL + "/v1/form/?position=SONG_RECORDING", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // responseType: "arraybuffer",
      })
      .then((response) => response.data)
      .then((res_data) => {
        setRecordingSongCount(res_data.data.formDtos.count);
      });
  };
  const fetchDeliveredLength = () => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(BASE_URL + "/v1/form/?position=DELIVERED", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // responseType: "arraybuffer",
      })
      .then((response) => response.data)
      .then((res_data) => {
        setDeliveredSongCount(res_data.data.formDtos.count);
      });
  };
  // const fetchRejectedLength = () => {
  //   const token = localStorage.getItem("accessToken");
  //   axios
  //     .get(BASE_URL + "/v1/form/getrejectedforms?activeStatus=inactive", {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //       // responseType: "arraybuffer",
  //     })
  //     .then((response) => response.data)
  //     .then((res_data) => {
  //         res_data.data.formRequestDtos.count,
  //         "res_data.data.formRequestDtos"
  //       );
  //       setRejectedSongCount(res_data.data.formRequestDtos.count);
  //     });
  // };

  useEffect(() => {
    fetchAllSongsLength();
    fetchSavedLength();
    fetchConfirmedLength();
    fetchComposingLength();
    fetchRecordingLength();
    fetchDeliveredLength();
    // fetchRejectedLength();
  }, []);

  const handleFieldClick = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(e.target.textContent);
    Swal.fire({
      position: "top",
      icon: "success",
      title: "Email copied to clipboard",
      showConfirmButton: false,
      timer: 1000,
    });
  };
  const { TabPane } = Tabs;

  const items = [
    {
      key: "1",
      label: (
        <p className="m-0">
          All Songs
          <span className="song-count-tag ms-1">{allSongCount}</span>
        </p>
      ),
      children: (
        <AllSongs
          handleFieldClick={(e) => handleFieldClick(e)}
          setSavedSongCount={setSavedSongCount}
          setConfirmedSongCount={setConfirmedSongCount}
          setcomposingSongCount={setcomposingSongCount}
          setRecordingSongCount={setRecordingSongCount}
          setDeliveredSongCount={setDeliveredSongCount}
          additionalProp1={selectedTab}
        />
      ),
    },
    {
      key: "2",
      label: (
        <p className="m-0">
          Order Saved
          <span className="song-count-tag ms-1">{savedSongCount}</span>
        </p>
      ),
      children: (
        <Processing
          handleFieldClick={(e) => handleFieldClick(e)}
          setSavedSongCount={setSavedSongCount}
          setConfirmedSongCount={setConfirmedSongCount}
          additionalProp1={selectedTab}
          setRejectedSongCount={setRejectedSongCount}
        />
      ),
    },
    {
      key: "3",
      label: (
        <p className="m-0">
          Order Confirmed{" "}
          <span className="song-count-tag ms-1">{confirmedSongCount}</span>
        </p>
      ),
      children: (
        <Rejected1
          handleFieldClick={(e) => handleFieldClick(e)}
          setConfirmedSongCount={setConfirmedSongCount}
          setcomposingSongCount={setcomposingSongCount}
          additionalProp1={selectedTab}
        />
      ),
    },
    {
      key: "4",
      label: (
        <p className="m-0">
          Composing
          <span className="song-count-tag ms-1">{composingSongCount}</span>
        </p>
      ),
      children: (
        <Composing
          handleFieldClick={(e) => handleFieldClick(e)}
          setcomposingSongCount={setcomposingSongCount}
          setRecordingSongCount={setRecordingSongCount}
          additionalProp1={selectedTab}
        />
      ),
    },
    {
      key: "5",
      label: (
        <p className="m-0">
          Recording
          <span className="song-count-tag ms-1">{recordingSongCount}</span>
          {/* <span className="song-status-count">{allSongCount}</span> */}
        </p>
      ),
      children: (
        <Recording
          handleFieldClick={(e) => handleFieldClick(e)}
          setRecordingSongCount={setRecordingSongCount}
          setDeliveredSongCount={setDeliveredSongCount}
          additionalProp1={selectedTab}
        />
      ),
    },
    {
      key: "6",
      label: (
        <p className="m-0">
          Delivered
          <span className="song-count-tag ms-1">{deliveredSongCount}</span>
          {/* <span className="song-status-count">{allSongCount}</span> */}
        </p>
      ),
      children: (
        <Delivered
          handleFieldClick={(e) => handleFieldClick(e)}
          additionalProp1={selectedTab}
        />
      ),
    },
    // {
    //   key: "7",
    //   tab: (
    //     <p className="m-0">
    //       Rejected
    //       <span className="song-count-tag ms-1">{rejectedSongCount}</span>
    //       {/* <span className="song-status-count">{allSongCount}</span> */}
    //     </p>
    //   ),
    //   content: (
    //     <Rejected
    //       handleFieldClick={(e) => handleFieldClick(e)}
    //       additionalProp1={selectedTab}
    //     />
    //   ),
    // },
  ];

  return (
    <div
      id="songStatusBase"
      className="p-4"
      style={{ backgroundColor: "#fff" }}
    >
      {/* <Tabs
        defaultActiveKey="1"
        indicator={{
          size: 30,
          align: "center",
        }}
        onChange={handleTabChange}
      >
        {items.map(({ key, tab, content }) => {
          return (
            <TabPane tab={tab} key={key}>
              {content}
            </TabPane>
          );
        })}
      </Tabs> */}
      <Tabs
        defaultActiveKey="1"
        activeKey={selectedTab}
        onChange={handleTabChange}
        items={items} // Use the `items` prop
      />
    </div>
  );
};
