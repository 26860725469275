import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Navbar from "../../Base/Navbar";
import check from "../../../assets/images/TuneFor2Img/check-gif.gif";
import cross from "../../../assets/images/TuneFor2Img/cross.gif";
import "./SuccessFailure.css";
import axios from "axios";
import { BASE_URL } from "../../..";
import { useNavigate } from "react-router-dom";

const SuccessFailure = () => {
  const status = sessionStorage.getItem("paymentStatus");
  const [navLocation, setNavLocation] = useState(
    status ? "/" : "/create-a-song"
  );
  const [paymentformInfo, setPaymentformInfo] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const userId = sessionStorage.getItem("paymentUserId");

    if (userId != 0) {
      getOrderData(userId);
    }
  }, []);
  const getOrderData = (id) => {
    setLoading(true);
    axios
      .get(BASE_URL + "/v1/form/" + id)
      .then((response) => response.data)
      .then((res_data) => {
        setPaymentformInfo(res_data.data.formRequestDto);
        setLoading(false);
      });
  };
  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Heartfelt Customised Song to Gift Your Loved Ones | Tune for 2
        </title>
        <meta
          name="description"
          content="Tune for 2: Best custom song service, Offering customised love and sad songs for birthdays| anniversaries| wedding| love proposal & all special moments."
          data-rh="true"
        />
        <link rel="canonical" href="/about-us"></link>
      </Helmet>
      <div className="d-flex flex-column">
        <Navbar createSong={true} />
        <section className="flex-grow-1 bg-color" id="successFailure">
          {loading ? (
            <div className="loader">
              <Spin tip="Loading" size="large"></Spin>
            </div>
          ) : (
            <div className="container">
              <div className="row">
                <div className="pmt_sec col-md-5 mx-auto text-center">
                  <div className="pmt_icon">
                    <img
                      src={status ? check : cross}
                      alt=""
                      className="img-fluid"
                      style={{
                        maxWidth: "100px",
                        background: status
                          ? "rgb(74, 185, 74)"
                          : "rgb(240, 74, 74)",
                      }}
                    />
                    <h4 className="pri-clr mt-4">{"Payment Successful!"}</h4>
                  </div>
                  <div className="pmt_details mt-5">
                    <ul>
                      <li>
                        <p className="lable">Amount</p>
                        <span>₹&nbsp;{paymentformInfo.orderTotal}</span>
                      </li>
                      <li>
                        <p className="lable">Name</p>
                        <span>{paymentformInfo.name}</span>
                      </li>
                      <li>
                        <p className="lable">Email</p>
                        <span>{paymentformInfo.mail}</span>
                      </li>
                    </ul>
                  </div>
                  <div className="fs-16 fw-5 mt-3">
                    We appreciate your song order. You will receive an order
                    confirmation once the composition process begins.
                  </div>
                  <div className="pmt-btn mt-3 mt-md-5">
                    <button
                      className="btn amount-btn px-3 py-2"
                      onClick={() => navigate(navLocation)}
                    >
                      {status ? "Ok" : "Retry"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
      </div>
    </HelmetProvider>
  );
};

export default SuccessFailure;
