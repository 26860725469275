import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./admin.css";
import { MasterBase } from "./Masters/MasterBase";
import { SongStatus } from "./SongStatus/SongStatus";
import { Settings } from "./AdminSettings/Settings";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Payment from "./AdminPayment/Payment";

const AdminBase = () => {
  const navigate = useNavigate();
  // State to track which component should be displayed
  const [activeComponent, setActiveComponent] = useState("songStatus");
  const [demo, setDemo] = useState(localStorage.getItem("token"));
  const [userName, setUserName] = useState(localStorage.getItem("username"));

  // Function to handle button clicks and update activeComponent state
  const handleButtonClick = (component) => {
    setActiveComponent(component);
  };

  const logoutPop = () => {
    Swal.fire({
      title: "Are you sure you want to Logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("accessToken");
        navigate("/login");
      }
    });
  };

  return (
    <>
      <div id="adminBase">
        <header>
          <nav className="navbar px-4">
            <div className="container-fluid">
              <div className="log navbar-brand">
                {/* <Link to={"/"} className="navbar-brand nav-link"> */}
                <img
                  src={require("../../assets/images/logo.png")}
                  alt="Tune for 2"
                  className="img-fluid"
                />
                {/* </Link> */}
              </div>

              {demo !== "1" ? (
                <div className="btn-group d-flex column-gap-2">
                  <button
                    className={`song-status-nav-btn ${
                      activeComponent === "songStatus" ? "active" : ""
                    }`}
                    onClick={() => handleButtonClick("songStatus")}
                  >
                    Song Status
                  </button>
                </div>
              ) : (
                <div className="btn-group d-flex column-gap-2">
                  <button
                    className={`song-status-nav-btn ${
                      activeComponent === "songStatus" ? "active" : ""
                    }`}
                    onClick={() => handleButtonClick("songStatus")}
                  >
                    Song Status
                  </button>
                  <button
                    className={`masters-nav-btn ${
                      activeComponent === "masters" ? "active" : ""
                    }`}
                    onClick={() => handleButtonClick("masters")}
                  >
                    Masters
                  </button>
                  <button
                    className={`settings-nav-btn ${
                      activeComponent === "settings" ? "active" : ""
                    }`}
                    onClick={() => handleButtonClick("settings")}
                  >
                    Settings
                  </button>
                  <button
                    className={`payment-nav-btn ${
                      activeComponent === "payment" ? "active" : ""
                    }`}
                    onClick={() => handleButtonClick("payment")}
                  >
                    Payment
                  </button>
                </div>
              )}

              <div className="nav-options d-flex align-items-center">
                <div className="profile d-flex align-items-center">
                  <div className="profile-pic m-3">
                    <i className="bi bi-person-fill fs-4"></i>
                  </div>
                  <div className="profile-toggle">
                    <p className="m-0 p-0">{userName}</p>
                    {demo === "1" ? (
                      <small className="m-0 p-0">Admin</small>
                    ) : (
                      <small className="m-0 p-0">Co-Admin</small>
                    )}
                  </div>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="dropdown-toggle border-0 bg-transparent"
                      data-bs-toggle="dropdown"
                    ></button>
                    <ul className="dropdown-menu logout_menu logout_data">
                      <li>
                        <div
                          className="dropdown-item logout_menu"
                          onClick={logoutPop}
                        >
                          Logout
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <div className="px-4">
          {/* ====================== Conditional Rendering The SongStatus and Masters Component =================== */}
          {/* Conditional Rendering Based on activeComponent */}
          {activeComponent === "songStatus" ? (
            <SongStatus />
          ) : activeComponent === "masters" ? (
            <MasterBase />
          ) : activeComponent === "settings" ? (
            <Settings />
          ) : activeComponent === "payment" ? (
            <Payment />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default AdminBase;
