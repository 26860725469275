import React, { useEffect, useRef, useState } from "react";

const AudioPlayer = ({ src, isPlaying, onPlay, onPause }) => {
  const audioRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [maxRange, setMaxRange] = useState(0);
  const [isSeeking, setIsSeeking] = useState(false);

  useEffect(() => {
    const audio = audioRef.current;

    const handleLoadedMetadata = () => {
      if (audio.duration === Infinity) {
        calculateDuration(audio);
      } else {
        setDuration(audio.duration);
        setMaxRange(Math.floor(audio.duration));
      }
    };

    const handleTimeUpdate = () => {
      if (!isSeeking) {
        setCurrentTime(audio.currentTime);
      }
      if (audio.currentTime === duration) {
        onPause();
      }
    };

    audio.addEventListener("loadedmetadata", handleLoadedMetadata);
    audio.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      audio.removeEventListener("loadedmetadata", handleLoadedMetadata);
      audio.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [duration, onPause, isSeeking]);

  useEffect(() => {
    const audio = audioRef.current;
    audio.src = src;
  }, [src]);

  useEffect(() => {
    const audio = audioRef.current;
    if (isPlaying) {
      const playPromise = audio.play();
      if (playPromise !== undefined) {
        playPromise.catch((error) => {});
      }
    } else {
      audio.pause();
    }
  }, [isPlaying]);

  const calculateDuration = (audio) => {
    audio.currentTime = 1e101;
    audio.ontimeupdate = () => {
      audio.ontimeupdate = null;
      audio.currentTime = 0;
      setDuration(audio.duration);
      setMaxRange(Math.floor(audio.duration));
    };
  };

  const handleSeekStart = () => {
    setIsSeeking(true);
  };

  const handleSeekEnd = (e) => {
    setIsSeeking(false);
    const newTime = parseFloat(e.target.value);
    if (audioRef.current) {
      setCurrentTime(newTime);
      audioRef.current.currentTime = newTime;
      if (isPlaying) {
        audioRef.current.play().catch((error) => {});
      }
    }
  };

  const changeCurrentTime = (e) => {
    const newTime = parseFloat(e.target.value);
    setCurrentTime(newTime);
    if (audioRef.current) {
      audioRef.current.currentTime = newTime;
    }
  };
  const formatDuration = (totalSeconds) => {
    if (isNaN(totalSeconds) || !isFinite(totalSeconds)) {
      return "00:00";
    }
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = Math.floor(totalSeconds % 60);
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  return (
    <div className="d-flex align-items-center" id="audioPlayer">
      <button onClick={isPlaying ? onPause : onPlay} className="play-btn">
        {isPlaying ? (
          <i className="bi bi-pause-fill pause-icon"></i>
        ) : (
          <i className="bi bi-play-fill play-icon"></i>
        )}
      </button>
      <div className="d-flex align-items-center flex-grow-1">
        <audio ref={audioRef} />
        <input
          type="range"
          min={0}
          max={maxRange}
          value={currentTime}
          onMouseDown={handleSeekStart}
          onMouseUp={handleSeekEnd}
          onChange={changeCurrentTime}
          className="flex-grow-1"
          step="any"
        />
        <span className="duration d-flex">
          {formatDuration(currentTime)} / {formatDuration(duration)}
        </span>
      </div>
    </div>
  );
};

export default AudioPlayer;
