import React, { useState, useEffect, useRef } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import { Button, Popover, Spin } from "antd";
import axios from "axios";
import dayjs from "dayjs";

import { BASE_URL } from "../..";
import { DatePicker, Space, Form } from "antd";
import moment from "moment";
import { set } from "date-fns";
import Footer from "../Base/Footer";
import zIndex from "@mui/material/styles/zIndex";

const UserDetailsTab = ({
  setActiveTab,
  activeTab,
  setUserID,
  userId,
  setAmountID,
  amountId,
  firstPageDate,
  setSecondPageData,
  secondPageData,
  deliveryDateAmount,
  setDeliveryDateAmount,
}) => {
  const [input, setInput] = useState({
    songFor: "",
    songFrom: "",
    occasion: "",
    story: "",
    audioFile: null,
    mixingMaster: "Standard_Mixing_Master",
    delivery: null,
  });
  const [duration, setDuration] = useState(0); // Track duration in seconds
  const [timer, setTimer] = useState(null); // Store timer ID for clearing
  const [audioDuration, setAudioDuration] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [fileName, setFileName] = useState("");
  const [songTo, setSongTo] = useState("");
  const [songFrom, setSongFrom] = useState("");
  const [occasion, setOccasion] = useState("");
  const [story, setStory] = useState("");
  const [record, setRecord] = useState("");
  const [mixing, setMixing] = useState("");
  const [delivery, setDelivery] = useState("");
  const [orderInfo, setOrderInfo] = useState("");
  const [deliveryDate, setDeliveryDate] = useState(new Date());
  const [deliveryAmount, setDeliveryAmount] = useState(0);
  const [mixingAmount, setMixingAmount] = useState(0);
  const [standardAmount, setStandardAmount] = useState(0);
  const [professionalAmount, setProfessionalAmount] = useState(0);
  const [deliveryDuration, setDeliveryDuration] = useState(0);
  const [loading, setLoading] = useState(false);

  const dateformat = "YYYY-MM-DD";
  let dateComponents = dateformat.split("-");
  dateComponents = dateComponents.map((component) => component.toUpperCase());
  let formatedDate = dateComponents.join("-");
  const [form] = Form.useForm();

  // const deliveryDate = input.delivery ? dayjs(input.delivery) : null;
  const [status, setStatus] = useState("idle");
  const [audioBlob, setAudioBlob] = useState(null);
  const [mediaBlobUrl, setMediaBlobUrl] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const audioRef = useRef(null);
  const audio = audioRef.current;
  const [isPlaying, setIsPlaying] = useState(false);
  const [maxRange, setMaxRange] = useState(10);

  const getSupportedMimeType = () => {
    const mimeTypes = [
      "audio/webm; codecs=opus",
      "audio/webm",
      "audio/ogg; codecs=opus",
      "audio/ogg",
      "audio/wav",
      "audio/vnd.wav",
      "audio/mpeg",
      "audio/mp4",
    ];
    for (const mimeType of mimeTypes) {
      if (MediaRecorder.isTypeSupported(mimeType)) {
        return mimeType;
      }
    }
    return "";
  };
  const startRecording = () => {
    setFileName("");
    setAudioDuration(0);
    setErrors((prevErrors) => ({
      ...prevErrors,
      audioFile: "", // Clear the error message for this field
    }));

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const mimeType = getSupportedMimeType();
        if (!mimeType) {
          console.error("No supported MIME type found for MediaRecorder.");
          alert("No supported MIME type found for MediaRecorder.");
          return;
        }

        const options = { mimeType };
        mediaRecorderRef.current = new MediaRecorder(stream, options);

        mediaRecorderRef.current.ondataavailable = (event) => {
          audioChunksRef.current.push(event.data);
        };

        mediaRecorderRef.current.onstop = () => {
          const audioBlob = new Blob(audioChunksRef.current, {
            type: mimeType,
          });
          setAudioBlob(audioBlob);
          const audioUrl = URL.createObjectURL(audioBlob);
          setMediaBlobUrl(audioUrl);
          calculateAudioDuration(audioBlob);
          audioChunksRef.current = [];
        };

        mediaRecorderRef.current.start();
        setStatus("recording");
      })
      .catch((error) => {
        console.error("Error accessing audio devices:", error);
        alert(
          "Could not access audio devices. Please check your device settings and permissions."
        );
      });
  };

  const calculateAudioDuration = (audioBlob) => {
    const audio = new Audio();
    const url = URL.createObjectURL(audioBlob);
    audio.src = url;

    audio.onloadedmetadata = () => {
      if (audio.duration === Infinity) {
        // If duration is still Infinity, try reloading the audio object
        audio.currentTime = 1e101; // Seek to a very large time
        audio.ontimeupdate = () => {
          audio.ontimeupdate = null; // Reset the event listener
          audio.currentTime = 0; // Reset the time
          setDuration(audio.duration);
          setAudioDuration(audio.duration);
          setMaxRange(Math.round(audio.duration));
          URL.revokeObjectURL(url); // Clean up
        };
      } else {
        setDuration(audio.duration);
        URL.revokeObjectURL(url); // Clean up
      }
    };

    audio.onerror = () => {
      console.error("Error loading audio metadata.");
      setDuration(null);
    };
  };
  const changeCurrentTime = (e) => {
    if (audioRef.current) {
      audioRef.current.currentTime = e.target.value;
      setCurrentTime(e.target.value);
    }
  };
  // ==================== For showing Recording Time while recording ==================
  const formatDuration = (totalSeconds) => {
    if (totalSeconds === 600) {
      stopRecording();
    }
    // const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.round(totalSeconds % 60);
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };
  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    setStatus("stopped");
  };

  const pauseRecording = () => {
    mediaRecorderRef.current.pause();
    setStatus("paused");
  };

  const resumeRecording = () => {
    mediaRecorderRef.current.resume();
    setStatus("recording");
    setErrors((prevErrors) => ({
      ...prevErrors,
      audioFile: "", // Clear the error message for this field
    }));
  };

  const clearBlobUrl = () => {
    setMediaBlobUrl(null);
    setStatus("idle");
  };

  // ========================= Audio Play/Pause================================
  const audioPlayPause = () => {
    if (isPlaying) {
      setIsPlaying(false);
      audio.pause();
    } else {
      setIsPlaying(true);
      audio.play();
    }
  };
  const onChange = (date, dateString) => {
    if (dateString !== "") {
      axios
        .get(
          `${BASE_URL}/v1/payment/calculatedeliverypayment?date=${dateString}`
        )
        .then((response) => response.data)
        .then((res_data) => {
          setDeliveryAmount(Math.round(res_data.data.deliveryPayment));
        })
        .catch((error) => {
          console.error("Error fetching delivery payment", error);
        });

      setInput((prevInput) => ({ ...prevInput, delivery: dateString }));
      setErrors((prevErrors) => ({ ...prevErrors, delivery: "" }));
      form.setFieldsValue({ date: date });
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        delivery: "Delivery Date is required",
      }));
    }
  };
  const getAmount = (id) => {
    axios
      .get(BASE_URL + "/v1/orderSummary/" + id)
      .then((response) => response.data)
      .then((res_data) => {
        setDeliveryAmount(res_data.data.orderSummary.deliverydateAmount);
      });
  };
  const getAmountduration = () => {
    axios
      .get(BASE_URL + "/v1/deliveryDate/getDeliveryDate")
      .then((response) => response.data)
      .then((res_data) => {
        setDeliveryDuration(res_data.data.deliveryDate.estimatedDays);
      });
  };
  useEffect(() => {
    if (amountId !== 0) {
      getAmount(amountId);
    }
  }, [amountId]);
  const getMixtureMaster = () => {
    axios
      .get(`${BASE_URL}/v1/mixturemaster`)
      .then((response) => response.data)
      .then((res_data) => {
        for (
          let index = 0;
          index < res_data.data.mixtureMasters.length;
          index++
        ) {
          const element = res_data.data.mixtureMasters[index];
          if (
            res_data.data.mixtureMasters[index].mixingMasterType ===
            "Standard_Mixing_Master"
          ) {
            setStandardAmount(res_data.data.mixtureMasters[index].price);
            setMixingAmount(res_data.data.mixtureMasters[index].price);
            if (secondPageData.mixingMaster === "Standard_Mixing_Master") {
              setMixingAmount(res_data.data.mixtureMasters[index].price);
            }
          } else if (
            res_data.data.mixtureMasters[index].mixingMasterType ===
            "Professional_Mixing_Master"
          ) {
            setProfessionalAmount(res_data.data.mixtureMasters[index].price);
            if (secondPageData.mixingMaster === "Professional_Mixing_Master") {
              setMixingAmount(res_data.data.mixtureMasters[index].price);
            }
          }
        }
      });
  };

  const getOrderData = (id) => {
    axios
      .get(BASE_URL + "/v1/form/" + id)
      .then((response) => response.data)
      .then((res_data) => {
        setOrderInfo(res_data.data.formRequestDto);
      });
  };

  useEffect(() => {
    if (orderInfo && orderInfo.customerDeliveryDate) {
      const newValue = dayjs(orderInfo.customerDeliveryDate);
      form.setFieldsValue({
        date: newValue,
      });
    }
    if (secondPageData && secondPageData.delivery) {
      const newValue = dayjs(secondPageData.delivery);
      form.setFieldsValue({
        date: newValue,
      });
    }
  }, [orderInfo, form, secondPageData]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getMixtureMaster();
    getAmountduration();
  }, []);
  useEffect(() => {
    if (userId !== 0) {
      getOrderData(userId);
    }
  }, [userId]);
  useEffect(() => {
    if (userId !== 0) {
      setInput({
        songFor: orderInfo.songto,
        songFrom: orderInfo.songfrom,
        occasion: orderInfo.occasion,
        story: orderInfo.story,
        audioFile: orderInfo.clipPath,
        delivery: orderInfo.customerDeliveryDate,
        mixingMaster: orderInfo.mixtureMaster,
      });
      if (orderInfo.mixtureMaster === "Standard_Mixing_Master") {
        setMixingAmount(standardAmount);
      } else if (orderInfo.mixtureMaster === "Professional_Mixing_Master") {
        setMixingAmount(professionalAmount);
      }

      setFileName(orderInfo.clipPath);
      setDeliveryDate(orderInfo.customerDeliveryDate);
    }
  }, [orderInfo]);
  useEffect(() => {
    if (userId === 0) {
      setInput({
        songFor: secondPageData.songFor || "",
        songFrom: secondPageData.songFrom || "",
        occasion: secondPageData.occasion || "",
        story: secondPageData.story || "",
        audioFile: secondPageData.audioFile || "",
        delivery: secondPageData.delivery || "",
        mixingMaster: secondPageData.mixingMaster || "Standard_Mixing_Master",
      });
      // setDeliveryDate(secondPageData.delivery);
      // onChange(secondPageData.delivery);
      setFileName(
        secondPageData?.audioFile
          ? "recorded audio"
          : secondPageData?.file
          ? secondPageData.file
          : ""
      );
      setDeliveryAmount(deliveryDateAmount);
    }
  }, [userId, secondPageData]);

  const getFields = () => {
    const idsToDelete = [];
    axios
      .get(BASE_URL + "/v1/Filed/")
      .then((response) => response.data)
      .then((res_data) => {
        for (let index = 0; index < res_data.data.fileds.length; index++) {
          const element = res_data.data.fileds[index];
          if (res_data.data.fileds[index].name === "Who is the song to ?") {
            setSongTo(res_data.data.fileds[index].isMandatory);
          } else if (
            res_data.data.fileds[index].name === "Who is the song  from ?"
          ) {
            setSongFrom(res_data.data.fileds[index].isMandatory);
          } else if (
            res_data.data.fileds[index].name === "What is the Occasion ?"
          ) {
            setOccasion(res_data.data.fileds[index].isMandatory);
          } else if (
            res_data.data.fileds[index].name === "Tell us your story"
          ) {
            setStory(res_data.data.fileds[index].isMandatory);
          } else if (
            res_data.data.fileds[index].name === "Record your story audio"
          ) {
            setRecord(res_data.data.fileds[index].isMandatory);
          } else if (
            res_data.data.fileds[index].name === "Select an mixing mastering"
          ) {
            setMixing(res_data.data.fileds[index].isMandatory);
          } else if (res_data.data.fileds[index].name === "Song Delivery") {
            setDelivery(res_data.data.fileds[index].isMandatory);
          }
        }
      });
  };

  const handleInput = (e) => {
    let errorMessage = "";
    if (e.target.name === "story") {
      if (e.target.value.length >= 750) {
        errorMessage = "Story cannot exceed 750 characters";
      }
    }

    if (e.target.value === "Standard_Mixing_Master") {
      setMixingAmount(standardAmount);
    } else if (e.target.value === "Professional_Mixing_Master") {
      setMixingAmount(professionalAmount);
    }

    if (e.target.name === "audioFile") {
      const file = e.target.files[0];
      if (file) {
        setFileName(file.name); // Update the file name state
      }
    } else {
      setInput({ ...input, [e.target.name]: e.target.value });
    }
    const { name, value } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage, // Clear the error message for this field
    }));

    setInput((prevInput) => ({
      ...prevInput,
      [name]: value,
      audioFile: audioBlob,
    }));

    // let errorMessage = "";
  };

  const backFunc = (e) => {
    const data = {
      songFor: input?.songFor,
      songFrom: input?.songFrom,
      occasion: input?.occasion,
      story: input?.story,
      file: fileName,
      audioFile: input?.audioFile,
      mixingMaster: input?.mixingMaster,
      delivery: input?.delivery,
    };

    setDeliveryDateAmount(deliveryAmount);
    // setSecondPageData(data);

    setActiveTab(0);
  };
  const [errors, setErrors] = useState({});

  const handleSubmit = async (e, blobUrl) => {
    e.preventDefault();
    const errors = {};
    let audioFile = null;

    if (blobUrl && blobUrl !== "undefined") {
      const response = await fetch(blobUrl);
      const blob = await response.blob();
      audioFile = new File([audioBlob], "audio.mp3", { type: "audio/mp3" });
    } else if (status === "paused") {
      errors.audioFile = "Audio is in pause mode Please stop it";
    } else {
      const chosenFile = document.getElementById("audioFile").files[0];
      if (chosenFile) {
        audioFile = new File([chosenFile], chosenFile.name, {
          type: chosenFile.type,
        });
      } else {
        errors.audioFile = "Audio is required";
      }
    }

    // Converting Bolob URL into blob
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    const audio_rec_file = new File([audioBlob], "audio.mp3", {
      type: "audio/mp3",
    });

    if (!input.story) {
      if (story === "Mandatory") {
        errors.story = "story is required";
      }
    }

    if (!input.songFor) {
      if (songTo === "Mandatory") {
        errors.songFor = "Song For is required";
      }
    }

    //Validate songFrom field
    if (!input.songFrom) {
      if (songFrom === "Mandatory") {
        errors.songFrom = "Song From is required";
      }
    }
    //Validate songFrom field
    if (!input.occasion) {
      if (occasion === "Mandatory") {
        errors.occasion = "Occasion is required";
      }
    }

    //Validate occasion field
    if (!input.mixingMaster) {
      if (mixing === "Mandatory") {
        errors.mixingMaster = "Mixing Master is required";
      }
    }

    if (!input.delivery) {
      errors.delivery = "Delivery Date is required";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    setLoading(true);
    const data = {
      songFor: input?.songFor,
      songFrom: input?.songFrom,
      occasion: input?.occasion,
      story: input?.story,
      audioFile: input?.audioFile,
      mixingMaster: input?.mixingMaster,
      delivery: input?.delivery,
    };

    const formData = new FormData();
    formData.append("customerDeliveryDate", input?.delivery);
    formData.append("lanuage", firstPageDate?.language);
    formData.append("occasion", input?.occasion);
    formData.append("artist", firstPageDate?.gender);
    formData.append("instrument", firstPageDate?.instrument);
    formData.append("name", firstPageDate?.name);
    formData.append("masterRequest", input?.mixingMaster);
    formData.append("songto", input?.songFor);
    formData.append(
      "orderTotal",
      firstPageDate?.languageAmount +
        firstPageDate?.instrumentAmount +
        firstPageDate?.artistAmount +
        mixingAmount +
        deliveryAmount
    );

    // formData.append("clip", document.getElementById("audioFile").files[0]);

    if (blobUrl && blobUrl !== "undefined") {
      formData.append("clip", audio_rec_file, "audio.mp3");
    } else {
      const chosenFile = document.getElementById("audioFile").files[0];
      const chosenFileAsFileObject = new File([chosenFile], chosenFile?.name, {
        type: chosenFile?.type,
      });
      formData.append(
        "clip",
        chosenFileAsFileObject,
        chosenFileAsFileObject.name
      );
    }

    formData.append("story", input?.story);
    formData.append("mail", firstPageDate?.email);
    formData.append("phonenumber", firstPageDate?.number);
    formData.append("mood", firstPageDate?.songMood);
    formData.append("songfrom", input?.songFrom);
    // formData.append("songfrom", languageAmount);
    // formData.append("songfrom", instrumentAmount);

    try {
      const response = await axios.post(BASE_URL + "/v1/form", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const parts = response.data.message.split(" : ");
      const songId = parts[1];

      setUserID(songId);
      const form_Data = new FormData();
      form_Data.append("languageAmount", firstPageDate?.languageAmount);
      form_Data.append("versionAmount", firstPageDate?.instrumentAmount);
      form_Data.append("artistAmount", firstPageDate?.artistAmount);
      form_Data.append("masterAmount", mixingAmount);
      form_Data.append("deliverydateAmount", deliveryAmount);

      try {
        const response = await axios.post(
          BASE_URL + "/v1/orderSummary",
          form_Data
        );

        const parts2 = response.data?.message.split(" : ");

        const amountId = parts2[1]; // This removes any leading or trailing spaces

        setAmountID(amountId);
        setLoading(false);
        // setActiveTab(2);
      } catch (error) {
        console.error("Error creating post:", error);
        setLoading(false);
      }

      setActiveTab(2);
    } catch (error) {
      console.error("Error creating post:", error);
      setLoading(false);
    }
  };
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };

  const handleEditSubmit = async (e, blobUrl) => {
    e.preventDefault();
    // setLoading(true);
    const errors = {};

    let audioFile = null;
    if (blobUrl && blobUrl !== "undefined") {
      const response = await fetch(blobUrl);
      const blob = await response.blob();
      audioFile = new File([blob], "audio.mp3", { type: "audio/mp3" });
    } else if (status === "paused") {
      errors.audioFile = "Audio is in pause mode Please stop it";
    } else {
      const chosenFile = document.getElementById("audioFile").files[0];
      if (chosenFile) {
        audioFile = new File([chosenFile], chosenFile.name, {
          type: chosenFile.type,
        });
      } else {
        if (userId === 0) {
          errors.audio = "Audio is required";
        }
      }
    }
    let audio_rec_file;
    if (blobUrl !== null) {
      const response = await fetch(blobUrl);
      const blob = await response.blob();
      audio_rec_file = new File([blob], "audio.mp3", {
        type: "audio/mp3",
      });
    }

    if (!input.songFor) {
      if (songTo === "Mandatory") {
        errors.songFor = "Song For is required";
      }
    }

    //Validate songFrom field
    if (!input.songFrom) {
      if (songFrom === "Mandatory") {
        errors.songFrom = "Song From is required";
      }
    }
    if (!input.occasion) {
      if (occasion === "Mandatory") {
        errors.occasion = "Occasion is required";
      }
    }
    //Validate occasion field
    if (!input.mixingMaster) {
      if (mixing === "Mandatory") {
        errors.mixingMaster = "Mixing Master is required";
      }
    }
    if (!input.story) {
      if (story === "Mandatory") {
        errors.story = "story is required";
      }
    }
    if (!input.delivery) {
      errors.delivery = "Delivery Date is required";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    const formData = new FormData();
    formData.append("customerDeliveryDate", input?.delivery);
    formData.append("lanuage", firstPageDate?.language);
    formData.append("occasion", input?.occasion);
    formData.append("artist", firstPageDate?.gender);
    formData.append("instrument", firstPageDate?.instrument);
    formData.append("name", firstPageDate?.name);
    formData.append("masterRequest", input?.mixingMaster);
    formData.append("songto", input?.songFor);
    formData.append(
      "orderTotal",
      firstPageDate?.languageAmount +
        firstPageDate?.instrumentAmount +
        firstPageDate?.artistAmount +
        mixingAmount +
        deliveryAmount
    );

    if (blobUrl && blobUrl !== "undefined") {
      formData.append("clip", audio_rec_file, "audio.mp3");
    } else {
      const chosenFile = document.getElementById("audioFile").files[0];
      if (chosenFile && chosenFile !== "undefined") {
        const chosenFileAsFileObject = new File([chosenFile], chosenFile.name, {
          type: chosenFile.type,
        });
        formData.append(
          "clip",
          chosenFileAsFileObject,
          chosenFileAsFileObject.name
        );
      }
    }

    // formData.append("clip", document.getElementById("audioFile").files[0]);
    formData.append("story", input.story);
    formData.append("mail", firstPageDate.email);
    formData.append("phonenumber", firstPageDate.number);
    // formData.append("mood", firstPageDate.songMood);
    formData.append("songfrom", input.songFrom);
    // formData.append("deliveryRequest", orderInfo.deliveryRequest);
    // formData.append("orderPosition", orderInfo);
    axios
      .put(BASE_URL + "/v1/form/form-update/" + userId, formData)
      .then(() => {
        // fetchData();
        setLoading(false);
        setActiveTab(2);
        let data = {
          params: {
            id: amountId,
            languageAmount: firstPageDate.languageAmount,
            versionAmount: firstPageDate.instrumentAmount,
            artistAmount: firstPageDate.artistAmount,
            masterAmount: mixingAmount,
            deliverydateAmount: deliveryAmount,
          },
        };

        try {
          const response = axios
            .put(
              BASE_URL + "/v1/orderSummary/orderSummary/" + amountId,
              null,
              data
            )
            .then(() => setLoading(false));
        } catch (error) {
          // console.error("Error creating post:", error);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error saving data:", error);
      });
  };
  // =========== Retrieve stored data form session storage
  useEffect(() => {
    getFields();
  }, []);
  //*************************  Pop up
  const songToInfo = (
    <div>
      <p>
        Please provide the name or relationship of the person or people the song
        is dedicated to. This helps us personalize the lyrics and theme of the
        song to reflect your intended recipient.
      </p>
    </div>
  );
  const songFromInfo = (
    <div>
      <p>
        Enter your name or the names of the individuals who are dedicating the
        song. This information will be included in the song's credits and helps
        personalize the message.
      </p>
    </div>
  );
  const occasionInfo = (
    <div>
      <p>
        Tell us the special occasion or reason for creating this song (e.g.,
        anniversary, birthday, proposal). This helps us capture the appropriate
        emotions and tailor the song to suit the event.
      </p>
    </div>
  );
  const standandInfo = (
    <div>
      <p>
        Our Standard Mixing & Mastering service ensures that your customised
        song receives professional-level sound quality. This includes basic
        adjustments to levels, EQ, and compression to achieve a balanced and
        polished sound.
      </p>
    </div>
  );
  const professionalInfo = (
    <div>
      <p>
        Our Professional Mixing & Mastering service takes your customised song
        to the next level with meticulous attention to detail. This includes
        advanced techniques in mixing and mastering to enhance clarity,
        dynamics, and overall sonic impact.
      </p>
    </div>
  );
  const contentAudio = (
    <div>
      <p>
        The recorded audio duration should be
        <br /> less than 10 Minutes !!! "If you have an audio file or recording
        that captures the essence of your story or message, you can upload it
        here. This optional feature allows us to better understand your vision
        and create a more personalized song.
      </p>
    </div>
  );
  const contentDeliveryDuration = (
    <div>
      <p>
        If the requested delivery date is <br /> less than "{deliveryDuration}"
        days, delivery charges apply <br /> over the current order total !
        Select the exact date by which you would like to receive your customised
        song. We are committed to delivering on the date chosen here, ensuring
        your special moment is celebrated right on time.
      </p>
    </div>
  );

  //
  useEffect(() => {
    if (status === "recording") {
      const newTimer = setInterval(() => {
        setDuration((prevDuration) => prevDuration + 1);
      }, 1000);
      setTimer(newTimer);
    } else if (status === "stopped" || status === "idle") {
      clearInterval(timer);
      setDuration(0); // Reset duration when stopped or reset
    } else if (status === "paused") {
      clearInterval(timer);
    } else if (status === "resumed") {
      const newTimer = setInterval(() => {
        setDuration((prevDuration) => prevDuration + 1);
      }, 1000);
      setTimer(newTimer);
    }

    return () => {
      clearInterval(timer); // Make sure to clear timer on component unmount
    };
  }, [status]); // Effect runs on change of status

  const handleKeyPressName = (e) => {
    const charCode = e.charCode;
    if (!/[a-zA-Z. ]/.test(String.fromCharCode(charCode))) {
      e.preventDefault();
    }
  };

  // =============== Remove Price tag Function ====================
  const handleRemoveField = (fieldName) => {
    setInput((prevInput) => ({
      ...prevInput,
      [fieldName]: "",
    }));
    if (fieldName === "mixingMaster") {
      setMixingAmount(0);
    }

    if (fieldName === "delivery") {
      form.resetFields(["date"]);
      setDeliveryAmount(0); // Reset delivery amount when date is removed
    }
  };

  const getDefaultDate = () => {
    const dateFromInput = input.delivery;
    const dateFromOrderInfo = orderInfo.deliveryDate;

    const defaultDate = moment(
      dateFromInput || dateFromOrderInfo,
      "YYYY-MM-DD",
      true
    ).isValid()
      ? moment(dateFromInput || dateFromOrderInfo, "YYYY-MM-DD")
      : null;

    return defaultDate;
  };

  return (
    <>
      <Spin
        spinning={loading}
        fullscreen
        className="custom-spin fixed-top"
        size="large"
        tip="Loading"
      />
      <section className="section1 bg-color DM_Sans">
        {activeTab === 1 && (
          <div>
            <div className="container-fluid">
              <div className="Tab-shape1 d-none d-md-block">
                <img src={require("../../assets/images/shape1.png")} alt="" />
              </div>
              <div className="Tab-shape2 d-none d-md-block">
                <img src={require("../../assets/images/shape2.png")} alt="" />
              </div>
              {/* <div className="dsf ms-xl-5 ms-lg-5 ms-md-3">
                <h2 className="mt-5 c_F77B21 fs-36 fw-6 ps-3">The Basics </h2>
                <p className="basic_para my-2 my-md-2 c_9F9F9F fst-italic ps-3">
                  Give your artist a quick overview to begin (details on the
                  next page).
                </p>
              </div> */}
            </div>

            <div className="container-fluid">
              <div className="row  ms-xl-5 ms-lg-5 ms-md-2">
                <div className="col-xl-8 col-lg-10 col-md-12">
                  <form
                    action=""
                    // onSubmit={handleSubmit}
                    onSubmit={
                      userId != 0
                        ? (e) => handleEditSubmit(e, mediaBlobUrl)
                        : (e) => handleSubmit(e, mediaBlobUrl)
                    }
                    className="row g-3 my-1"
                  >
                    {/* <div className="col-md-4 px-3">
                      <label htmlFor="SongFor" className="form-label fw-5">
                        Who is the song to?{" "}
                        {songTo === "Mandatory" && (
                          <span className="text-danger">*</span>
                        )}
                        <Popover content={songToInfo} placement="bottomRight">
                          <Button type="button" className="p-1">
                            <i className="bi bi-info-circle c_575757 hover-info"></i>
                          </Button>
                        </Popover>
                      </label>
                      <input
                        type="text"
                        className="form-control form-input"
                        name="songFor"
                        placeholder="Enter Relationship"
                        id="SongFor"
                        onChange={handleInput}
                        onKeyPress={handleKeyPressName}
                        value={input.songFor}
                      />
                      {errors.songFor && (
                        <div className="text-danger">{errors.songFor}</div>
                      )}
                    </div>
                    <div className="col-md-4 px-3">
                      <label htmlFor="SongFrom" className="form-label fw-5">
                        Who is the song from?{" "}
                        {songFrom === "Mandatory" && (
                          <span className="text-danger">*</span>
                        )}
                        <Popover content={songFromInfo} placement="bottomRight">
                          <Button type="button" className="p-1">
                            <i className="bi bi-info-circle c_575757 hover-info"></i>
                          </Button>
                        </Popover>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="songFrom"
                        placeholder="Enter Relationship"
                        onChange={handleInput}
                        onKeyPress={handleKeyPressName}
                        value={input.songFrom}
                      />
                      {errors.songFrom && (
                        <div className="text-danger">{errors.songFrom}</div>
                      )}
                    </div>
                    <div className="col-md-4 px-3">
                      <label htmlFor="Occasion" className="form-label fw-5">
                        What is the Occasion?{" "}
                        {occasion === "Mandatory" && (
                          <span className="text-danger">*</span>
                        )}
                        <Popover content={occasionInfo} placement="bottomRight">
                          <Button type="button" className="p-1">
                            <i className="bi bi-info-circle c_575757 hover-info"></i>
                          </Button>
                        </Popover>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="occasion"
                        placeholder="Enter Occasion"
                        onChange={handleInput}
                        value={input.occasion}
                      />
                      {errors.occasion && (
                        <div className="text-danger">{errors.occasion}</div>
                      )}
                    </div> */}
                    <div className="col-lg-10">
                      <h4 className="fs-22 c_F77B21 fw-6 py-3 pb-2 py-md-4">
                        Tell us your story{" "}
                        {story === "Mandatory" && (
                          <span className="text-danger">*</span>
                        )}
                        {/* <Popover content={content} placement="bottomRight">
                          <Button type="button" className="p-1">
                            <i className="bi bi-info-circle c_575757 hover-info"></i>
                          </Button>
                        </Popover> */}
                      </h4>
                      <p className="fst-italic c_9F9F9F fs-17">
                        Unleash your creativity and share all your details for a
                        personalized song crafted from scratch, including your
                        own lyrics (keep it under 60 words per minute for audio
                        recording, 750 letters for written story), but remember
                        the artist can only use what you provide.
                      </p>
                    </div>
                    <div className="row py-3">
                      <div className="col-12 col-sm-7">
                        <textarea
                          className="form-control"
                          name="story"
                          placeholder="Start to write your story here."
                          onChange={handleInput}
                          value={input.story}
                          rows="5"
                          maxLength={750}
                          style={{
                            resize: "none",
                            overflow: "auto",
                          }}
                        />
                        {errors.story && (
                          <div className="text-danger">{errors.story}</div>
                        )}
                      </div>

                      <div className="col-12 mt-4 mt-sm-0 col-sm-5">
                        <p className="fw-5">
                          Upload your story audio
                          <span className="text-danger">*</span>
                          <Popover
                            content={contentAudio}
                            placement="bottomRight"
                          >
                            <Button type="button" className="p-1" tabIndex={-1}>
                              <i className="bi bi-info-circle c_575757 hover-info"></i>
                            </Button>
                          </Popover>
                        </p>
                        <div className="audio-file-data tab-2 d-flex justify-content-between">
                          {status !== "stopped" && (
                            <div className="content d-flex align-items-center file-name">
                              {status === "idle" && (
                                <p className="mb-0 ps-2 RecfileName">
                                  {fileName ? fileName : " Choose a file"}
                                </p>
                              )}
                              {status !== "idle" && status !== "stopped" && (
                                <p className="mb-0 ps-2 recDuration">
                                  {formatDuration(duration)}
                                </p>
                              )}
                            </div>
                          )}

                          <div className="controls flex-grow-1">
                            <div
                              id="recordAudio"
                              className="d-flex justify-content-end flex-grow-1"
                            >
                              {status === "stopped" && mediaBlobUrl && (
                                <>
                                  <audio
                                    ref={audioRef}
                                    autoPlay
                                    onPlay={() => setIsPlaying(true)}
                                    onEnded={() => setIsPlaying(false)}
                                    onTimeUpdate={() =>
                                      setCurrentTime(
                                        audioRef.current.currentTime
                                      )
                                    }
                                  >
                                    <source
                                      src={mediaBlobUrl}
                                      type="audio/webm"
                                    />
                                    <source
                                      src={mediaBlobUrl}
                                      type="audio/ogg"
                                    />
                                    <source
                                      src={mediaBlobUrl}
                                      type="audio/mp3"
                                    />
                                    Your browser does not support the audio
                                    element.
                                  </audio>
                                  <div className="d-flex align-items-center flex-grow-1">
                                    <button
                                      type="button"
                                      onClick={audioPlayPause}
                                      className="startRecord"
                                    >
                                      {isPlaying ? (
                                        <i className="bi bi-pause-fill pause-icon"></i>
                                      ) : (
                                        <i className="bi bi-play-fill play-icon"></i>
                                      )}
                                    </button>
                                    <div className="d-flex align-items-center flex-grow-1">
                                      <input
                                        type="range"
                                        min={0}
                                        max={maxRange}
                                        value={currentTime}
                                        onChange={changeCurrentTime}
                                        className="flex-grow-1"
                                      />
                                      <span className="duration d-flex ms-1 mono-space">
                                        {isPlaying
                                          ? formatDuration(currentTime)
                                          : formatDuration(duration)}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )}
                              {status === "recording" && (
                                <button
                                  onClick={stopRecording}
                                  title="Stop Recording"
                                >
                                  <i className="bi bi-stop-circle"></i>
                                </button>
                              )}
                              {status === "idle" && (
                                <button
                                  type="button"
                                  onClick={startRecording}
                                  className="startRecord"
                                  title="Start Recording"
                                >
                                  <i className="bi bi-mic-fill"></i>
                                </button>
                              )}
                              {status === "stopped" && (
                                <button
                                  onClick={clearBlobUrl}
                                  className="startRecord"
                                >
                                  <i className="bi bi-x-circle-fill"></i>
                                </button>
                              )}
                              {status === "recording" && (
                                <button
                                  onClick={pauseRecording}
                                  title="Pause Recording"
                                >
                                  <i className="bi bi-pause-circle"></i>
                                </button>
                              )}
                              {status === "paused" && (
                                <button
                                  onClick={resumeRecording}
                                  title="Resume Recording"
                                >
                                  <i className="bi bi-play-circle"></i>
                                </button>
                              )}
                              {status === "idle" && status !== "stopped" && (
                                <button type="button">
                                  <div className="audio-file m-0">
                                    <label
                                      htmlFor="audioFile"
                                      className="form-label fw-5 choose-file  mt-2 m-md-0"
                                    >
                                      {/* Choose File */}
                                      <input
                                        type="file"
                                        id="audioFile"
                                        name="audioFile"
                                        onChange={handleInput}
                                        accept="audio/mp3,audio/wav"
                                        style={{ display: "none" }}
                                      />
                                      <i className="bi bi-paperclip audio-file"></i>
                                    </label>
                                  </div>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                        {errors.audioFile && (
                          <div className="text-danger">{errors.audioFile}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4 px-3">
                      <label htmlFor="SongFor" className="form-label fw-5">
                        Who is the song to?{" "}
                        {songTo === "Mandatory" && (
                          <span className="text-danger">*</span>
                        )}
                        <Popover content={songToInfo} placement="bottomRight">
                          <Button type="button" className="p-1" tabIndex={-1}>
                            <i className="bi bi-info-circle c_575757 hover-info"></i>
                          </Button>
                        </Popover>
                      </label>
                      <input
                        type="text"
                        className="form-control form-input"
                        name="songFor"
                        placeholder="Enter Relationship"
                        id="SongFor"
                        onChange={handleInput}
                        onKeyPress={handleKeyPressName}
                        value={input.songFor}
                      />
                      {errors.songFor && (
                        <div className="text-danger">{errors.songFor}</div>
                      )}
                    </div>
                    <div className="col-md-4 px-3">
                      <label htmlFor="SongFrom" className="form-label fw-5">
                        Who is the song from?{" "}
                        {songFrom === "Mandatory" && (
                          <span className="text-danger">*</span>
                        )}
                        <Popover content={songFromInfo} placement="bottomRight">
                          <Button type="button" className="p-1" tabIndex={-1}>
                            <i className="bi bi-info-circle c_575757 hover-info"></i>
                          </Button>
                        </Popover>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="songFrom"
                        placeholder="Enter Relationship"
                        onChange={handleInput}
                        onKeyPress={handleKeyPressName}
                        value={input.songFrom}
                      />
                      {errors.songFrom && (
                        <div className="text-danger">{errors.songFrom}</div>
                      )}
                    </div>
                    <div className="col-md-4 px-3 pb-4">
                      <label htmlFor="Occasion" className="form-label fw-5">
                        What is the Occasion?{" "}
                        {occasion === "Mandatory" && (
                          <span className="text-danger">*</span>
                        )}
                        <Popover content={occasionInfo} placement="bottomRight">
                          <Button type="button" className="p-1" tabIndex={-1}>
                            <i className="bi bi-info-circle c_575757 hover-info"></i>
                          </Button>
                        </Popover>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="occasion"
                        placeholder="Enter Occasion"
                        onChange={handleInput}
                        value={input.occasion}
                      />
                      {errors.occasion && (
                        <div className="text-danger">{errors.occasion}</div>
                      )}
                    </div>
                    {/* ===================== Mixing master Radio buttons ================== */}
                    <div className="col-md-10 ">
                      <h4 className="fs-22 c_F77B21 fw-6 pb-2">
                        Mixing & Mastering Level
                        {mixing === "Mandatory" && (
                          <span className="text-danger">*</span>
                        )}
                      </h4>
                      <div className="d-flex flex-column flex-sm-row py-3 w-100 gap-3">
                        <div className="radio-buttons-container flex-column flex-sm-row align-items-start">
                          <div className="checkbox-wrapper-26">
                            <input
                              type="radio"
                              name="mixingMaster"
                              id="standard"
                              value="Standard_Mixing_Master"
                              onChange={handleInput}
                              className="form-check-input"
                              checked={
                                input.mixingMaster === "Standard_Mixing_Master"
                              }
                            />
                            <label htmlFor="standard">
                              <div className="tick_mark"></div>
                            </label>
                            <span className="mt-2 mt-md-0">
                              Standard Mixing & Mastering
                              <Popover
                                content={standandInfo}
                                placement="bottomRight"
                              >
                                <Button
                                  type="button"
                                  className="p-1"
                                  tabIndex={-1}
                                >
                                  <i className="bi bi-info-circle c_575757 hover-info"></i>
                                </Button>
                              </Popover>
                            </span>
                          </div>
                          <div className="checkbox-wrapper-26">
                            <input
                              type="radio"
                              name="mixingMaster"
                              id="professional"
                              value="Professional_Mixing_Master"
                              onChange={handleInput}
                              className="form-check-input"
                              checked={
                                input.mixingMaster ===
                                "Professional_Mixing_Master"
                              }
                            />
                            <label htmlFor="professional">
                              <div className="tick_mark"></div>
                            </label>
                            <span className="mt-2 mt-md-0">
                              Professional Mixing & Mastering
                              <Popover
                                content={professionalInfo}
                                placement="bottomRight"
                              >
                                <Button
                                  type="button"
                                  className="p-1"
                                  tabIndex={-1}
                                >
                                  <i className="bi bi-info-circle c_575757 hover-info"></i>
                                </Button>
                              </Popover>
                            </span>
                          </div>
                        </div>
                      </div>
                      {errors.mixingMaster && (
                        <div className="text-danger">{errors.mixingMaster}</div>
                      )}
                    </div>
                    <div className="col-md-8">
                      <h4 className="fs-22 c_F77B21 fw-6 pb-2">
                        Delivery Date
                        <span className="text-danger">*</span>
                        <Popover
                          content={contentDeliveryDuration}
                          placement="bottomRight"
                        >
                          <Button type="button" className="p-1" tabIndex={-1}>
                            <i className="bi bi-info-circle c_575757 hover-info"></i>
                          </Button>
                        </Popover>
                      </h4>

                      <div className="d-flex flex-column flex-sm-row py-3 w-100 gap-3">
                        <Form form={form}>
                          {/* <h6>Delivery Date</h6> */}
                          <div className="d-flex flex-column flex-sm-row w-100 gap-3">
                            <Form.Item
                              name="date"
                              validateStatus={errors.delivery ? "error" : ""}
                              help={errors.delivery}
                            >
                              <DatePicker
                                onChange={onChange}
                                disabledDate={disabledDate}
                                allowClear={false}
                                format={formatedDate}
                              />
                            </Form.Item>
                          </div>
                        </Form>
                      </div>
                    </div>
                    <div
                      className="row pay-card-tab ms-2 d-none d-sm-block ps-0"
                      style={{ zIndex: 9 }}
                    >
                      <div className="col-md-12 col-12 d-flex align-items-center py-3 bg-fff">
                        <div className="d-flex w-100 align-items-center justify-content-between gap-5">
                          <div className="d-flex align-items-center">
                            <img
                              src={require("../../assets/images/audio-img.png")}
                              alt=""
                              className="img-fluid audio-img pt-0"
                            />
                            {/* ================== */}
                            <div className="d-flex">
                              <p className="mb-0 c_575757 ms-2">
                                Price Details
                              </p>
                              <div className="priceCard c_F77B21 d-flex fw-7 gap-2 rounded">
                                Previous Total - ₹
                                {firstPageDate?.languageAmount +
                                  firstPageDate?.instrumentAmount +
                                  firstPageDate?.artistAmount}
                              </div>
                              {Object.keys(input).map((key) => {
                                let displayValue = input[key];
                                if (
                                  [
                                    "songFor",
                                    "songFrom",
                                    "occasion",
                                    "story",
                                    "audioFile",
                                  ].includes(key) ||
                                  !input[key]
                                ) {
                                  return null; // Skip these keys
                                } else if ("deliverDate") {
                                  displayValue = `${input[key]} - ₹${deliveryAmount}`;
                                }

                                if (input[key] === "Standard_Mixing_Master") {
                                  displayValue = `${input[key]} - ₹${mixingAmount}`;
                                } else if (
                                  input[key] === "Professional_Mixing_Master"
                                ) {
                                  displayValue = `${input[key]} - ₹${mixingAmount}`;
                                }
                                return (
                                  <div
                                    key={key}
                                    className="priceCard c_F77B21 d-flex fw-7 gap-2 rounded"
                                  >
                                    {displayValue}
                                    <i
                                      className="bi bi-x-circle-fill c_F77B21"
                                      onClick={() => handleRemoveField(key)}
                                    ></i>
                                  </div>
                                );
                              })}
                            </div>

                            {/* ================== */}
                          </div>
                          <div className="callme_btn d-flex p-0">
                            <div className="amount-btn c_#FFFFFF fw-5 p-3">
                              Total:{"₹"}
                              {parseInt(mixingAmount || 0) +
                                parseInt(deliveryAmount || 0) +
                                parseInt(firstPageDate?.languageAmount || 0) +
                                parseInt(firstPageDate?.instrumentAmount || 0) +
                                parseInt(firstPageDate?.artistAmount || 0)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-button d-none d-sm-flex mb-5 ms-3 ms-md-2 ps-0">
                      {/* <div className="">
                        <button
                          type="button"
                          className="back-btn fw-6"
                          onClick={backFunc}
                        >
                          Back
                        </button>
                      </div> */}
                      <div className="">
                        <button
                          type="submit"
                          className="continue-btn mr-4 fw-6"
                        >
                          Continue
                        </button>
                      </div>
                    </div>

                    <div className="tab-nav-btn d-sm-none sticky-bottom bg-color">
                      <div className="row d-flex justify-content-between">
                        <div className="col-4 text-center p-2">
                          <span className="h3 fw-bold c_F77B21 ">
                            {"₹"}
                            {parseInt(mixingAmount || 0) +
                              parseInt(deliveryAmount || 0) +
                              parseInt(firstPageDate?.languageAmount || 0) +
                              parseInt(firstPageDate?.instrumentAmount || 0) +
                              parseInt(firstPageDate?.artistAmount || 0)}
                          </span>
                        </div>
                        {/* <div className="col-4 p-2">
                          <button
                            className="back-btn-new fw-6"
                            type="button"
                            onClick={backFunc}
                          >
                            Back
                          </button>
                        </div> */}
                        <div className="col-4 p-2">
                          <button
                            type="submit"
                            className="continue-btn-new fw-6"
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-4 d-none d-lg-block overflow-hidden">
                  <div className="basic-bg">
                    <img
                      src={require("../../assets/images/TuneFor2Img/customised-proposal-song.webp")}
                      alt="customised proposal song"
                      title="customised proposal song"
                      className="tab2-img img-fluid"
                    />
                  </div>
                  <div className="Tab-shape3">
                    <img
                      src={require("../../assets/images/shape3.png")}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
      <Footer />
    </>
  );
};

export default UserDetailsTab;
