import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Drawer,
  Input,
  message,
  Popconfirm,
  Switch,
} from "antd";
import axios from "axios";
import { BASE_URL } from "../../..";
import { Masterdata } from "./MasterData";

export const MixingDelivery = () => {
  const [languageData, setLanguageData] = useState(Masterdata.language);
  const [editedId, setEditedId] = useState("");
  const [editLanguageData, setEditLanguageData] = useState({
    name: "",
    price: "",
  });
  const [editID, setEditID] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState("Add New Language");
  const [drawerButton, setDrawerButton] = useState("Add Language");
  const [tableData, setTableData] = useState([]);
  const [dateData, setDateData] = useState([]);
  const [tableDateData, setTableDateData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [statusActive, setStatusActive] = useState("");
  const [input, setInput] = useState({ name: "", price: "" });
  const [inputDate, setInputDate] = useState({
    days: "",
    price: "",
    decrement: "",
  });
  const [open, setOpen] = useState(false);
  const [newopen, setNewOpen] = useState(false);
  const [newopen1, setNewOpen1] = useState(false);

  const [editopen, setEditopen] = useState(false);
  const [errors, setErrors] = useState({});
  const fetchData = () => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(`${BASE_URL}/v1/mixturemaster`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // params: data.params,
        // responseType: "arraybuffer",
      })
      .then((response) => response.data)
      .then((res_data) => {
        setTableData(res_data.data.mixtureMasters);
      });
  };

  const fetchDateValue = () => {
    const token = localStorage.getItem("accessToken");
    axios
      .get(`${BASE_URL}/v1/deliveryDate/getDeliveryDate`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // params: data.params,
        // responseType: "arraybuffer",
      })
      .then((response) => response.data)
      .then((res_data) => res_data.data.deliveryDate)
      .then((res) => {
        setDateData([{ ...res, key: 1, deliveryDate: "Delivery Date" }]);

        setInputDate({
          days: res.estimatedDays,
          price: res.priceForTotalDays,
          decrement: res.pricePerDay,
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchData();
    fetchDateValue();
  }, []);

  const onClose = () => {
    setNewOpen(false);
    setNewOpen1(false);
    setEditopen(false);
    setErrors({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};

    if (!inputDate.days) {
      errors.days = "Days is required";
    }
    if (!inputDate.price) {
      errors.price = "Price is required";
    }
    if (!inputDate.decrement) {
      errors.decrement = "Per Increment is required";
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    try {
      const token = localStorage.getItem("accessToken");
      await axios.put(
        BASE_URL +
          "/v1/deliveryDate/updateDeliveryDate?estimatedDays=" +
          inputDate.days +
          "&priceForTotalDays=" +
          inputDate.price +
          "&pricePerDay=" +
          inputDate.decrement,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          // params: data.params,
          // responseType: "arraybuffer",
        }
      );
      message.success("Delivery Date Updated Successfully");
      fetchDateValue();
    } catch (error) {}
    setNewOpen(false);
    setNewOpen1(false);
  };

  const confirm = (e) => {
    const token = localStorage.getItem("accessToken");
    message.success("Deleted Successfully");
    axios
      .delete(`${BASE_URL}/v1/language/` + editedId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // params: data.params,
        // responseType: "arraybuffer",
      })
      .then(() => {
        fetchData();
      });
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    e.preventDefault();
    const errors = {};

    if (!editLanguageData.price) {
      errors.Mixutreprice = "Price is required";
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    const token = localStorage.getItem("accessToken");
    axios
      .put(
        `${BASE_URL}/v1/mixturemaster/${editID}?price=${editLanguageData.price}`,
        null, // Use `null` if there's no data to send in the request body
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        fetchData();
        message.success("Mixing Master Updated Successfully");
      })
      .catch((error) => {
        message.error(error.response.data.message);
      });
    setNewOpen(false);
  };

  const handleInput = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
    setInputDate({ ...inputDate, [e.target.name]: e.target.value });
  };
  const handleInputDate = (e) => {
    const { name, value } = e.target;
    setInputDate({ ...inputDate, [name]: value });

    // Clear the error message for this field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear the error message for this field
    }));
  };

  const handleDateEdit = () => {
    setNewOpen1(true);
    setNewOpen(false);
    fetchDateValue();
  };
  const handleEdit = (id) => {
    setDrawerButton("Edit Language");
    setEditID(id);
    setNewOpen(true);
    const token = localStorage.getItem("accessToken");
    axios
      .get(BASE_URL + "/v1/mixturemaster/" + id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // params: data.params,
        // responseType: "arraybuffer",
      })
      .then((response) => response.data)
      .then((res_data) => {
        setEditLanguageData(
          res_data.data.mixtureMaster || { mixingMasterType: "", price: "" }
        );
      });
  };
  const handleKeyPressNumber = (e) => {
    const charCode = e.charCode;
    if (!/[0-9]/.test(String.fromCharCode(charCode))) {
      e.preventDefault();
    }
  };
  const handleEditInput = (e) => {
    const { name, value } = e.target;
    setEditLanguageData({ ...editLanguageData, [name]: value });

    // Clear the error message for this field
    setErrors((prevErrors) => ({
      ...prevErrors,
      Mixutreprice: "", // Clear the error message for this field
    }));
  };

  const closeDrawer = () => {
    setOpen(false);
    setErrors({});
  };

  const columns = [
    { title: "SI.No", dataIndex: "sno", width: 150, align: "center" },
    { title: "Mixing & Delivery", dataIndex: "mixingMasterType", width: 250 },
    { title: "Price", dataIndex: "price", width: 750 },
    {
      title: "Action",
      align: "center",
      dataIndex: "actions",
      fixed: "right",
      width: 200,
    },
  ];
  const columns1 = [
    { title: "SI.No", dataIndex: "key", width: 150, align: "center" },
    { title: "Delivery Period", dataIndex: "estimatedDays", width: 250 },
    { title: "Price", dataIndex: "priceForTotalDays", width: 400 },
    { title: "Per Day Increment", dataIndex: "pricePerDay", width: 400 },
    {
      title: "Action",
      align: "center",
      fixed: "right",
      dataIndex: "actionDate",

      width: 200,
      render: (dateData) => {
        return (
          <div>
            <Button
              type="link"
              onClick={() => handleDateEdit()}
              className="edit-hover"
            >
              <i className="bi bi-pencil-fill sec-clr edit-icon-hover"></i>
            </Button>
          </div>
        );
      },
    },
  ];

  const data = tableData
    .filter((item) =>
      item.mixingMasterType.toLowerCase().includes(searchText.toLowerCase())
    )
    .map((item, index) => ({
      ...item,
      key: index.toString(),
      sno: index + 1,
      actions: (
        <div>
          <Button
            type="link"
            onClick={() => handleEdit(item.id)}
            className="edit-hover"
          >
            <i className="bi bi-pencil-fill sec-clr edit-icon-hover"></i>
          </Button>
        </div>
      ),
    }));

  return (
    <>
      <div className="sec-clr fs-14 fw-7 mb-4">Mixing Master</div>
      <Table
        scroll={{ x: 0, y: "50svh" }}
        columns={columns}
        dataSource={data}
        loading={loading}
        bordered
        pagination={true}
      />
      <div className="sec-clr fs-14 fw-7 mb-4">Delivery Date</div>
      <Table
        scroll={{ x: 0, y: "50svh" }}
        columns={columns1}
        dataSource={dateData}
        loading={loading}
        bordered
        pagination={true}
      />
      {/* <Tabx  */}
      <Drawer open={newopen} closable={false} onClose={onClose}>
        <div className="nav-content">
          <div className="d-flex justify-content-between sec-clr nav-title align-items-center">
            <h2 className="pri-clr fw-7 fs-30">
              {editID ? "Edit Mixing Master" : "Add New Language"}
            </h2>
            <i
              className="bi bi-x fs-26 fw-bold close-btn"
              onClick={onClose}
            ></i>
          </div>
          <div className="">
            <form onSubmit={editID ? handleEditSubmit : handleSubmit}>
              <div>
                <label
                  htmlFor="name"
                  className="side-nav-label fw-5 fs-16 c_454F5B"
                >
                  Mixing Master Name
                </label>
                <span className="text-danger">*</span>
                <br />
                <Input
                  type="text"
                  className="side-nav-input rounded"
                  name="name"
                  placeholder="Enter Language Name"
                  id="name"
                  onChange={editID ? handleEditInput : handleInput}
                  value={
                    editID ? editLanguageData.mixingMasterType : input.name
                  }
                  readOnly
                />
              </div>
              <div>
                <label
                  htmlFor="price"
                  className="side-nav-label fw-5 fs-16 mt-4 c_454F5B"
                >
                  Price
                </label>
                <span className="text-danger">*</span>
                <br />
                <Input
                  type="text"
                  className="side-nav-input rounded"
                  name="price"
                  placeholder="Enter Price"
                  id="price"
                  onKeyPress={handleKeyPressNumber}
                  onChange={editID ? handleEditInput : handleInput}
                  value={editID ? editLanguageData.price : input.price}
                />
                {errors.Mixutreprice && (
                  <div className="text-danger">{errors.Mixutreprice}</div>
                )}
              </div>
              <div className="button-side-nav d-flex justify-content-center">
                <Button
                  size="large"
                  className="button-bg"
                  onClick={closeDrawer}
                  htmlType="submit"
                >
                  Update
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Drawer>
      <Drawer open={newopen1} closable={false} onClose={onClose}>
        <div className="nav-content">
          <div className="d-flex justify-content-between sec-clr nav-title align-items-center">
            <h2 className="pri-clr fw-7 fs-30">Edit Delivery Details</h2>
            <i
              className="bi bi-x fs-26 fw-bold close-btn"
              onClick={onClose}
            ></i>
          </div>
          <div className="">
            <form onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="days"
                  className="side-nav-label fw-5 fs-16 c_454F5B"
                >
                  Choose Days
                </label>
                <span className="text-danger">*</span>
                <br />
                <Input
                  type="text"
                  className="side-nav-input rounded"
                  name="days"
                  placeholder="Enter How many days"
                  id="days"
                  onChange={handleInputDate}
                  onKeyPress={handleKeyPressNumber}
                  value={inputDate.days}
                />
                {errors.days && (
                  <div className="text-danger">{errors.days}</div>
                )}
              </div>
              <div>
                <label
                  htmlFor="price"
                  className="side-nav-label fw-5 fs-16 mt-4 c_454F5B"
                >
                  Price
                </label>
                <span className="text-danger">*</span>
                <br />
                <Input
                  type="text"
                  className="side-nav-input rounded"
                  name="price"
                  placeholder="Enter Price"
                  id="price"
                  onChange={handleInputDate}
                  onKeyPress={handleKeyPressNumber}
                  value={inputDate.price}
                  maxLength={6}
                />
                {errors.price && (
                  <div className="text-danger">{errors.price}</div>
                )}
              </div>
              <div>
                <label
                  htmlFor="decrement"
                  className="side-nav-label fw-5 fs-16 mt-4 c_454F5B"
                >
                  PerDay Increment
                </label>
                <span className="text-danger">*</span>
                <br />
                <Input
                  type="text"
                  className="side-nav-input rounded"
                  name="decrement"
                  placeholder="Enter Decrement per day"
                  id="decrement"
                  onChange={handleInputDate}
                  onKeyPress={handleKeyPressNumber}
                  value={inputDate.decrement}
                />
                {errors.decrement && (
                  <div className="text-danger">{errors.decrement}</div>
                )}
              </div>
              <div className="button-side-nav d-flex justify-content-center">
                <Button
                  size="large"
                  className="button-bg"
                  onClick={closeDrawer}
                  htmlType="submit"
                >
                  Update
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Drawer>
    </>
  );
};
