import React, { useEffect, useState, useRef } from "react";
import UserDetailsTab from "../Create_song/UserDetailsTab";
import VerifyDetails from "../Create_song/VerifyDetails";
import Payment from "../Create_song/Payment";
import ZipCodeSeclector from "../Create_song/PhoneNumberInput";
import { Button, Popover, Spin, Modal, Input, message } from "antd";
import axios from "axios";
import { BASE_URL } from "../..";
import Footer from "../Base/Footer";
import Select from "react-select";
import "../../pages/Admin/SongStatus/Components/style.css";
import OTPVerification from "../Admin/SongStatus/Components/OTPinput";

const UserDetails = ({
  setActiveTab,
  activeTab,
  userId,
  amountId,
  firstPageDate,
  setFirstPageData,
}) => {
  const [audio] = useState(new Audio());
  const [isPlaying, setIsPlaying] = useState(false);
  const [playButton, setPlayButton] = useState(false);
  const [moodSelete, setMoodSelete] = useState([]);
  const [languageSelete, setLanguageSelete] = useState([]);
  const [instrumentSelete, setInstrumentSelete] = useState([]);
  const [artistSelete, setArtistSelete] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [totalAmount, setTotalAmount] = useState("0");
  const [languageAmount, setLanguageAmount] = useState(0);
  const [instrumentAmount, setInstrumentAmount] = useState(0);
  const [artistAmount, setArtistAmount] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0); // State for audio duration
  const [fieldsData, setFieldsData] = useState("");
  const [nameMandatory, setNameMandatory] = useState("");
  const [mobileMandatory, setMobileMandatory] = useState("");
  const [mailMandatory, setMailMandatory] = useState("");
  const [languageMandatory, setLanguageMandatory] = useState("");
  const [artistMandatory, setArtistMandatory] = useState("");
  const [instumentMandatory, setInstrumentMandatory] = useState("");
  const [moodMandatory, setMoodMandatory] = useState("");
  const [orderInfo, setOrderInfo] = useState("");
  const [numberError, setNumberError] = useState("");
  const [otpData, setOtpData] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedCode, setSelectedCode] = useState("+91");
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);

  const [input, setInput] = useState({
    name: "",
    number: "",
    email: "",
    language: "",
    gender: "",
    instrument: "",
    songMood: "",
    total: "",
    languageAmount: 0,
    instrumentAmount: 0,
    artistAmount: 0,
  });
  // const [activeTab, setActiveTab] = useState(0);
  // ===================================================================
  const [selectedOption, setSelectedOption] = useState({
    value: selectedCode,
    label: selectedCode,
  });
  const [resendDisabled, setResendDisabled] = useState(true);
  const [verifyOtpButton, setVerifyOtpButton] = useState(true);
  const [timer, setTimer] = useState(30);
  const getOtp = (e) => {
    setOtpData(e);

    setOtpErrors((prevErrors) => ({
      ...prevErrors,
      otp: null, // Clear the OTP error if the length is valid
    }));
  };

  const handleInputChange = (e, index) => {
    const value = e.target.value;

    if (isNaN(value) && value !== "") return;

    const newOtp = [...otp];
    newOtp[index] = value;
    const otpString = newOtp.join("");
    setOtpData(otpString);
    setOtp(newOtp);
    setOtpErrors((prevErrors) => ({
      ...prevErrors,
      otp: null, // Clear the OTP error if the length is valid
    }));
    // Move focus to the next input field if the input length is 1
    if (value.length === 1 && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];
      newOtp[index] = "";
      const otpString = newOtp.join("");
      setOtpData(otpString);
      setOtp(newOtp);
      // Move focus to the previous input field if backspace is pressed
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    } else if (e.key === "Enter") {
      e.preventDefault();
      // alert(`Enter key pressed! Input value:`);
      verifyOtp();
    }
  };
  const handlePaste = (e, index) => {
    e.preventDefault();
    const pastedData = e.clipboardData
      .getData("text")
      .slice(0, otp.length - index); // Only take as many characters as can fit
    const newOtp = [...otp];

    pastedData.split("").forEach((char, i) => {
      if (i + index < otp.length) {
        newOtp[i + index] = char;
      }
    });

    setOtp(newOtp);
    const otpString = newOtp.join("");
    setOtpData(otpString);

    // Move focus to the next input field after pasting
    const nextFieldIndex = Math.min(index + pastedData.length, otp.length - 1);
    inputRefs.current[nextFieldIndex].focus();
  };
  const [phoneNumber, setPhoneNumber] = useState(null);
  const countries = [
    { code: "+93", name: "Afghanistan" },
    { code: "+355", name: "Albania" },
    { code: "+213", name: "Algeria" },
    { code: "+1684", name: "American Samoa" },
    { code: "+376", name: "Andorra" },
    { code: "+244", name: "Angola" },
    { code: "+54", name: "Argentina" },
    { code: "+374", name: "Armenia" },
    { code: "+297", name: "Aruba" },
    { code: "+61", name: "Australia" },
    { code: "+43", name: "Austria" },
    { code: "+994", name: "Azerbaijan" },
    { code: "+973", name: "Bahrain" },
    { code: "+880", name: "Bangladesh" },
    { code: "+375", name: "Belarus" },
    { code: "+32", name: "Belgium" },
    { code: "+501", name: "Belize" },
    { code: "+229", name: "Benin" },
    { code: "+975", name: "Bhutan" },
    { code: "+591", name: "Bolivia" },
    { code: "+387", name: "Bosnia and Herzegovina" },
    { code: "+267", name: "Botswana" },
    { code: "+55", name: "Brazil" },
    { code: "+246", name: "British Indian Ocean Territory" },
    { code: "+673", name: "Brunei" },
    { code: "+359", name: "Bulgaria" },
    { code: "+225", name: "Côte d'Ivoire" },
    { code: "+236", name: "Central African Republic" },
    { code: "+235", name: "Chad" },
    { code: "+56", name: "Chile" },
    { code: "+86", name: "China" },
    { code: "+61", name: "Christmas Island" },
    { code: "+61", name: "Cocos (Keeling) Islands" },
    { code: "+57", name: "Colombia" },
    { code: "+269", name: "Comoros" },
    { code: "+242", name: "Congo" },
    { code: "+243", name: "Congo, Democratic Republic of the" },
    { code: "+682", name: "Cook Islands" },
    { code: "+506", name: "Costa Rica" },
    { code: "+385", name: "Croatia" },
    { code: "+53", name: "Cuba" },
    { code: "+599", name: "Curaçao" },
    { code: "+357", name: "Cyprus" },
    { code: "+420", name: "Czech Republic" },
    { code: "+45", name: "Denmark" },
    { code: "+253", name: "Djibouti" },
    { code: "+1", name: "Dominica" },
    { code: "+1809", name: "Dominican Republic" },
    { code: "+1829", name: "Dominican Republic" },
    { code: "+1849", name: "Dominican Republic" },
    { code: "+593", name: "Ecuador" },
    { code: "+20", name: "Egypt" },
    { code: "+503", name: "El Salvador" },
    { code: "+240", name: "Equatorial Guinea" },
    { code: "+291", name: "Eritrea" },
    { code: "+372", name: "Estonia" },
    { code: "+268", name: "Eswatini" },
    { code: "+500", name: "Falkland Islands" },
    { code: "+298", name: "Faroe Islands" },
    { code: "+679", name: "Fiji" },
    { code: "+358", name: "Finland" },
    { code: "+33", name: "France" },
    { code: "+594", name: "French Guiana" },
    { code: "+689", name: "French Polynesia" },
    { code: "+241", name: "Gabon" },
    { code: "+220", name: "Gambia" },
    { code: "+995", name: "Georgia" },
    { code: "+49", name: "Germany" },
    { code: "+233", name: "Ghana" },
    { code: "+350", name: "Gibraltar" },
    { code: "+30", name: "Greece" },
    { code: "+299", name: "Greenland" },
    { code: "+502", name: "Guatemala" },
    { code: "+224", name: "Guinea" },
    { code: "+245", name: "Guinea-Bissau" },
    { code: "+592", name: "Guyana" },
    { code: "+509", name: "Haiti" },
    { code: "+504", name: "Honduras" },
    { code: "+852", name: "Hong Kong" },
    { code: "+36", name: "Hungary" },
    { code: "+354", name: "Iceland" },
    { code: "+91", name: "India" },
    { code: "+62", name: "Indonesia" },
    { code: "+98", name: "Iran" },
    { code: "+964", name: "Iraq" },
    { code: "+353", name: "Ireland" },
    { code: "+44", name: "Isle of Man" },
    { code: "+972", name: "Israel" },
    { code: "+39", name: "Italy" },
    { code: "+81", name: "Japan" },
    { code: "+962", name: "Jordan" },
    { code: "+7", name: "Kazakhstan" },
    { code: "+254", name: "Kenya" },
    { code: "+686", name: "Kiribati" },
    { code: "+850", name: "North Korea" },
    { code: "+82", name: "South Korea" },
    { code: "+965", name: "Kuwait" },
    { code: "+996", name: "Kyrgyzstan" },
    { code: "+856", name: "Laos" },
    { code: "+371", name: "Latvia" },
    { code: "+961", name: "Lebanon" },
    { code: "+266", name: "Lesotho" },
    { code: "+231", name: "Liberia" },
    { code: "+218", name: "Libya" },
    { code: "+423", name: "Liechtenstein" },
    { code: "+370", name: "Lithuania" },
    { code: "+352", name: "Luxembourg" },
    { code: "+261", name: "Madagascar" },
    { code: "+265", name: "Malawi" },
    { code: "+60", name: "Malaysia" },
    { code: "+960", name: "Maldives" },
    { code: "+223", name: "Mali" },
    { code: "+356", name: "Malta" },
    { code: "+692", name: "Marshall Islands" },
    { code: "+596", name: "Martinique" },
    { code: "+222", name: "Mauritania" },
    { code: "+230", name: "Mauritius" },
    { code: "+262", name: "Mayotte" },
    { code: "+52", name: "Mexico" },
    { code: "+691", name: "Micronesia" },
    { code: "+373", name: "Moldova" },
    { code: "+377", name: "Monaco" },
    { code: "+976", name: "Mongolia" },
    { code: "+382", name: "Montenegro" },
    { code: "+212", name: "Morocco" },
    { code: "+258", name: "Mozambique" },
    { code: "+95", name: "Myanmar" },
    { code: "+264", name: "Namibia" },
    { code: "+674", name: "Nauru" },
    { code: "+977", name: "Nepal" },
    { code: "+31", name: "Netherlands" },
    { code: "+687", name: "New Caledonia" },
    { code: "+64", name: "New Zealand" },
    { code: "+505", name: "Nicaragua" },
    { code: "+227", name: "Niger" },
    { code: "+234", name: "Nigeria" },
    { code: "+683", name: "Niue" },
    { code: "+672", name: "Norfolk Island" },
    { code: "+47", name: "Norway" },
    { code: "+968", name: "Oman" },
    { code: "+92", name: "Pakistan" },
    { code: "+680", name: "Palau" },
    { code: "+970", name: "Palestine" },
    { code: "+507", name: "Panama" },
    { code: "+675", name: "Papua New Guinea" },
    { code: "+595", name: "Paraguay" },
    { code: "+51", name: "Peru" },
    { code: "+63", name: "Philippines" },
    { code: "+64", name: "Pitcairn Islands" },
    { code: "+48", name: "Poland" },
    { code: "+351", name: "Portugal" },
    { code: "+1939", name: "Puerto Rico" },
    { code: "+974", name: "Qatar" },
    { code: "+40", name: "Romania" },
    { code: "+7", name: "Russia" },
    { code: "+250", name: "Rwanda" },
    { code: "+590", name: "Saint Barthélemy" },
    { code: "+290", name: "Saint Helena" },
    { code: "+1758", name: "Saint Lucia" },
    { code: "+685", name: "Samoa" },
    { code: "+378", name: "San Marino" },
    { code: "+239", name: "Sao Tome and Principe" },
    { code: "+966", name: "Saudi Arabia" },
    { code: "+221", name: "Senegal" },
    { code: "+381", name: "Serbia" },
    { code: "+248", name: "Seychelles" },
    { code: "+232", name: "Sierra Leone" },
    { code: "+65", name: "Singapore" },
    { code: "+227", name: "Niger" },
    { code: "+682", name: "Cook Islands" },
    { code: "+590", name: "Saint Martin" },
    { code: "+239", name: "São Tomé and Príncipe" },
    { code: "+27", name: "South Africa" },
    { code: "+34", name: "Spain" },
    { code: "+94", name: "Sri Lanka" },
    { code: "+249", name: "Sudan" },
    { code: "+597", name: "Suriname" },
    { code: "+268", name: "Eswatini" },
    { code: "+46", name: "Sweden" },
    { code: "+41", name: "Switzerland" },
    { code: "+963", name: "Syria" },
    { code: "+886", name: "Taiwan" },
    { code: "+992", name: "Tajikistan" },
    { code: "+255", name: "Tanzania" },
    { code: "+66", name: "Thailand" },
    { code: "+670", name: "Timor-Leste" },
    { code: "+228", name: "Togo" },
    { code: "+690", name: "Tokelau" },
    { code: "+676", name: "Tonga" },
    { code: "+216", name: "Tunisia" },
    { code: "+90", name: "Turkey" },
    { code: "+993", name: "Turkmenistan" },
    { code: "+688", name: "Tuvalu" },
    { code: "+256", name: "Uganda" },
    { code: "+380", name: "Ukraine" },
    { code: "+971", name: "United Arab Emirates" },
    { code: "+44", name: "United Kingdom" },
    { code: "+1", name: "United States" },
    { code: "+598", name: "Uruguay" },
    { code: "+998", name: "Uzbekistan" },
    { code: "+678", name: "Vanuatu" },
    { code: "+379", name: "Vatican City" },
    { code: "+58", name: "Venezuela" },
    { code: "+84", name: "Vietnam" },
    { code: "+1284", name: "British Virgin Islands" },
    { code: "+1340", name: "United States Virgin Islands" },
    { code: "+681", name: "Wallis and Futuna" },
    { code: "+967", name: "Yemen" },
    { code: "+260", name: "Zambia" },
    { code: "+263", name: "Zimbabwe" },
  ];

  const options = countries.map((country) => {
    return { value: country.code, label: country.code };
  });

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    handleSelectChange(selectedOption);
  };
  const handleKeyPressNumber = (e) => {
    const charCode = e.charCode;
    if (!/[0-9]/.test(String.fromCharCode(charCode))) {
      e.preventDefault();
    }
  };
  // ====================================================================
  const getOrderData = (id) => {
    axios
      .get(BASE_URL + "/v1/form/" + id)
      .then((response) => response.data)
      .then((res_data) => {
        setOrderInfo(res_data.data.formRequestDto);
      });
  };
  const getAmount = (id) => {
    axios
      .get(BASE_URL + "/v1/orderSummary/" + id)
      .then((response) => response.data)
      .then((res_data) => {
        setLanguageAmount(res_data.data.orderSummary.langugaeAmount);
        setInstrumentAmount(res_data.data.orderSummary.versionAmount);
        setArtistAmount(res_data.data.orderSummary.artistAmount);
      });
  };

  const MoodSeleteData = () => {
    axios
      .get(BASE_URL + "/v1/mood/get-AllMood-Active")
      .then((response) => response.data)
      .then((res_data) => {
        setMoodSelete(res_data.data.moods);
      });
  };

  const LanguageSeleteData = () => {
    axios
      .get(BASE_URL + "/v1/language/get-AllLanguage-Active")
      .then((response) => response.data)
      .then((res_data) => {
        setLanguageSelete(res_data.data.languages);
      });
  };
  const artistSeleteData = () => {
    axios
      .get(BASE_URL + "/v1/artistmaster/getall")
      .then((response) => response.data)
      .then((res_data) => {
        setArtistSelete(res_data.data.artist);
      });
  };
  const instru = sessionStorage.getItem("bookedInstrument");

  const InstrumentSeleteData = () => {
    axios
      .get(BASE_URL + "/v1/instrument/get-AllInstrument-Active")
      .then((response) => response.data)
      .then((res_data) => {
        setInstrumentSelete(res_data.data.instruments);
        if (userId !== 0) {
          for (
            let index = 0;
            index < res_data.data.instruments.length;
            index++
          ) {
            const element = res_data.data.instruments[index].name;
            if (element === orderInfo.instrument) {
              setDuration(
                convertToSeconds(res_data.data.instruments[index].audioDuration)
              );
            }
          }
        }
        if (firstPageDate.instrument || instru) {
          for (
            let index = 0;
            index < res_data.data.instruments.length;
            index++
          ) {
            const element = res_data.data.instruments[index].name;
            if (element === firstPageDate.instrument || element === instru) {
              setDuration(
                convertToSeconds(res_data.data.instruments[index].audioDuration)
              );
              setInstrumentAmount(res_data.data.instruments[index].price);
            }
          }
        }
      });
  };
  function convertToSeconds(time) {
    const [minutes, seconds] = time.split(":").map(Number);
    return minutes * 60 + seconds;
  }
  const getFields = () => {
    const idsToDelete = [];
    axios
      .get(BASE_URL + "/v1/Filed/")
      .then((response) => response.data)
      .then((res_data) => {
        for (let index = 0; index < res_data.data.fileds.length; index++) {
          const element = res_data.data.fileds[index];
          if (res_data.data.fileds[index].name === "Name") {
            setNameMandatory(res_data.data.fileds[index].isMandatory);
          } else if (res_data.data.fileds[index].name === "Mobile Number") {
            setMobileMandatory(res_data.data.fileds[index].isMandatory);
          } else if (res_data.data.fileds[index].name === "Email id") {
            setMailMandatory(res_data.data.fileds[index].isMandatory);
          } else if (res_data.data.fileds[index].name === "Langauge") {
            setLanguageMandatory(res_data.data.fileds[index].isMandatory);
          } else if (res_data.data.fileds[index].name === "Artist") {
            setArtistMandatory(res_data.data.fileds[index].isMandatory);
          } else if (res_data.data.fileds[index].name === "Instrument") {
            setInstrumentMandatory(res_data.data.fileds[index].isMandatory);
          } else if (res_data.data.fileds[index].name === "Mood") {
            setMoodMandatory(res_data.data.fileds[index].isMandatory);
          }
        }
      });
  };
  useEffect(() => {
    if (userId !== 0) {
      getOrderData(userId);
    }
  }, [userId]);
  useEffect(() => {
    if (amountId !== 0) {
      getAmount(amountId);
    }
  }, [amountId]);
  useEffect(() => {
    if (userId !== 0) {
      let numberPart = "";
      if (orderInfo.phonenumber !== undefined) {
        const parts = orderInfo.phonenumber.split("-");
        numberPart = parts.length > 1 ? parts[1] : "";
        setSelectedOption({ value: parts[0], label: parts[0] });
        setSelectedCode(parts[0]);
      }
      setInput({
        name: orderInfo.name || "",
        number: numberPart || "",
        email: orderInfo.mail || "",
        language: orderInfo.lanuage || "",
        gender: orderInfo.artist || "",
        instrument: orderInfo.instrument || "",
        songMood: orderInfo.mood || "",
      });
      InstrumentSeleteData();
    }
  }, [orderInfo]);

  useEffect(() => {
    if (userId === 0) {
      let numberPart = "";

      if (firstPageDate.number !== undefined) {
        const parts = firstPageDate.number.split("-");
        numberPart = parts.length > 1 ? parts[1] : "";
        setSelectedCode(parts[0]);
      }

      setInput({
        name: firstPageDate.name || "",
        number: numberPart || "",
        email: firstPageDate.email || "",
        language: firstPageDate.language || "",
        gender: firstPageDate.gender || "",
        instrument: firstPageDate.instrument || instru || "",
        songMood: firstPageDate.songMood || "",
      });

      setLanguageAmount(
        isNaN(firstPageDate.languageAmount) ? 0 : firstPageDate.languageAmount
      );
      setInstrumentAmount(
        isNaN(firstPageDate.instrumentAmount)
          ? 0
          : firstPageDate.instrumentAmount
      );
      setArtistAmount(
        isNaN(firstPageDate.artistAmount) ? 0 : firstPageDate.artistAmount
      );
    }
  }, [userId, firstPageDate]);

  const handleSelectChange = (selectedOption) => {
    setSelectedCode(selectedOption.value);
    setInput((prevState) => ({
      ...prevState,
      number: prevState.number.replace(/^\+\d*/, selectedOption.value),
    }));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    // MoodSeleteData();
    LanguageSeleteData();
    getFields();
    InstrumentSeleteData();
    artistSeleteData();
    const handleEnded = () => {
      setIsPlaying(false); // Audio playback ended, set isPlaying to false
    };
    audio.addEventListener("ended", handleEnded);

    return () => {
      audio.removeEventListener("ended", handleEnded);
    };
  }, [audio]);

  useEffect(() => {
    let intervalId;

    if (isPlaying) {
      intervalId = setInterval(() => {
        setCurrentTime(audio.currentTime);
      }, 100);
    } else {
      clearInterval(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isPlaying, audio]);

  const changeCurrentTime = (e) => {
    const newTime = parseFloat(e.target.value);
    setCurrentTime(newTime);
    if (audio) {
      audio.currentTime = newTime;
    }
  };

  const togglePlayPause = () => {
    let songAudio = ""; // Declare using let instead of const

    for (let index = 0; index < instrumentSelete.length; index++) {
      const element = instrumentSelete[index];

      if (element.name === input.instrument) {
        songAudio = element.audio;
      }
    }

    if (isPlaying) {
      audio.pause();
      setIsPlaying(!isPlaying);
    } else {
      audio.src = BASE_URL + "/v1/form/song/" + songAudio;

      if (audio.src !== BASE_URL + "/v1/form/song/") {
        audio.play();
        audio.currentTime = currentTime;
        setIsPlaying(!isPlaying);
      } else {
        errors.instrument = "Please select Sample";
      }
    }
  };

  const handleSelete = (e) => {
    handleInput(e);
    if (e.target.name === "instrument") {
      setPlayButton(true);
    }
    var data = e.target.value;
    for (let index = 0; index < languageSelete.length; index++) {
      const element = languageSelete[index].name;
      if (element === data) {
        setLanguageAmount(languageSelete[index].price);
      }
    }
    for (let index = 0; index < instrumentSelete.length; index++) {
      const element = instrumentSelete[index].name;
      if (element === data) {
        setInstrumentAmount(instrumentSelete[index].price);
        setDuration(convertToSeconds(instrumentSelete[index].audioDuration));
      }
    }
    for (let index = 0; index < artistSelete.length; index++) {
      const element = artistSelete[index].artist;
      if (element === data) {
        setArtistAmount(artistSelete[index].price);
      }
    }

    audio.pause();
    setIsPlaying(false);
    setCurrentTime(0);
  };

  const handleInput = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
    const { name, value } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage, // Clear the error message for this field
    }));

    // setInput((prevInput) => ({
    //   ...prevInput,
    //   [name]: value,
    // }));

    let errorMessage = "";

    if (name === "name") {
      if (!value.trim()) {
        errorMessage = "Name is required";
      } else if (!/^[A-Za-z .]+$/.test(value.trim())) {
        errorMessage = "Only alphabets and spaces are allowed";
      }
    }
    if (name === "number") {
      if (!value.trim()) {
        errorMessage = "Number is required";
      } else if (!/^\d+$/.test(value.trim())) {
        errorMessage = "Only numbers are allowed";
      }
    }

    if (name === "email") {
      if (!value || !value.trim()) {
        errorMessage = "Email is required";
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value.trim())) {
        errorMessage = "Invalid email address";
      } else if (/\.\d/.test(value.trim().split("@")[1])) {
        errorMessage =
          "Invalid email address. No numbers are allowed after the dot in the domain";
      }
    }
    if (name === "language") {
    }

    if (name === "instrument") {
    }
  };

  const openModal = () => {
    setModalShow(true);
  };
  const closeModal = () => {
    setOtp(["", "", "", "", "", ""]);
    setModalShow(false);
  };
  useEffect(() => {
    let countdown;
    if (modalShow) {
      setResendDisabled(true);
      setTimer(60);

      countdown = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            clearInterval(countdown);
            setResendDisabled(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => clearInterval(countdown);
  }, [modalShow]);

  const resendOtp = async () => {
    setOtp(["", "", "", "", "", ""]);
    setResendDisabled(true);
    setTimer(60);
    try {
      const response = await axios.post(
        `${BASE_URL}/v1/form/resend-otp?email=${input.email}&name=${input.name}`
      );
      message.success(response.data);
    } catch (error) {
      // handle error
      setResendDisabled(false);
      message.error(error.response.data);
    }

    let countdown = setInterval(() => {
      setTimer((prev) => {
        if (prev === 1) {
          clearInterval(countdown);
          setResendDisabled(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };
  const [otpErrors, setOtpErrors] = useState({});
  const verifyOtp = async () => {
    const errors = {};
    if (otpData.length !== 6) {
      errors.otp = "OTP is required and must be 6 digits.";
      setOtpErrors(errors);
      return; // Stop the function if OTP validation fails
    } else {
      try {
        const response = await axios.post(
          `${BASE_URL}/v1/form/verify-otp?email=${input.email}&otp=${otpData}`
        );
        const totalAmt =
          parseInt(languageAmount) +
          parseInt(instrumentAmount) +
          parseInt(artistAmount);
        const data = {
          name: input.name,
          number: selectedCode + "-" + input.number,
          email: input.email,
          language: input.language,
          gender: input.gender,
          instrument: input.instrument,
          // songMood: input.songMood,
          total: totalAmt,
          languageAmount: languageAmount,
          instrumentAmount: instrumentAmount,
          artistAmount: artistAmount,
        };
        setFirstPageData(data);
        message.success(response.data);
        setActiveTab(1);
      } catch (error) {
        // handle error
        message.error(error.response.data);
        // setOtpErrors(error.response.data);
      }
    }

    // closeModal();
  };

  // const handleEnterKeyDown = (event) => {
  //   if (event.key === "Enter") {
  //     event.preventDefault();
  //     // alert(`Enter key pressed! Input value:`);
  //     verifyOtp();
  //   }
  // };

  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    // setActiveTab(1);
    setIsPlaying(false);
    audio.pause();
    e.preventDefault();

    // Initialize an errors object to store validation errors
    const errors = {};

    // Validate Name field

    if (!input.name.trim()) {
      errors.name = "Name is required";
    }

    // Validate Number field

    // if (!input.number.trim()) {
    //   errors.number = "Number is required";
    // } else if (input.number) {
    //   let data = "";
    //   axios
    //     .get(
    //       // `${BASE_URL}/v1/form/validatemobilenumber?countryCode=${selectedCode}&mobileNumber=${input.number}`
    //       `${BASE_URL}/v1/form/validatemobilenumber?countryCode=${encodeURIComponent(
    //         selectedCode
    //       )}&mobileNumber=${input.number}`
    //     )
    //     .then((response) => {
    //     })
    //     .catch((error) => {
    //       errors.number = error.response.status;

    //       // errors.number = "Number required";
    //       // if (input.number) {
    //       //   errors.number = "Number required";
    //       // }
    //     });
    // }

    if (!input.number.trim()) {
      errors.number = "Number is required";
    } else {
      try {
        const response = await axios.get(
          `${BASE_URL}/v1/form/validatemobilenumber?countryCode=${encodeURIComponent(
            selectedCode
          )}&mobileNumber=${input.number}`
        );
      } catch (error) {
        errors.number = "Invalid number"; // Set the error message from the API
      }
    }
    // Validate Email field

    if (!input.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(input.email.trim())) {
      errors.email = "Invalid email address";
    } else if (/\.\d/.test(input.email.trim().split("@")[1])) {
      errors.email =
        "Invalid email address. No numbers are allowed after the dot in the domain";
    }

    //Validate Language field
    if (!input.language) {
      errors.language = "Language is required";
    }

    //Validate Gender field
    if (!input.gender) {
      if (artistMandatory === "Mandatory") {
        errors.gender = "Gender is required";
      }
    }

    //Validate Instrument field
    if (!input.instrument) {
      if (instumentMandatory === "Mandatory") {
        errors.instrument = "Instrument is required";
      }
    }

    // If there are errors, set them to state and prevent form submission
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    if (firstPageDate.email != input.email && orderInfo.mail != input.email) {
      try {
        axios.post(
          `${BASE_URL}/v1/form/send-otp?email=${input.email}&name=${input.name}`
        );
        openModal();
      } catch (error) {
        // handle error
      }
    } else {
      const totalAmt = parseInt(languageAmount) + parseInt(instrumentAmount);
      const data = {
        name: input.name,
        number: selectedCode + "-" + input.number,
        email: input.email,
        language: input.language,
        gender: input.gender,
        instrument: input.instrument,
        // songMood: input.songMood,
        total: totalAmt,
        languageAmount: languageAmount,
        instrumentAmount: instrumentAmount,
        artistAmount: artistAmount,
      };
      setFirstPageData(data);
      setActiveTab(1);
    }

    // If no errors, proceed with form submission
    const totalAmt = parseInt(languageAmount) + parseInt(instrumentAmount);
    const data = {
      name: input.name,
      number: selectedCode + "-" + input.number,
      email: input.email,
      language: input.language,
      gender: input.gender,
      instrument: input.instrument,
      // songMood: input.songMood,
      total: totalAmt,
      languageAmount: languageAmount,
      instrumentAmount: instrumentAmount,
      artistAmount: artistAmount,
    };
    // setFirstPageData(data);

    sessionStorage.setItem(
      "basicInputs",
      JSON.stringify({ ...input, ...data })
    );

    // setActiveTab(1);
  };
  const formatDuration = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  };

  //*************************  Pop up
  const nameInfo = (
    <div>
      <p>
        Please enter your full name as it will appear on your personalized song.
        This helps us address you properly and adds a personal touch to our
        communication.
      </p>
    </div>
  );
  const numberInfo = (
    <div>
      <p>
        Provide a valid phone number where we can reach you if we have any
        questions about your song or to provide updates on your order. Your
        phone number will not be shared with third parties.
      </p>
    </div>
  );
  const mailInfo = (
    <div>
      <p>
        Enter your email address to receive important notifications about your
        order, including previews, updates, and the final delivery of your
        customized song. Ensure your email is correct to stay informed
        throughout the process.
      </p>
    </div>
  );
  const languageInfo = (
    <div>
      <p>
        Select the language in which you would like your song to be created.
        This ensures that the lyrics and emotions are conveyed accurately and
        meaningfully in your preferred language.
      </p>
    </div>
  );
  const artistInfo = (
    <div>
      <p>
        Choose whether you would like a male or female voice for your customized
        song. This helps us ensure that the vocal performance matches your
        preference and enhances the overall feel of the song.
      </p>
    </div>
  );
  const instrumentInfo = (
    <div>
      <p>Select the version of the song that best suits your needs.</p>
    </div>
  );

  // ****************************  Remove the tag and value

  const handleRemoveField = (fieldName) => {
    setInput((prevInput) => ({
      ...prevInput,
      [fieldName]: "",
    }));
    if (fieldName === "language") {
      setLanguageAmount(0);
    } else if (fieldName === "instrument") {
      setPlayButton(false);
      setInstrumentAmount(0);
    } else if (fieldName === "gender") {
      setArtistAmount(0);
    }

    setDuration(0);
    setCurrentTime(0);
    audio.pause();
  };
  const handleOtpChange = (value) => {
    setOtpData(value); // Capture OTP value and set to state
  };

  // ====================Toggle Advance Options funtion

  const [adOption, setAdOption] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 575);

  const toggleAdvanceOptions = () => {
    setAdOption((prevState) => !prevState);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 575);
    };

    window.addEventListener("resize", handleResize);

    // Handle initial visibility based on screen size and adOption state
    if (!isMobile) {
      setAdOption(true); // Show advance options for larger screens
    } else {
      setAdOption(false); // Hide advance options for mobile screens
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);
  const handleKeyPressName = (e) => {
    const charCode = e.charCode;
    if (!/[a-zA-Z. ]/.test(String.fromCharCode(charCode))) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    const advanceOptions =
      document.getElementsByClassName("advance-options")[0];
    if (advanceOptions) {
      advanceOptions.style.display = adOption ? "flex" : "none";
    }
  }, [adOption]);

  return (
    <>
      <Spin
        spinning={loading}
        fullscreen
        className="custom-spin fixed-top"
        size="large"
        tip="Loading"
      />
      <section className="section1 bg-color DM_Sans">
        {/* <Navbar createSong={true} />
        <Tab activeTab={activeTab} setActiveTab={setActiveTab} /> */}

        {/* Content for the first tab */}

        {activeTab === 0 && (
          <div className="container-fluid">
            <Modal
              className="bg-color"
              centered
              open={modalShow}
              closable={false}
              footer={false}
            >
              <div className="m-4">
                <div className="fs-24 fw-5 text-center mb-4">{input.email}</div>
                <div className="fs-18 fw-4 c_505A64 text-center mb-4">
                  We have sent a 6-digit <b>OTP</b> to <b>{input.email}</b>.
                  Please enter the code below to proceed
                </div>
                {/* <Input.OTP
                  onChange={getOtp}
                  // length={6}
                  maxLength={1}
                  value={otpData}
                  className="otpBox"
                /> */}

                <div className="wrapper">
                  {otp.map((_, index) => (
                    <input
                      key={index}
                      type="text"
                      className={`field ${index + 1}`}
                      maxLength="1"
                      value={otp[index]}
                      onChange={(e) => handleInputChange(e, index)}
                      onKeyUp={(e) => handleKeyDown(e, index)}
                      onPaste={(e) => handlePaste(e, index)}
                      ref={(el) => (inputRefs.current[index] = el)}
                    />
                  ))}
                </div>

                {otpErrors.otp && (
                  <div className="text-danger">{otpErrors.otp}</div>
                )}
                <div className="mt-3 mb-3 fs-20">
                  <span className="c_898989 me-2">Didn't receive OTP?</span>
                  <span
                    className={`c_3CB043 audio-file ${
                      !resendDisabled ? "hoverUnderline" : ""
                    } `}
                    onClick={!resendDisabled ? resendOtp : null}
                    style={{
                      cursor: resendDisabled ? "default" : "pointer",
                      opacity: resendDisabled ? 0.6 : 1,
                    }}
                  >
                    Resend {resendDisabled && `(${timer}s)`}
                  </span>
                </div>
                <div>
                  <button
                    type="button"
                    className="payment-btn fs-20 fw-6 w-100 mb-5 cursor"
                    onClick={verifyOtp}
                    // disabled={verifyOtpButton}
                  >
                    Verify OTP
                  </button>
                </div>

                <div>
                  <button
                    type="button"
                    className="update_mail fw-6 fs-20 w-100"
                    onClick={closeModal}
                  >
                    Update Email
                  </button>
                </div>
              </div>
            </Modal>
            <div className="container-fluid">
              <div className="shape1">
                <img
                  src={require("../../assets/images/shape1.png")}
                  alt=""
                  className="d-none d-md-block"
                />
              </div>
              <div className="shape2 hidden_shape2">
                <img
                  src={require("../../assets/images/shape2.png")}
                  className="d-none d-md-block"
                  alt=""
                />
              </div>
              <div className="dsf ms-xl-5 ms-lg-5 ms-md-3">
                <h1 className="mt-5 c_F77B21 fs-36 fw-6 ps-3">
                  Let's Create a Song
                </h1>
                <p className="song-para my-2 my-md-5 c_9F9F9F fst-italic ps-3">
                  Ready to Turn Your Stories and Emotions into Melodies? We'll
                  walk you through the process one step at a time. Feeling
                  inspired? Let's craft your perfect song together. With our
                  step-by-step guidance, your musical masterpiece is just a few
                  clicks away.
                </p>

                {/* <h3 className="fs-22 c_F77B21 fw-6  ps-3 mt-5">Basic Info</h3> */}
              </div>
            </div>

            <div className="container-fluid">
              <div className="row ms-xl-5 ms-lg-5 ms-md-2">
                <div className="col-xl-9 col-lg-12 col-md-12">
                  <form
                    // key={index}
                    action=""
                    onSubmit={handleSubmit}
                    className="row g-3 my-2"
                  >
                    <>
                      <div className="col-xl-3 col-lg-3 col-md-6 col-12 px-2">
                        <label htmlFor="name" className="form-label fw-5">
                          Name
                          <span className="text-danger">*</span>
                          <Popover
                            content={nameInfo}
                            placement="bottomRight"
                            className="info_width"
                          >
                            <Button type="button" className="p-1" tabIndex={-1}>
                              <i className="bi bi-info-circle c_575757 hover-info"></i>
                            </Button>
                          </Popover>
                        </label>
                        <input
                          type="text"
                          className="form-control form-input"
                          name="name"
                          placeholder="Enter Name"
                          id="name"
                          onChange={handleInput}
                          onKeyPress={handleKeyPressName}
                          value={input.name}
                        />
                        {errors.name && (
                          <div className="text-danger">{errors.name}</div>
                        )}
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-6 col-12 px-2">
                        <label htmlFor="number" className="form-label fw-5">
                          Phone Number
                          <span className="text-danger">*</span>
                          <Popover content={numberInfo} placement="bottomLeft">
                            <Button type="button" className="p-1" tabIndex={-1}>
                              <i className="bi bi-info-circle c_575757 hover-info"></i>
                            </Button>
                          </Popover>
                        </label>
                        {/* <ZipCodeSeclector
                          handleInput={handleInput}
                          input={input.number}
                          error={errors.number}
                          handleSelectChange={handleSelectChange}
                          selectedCode={selectedCode}
                        /> */}
                        <div className="zip-group">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text p-0"
                                id="basic-addon1"
                              >
                                <Select
                                  value={selectedOption}
                                  options={options}
                                  onChange={handleChange}
                                  menuPlacement="auto"
                                  styles={{
                                    menuList: (base) => ({
                                      ...base,
                                      maxHeight: "150px",
                                    }),
                                  }}
                                />
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              name="number"
                              placeholder="Enter Number"
                              onChange={handleInput}
                              onKeyPress={handleKeyPressNumber}
                              value={input.number}
                            />
                          </div>
                          {errors.number && (
                            <div className="text-danger d-block">
                              {errors.number}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-6 col-12 px-2">
                        <label htmlFor="email" className="form-label fw-5">
                          Email
                          <span className="text-danger">*</span>
                          <Popover content={mailInfo} placement="bottomLeft">
                            <Button type="button" className="p-1" tabIndex={-1}>
                              <i className="bi bi-info-circle c_575757 hover-info"></i>
                            </Button>
                          </Popover>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="email"
                          placeholder="Enter Email"
                          onChange={handleInput}
                          value={input.email}
                        />
                        {errors.email && (
                          <div className="text-danger">{errors.email}</div>
                        )}
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-6 col-12 px-2">
                        <label htmlFor="language" className="form-label fw-5">
                          Language
                          <span className="text-danger">*</span>
                          <Popover
                            content={languageInfo}
                            placement="bottomLeft"
                          >
                            <Button type="button" className="p-1" tabIndex={-1}>
                              <i className="bi bi-info-circle c_575757 hover-info"></i>
                            </Button>
                          </Popover>
                        </label>
                        <select
                          name="language"
                          id="language"
                          className="form-select"
                          onChange={handleSelete}
                          value={input.language}
                        >
                          <option value="" disabled>
                            Select Language
                          </option>

                          {languageSelete.map((item, index) => (
                            <option
                              key={index}
                              value={item.name}
                              // title={item.price}
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                        {errors.language && (
                          <div className="text-danger">{errors.language}</div>
                        )}
                      </div>

                      {/* ========================== Advanced Options ============================ */}

                      <p
                        className="c_F77B21 fw-bold mt-5 d-sm-none"
                        onClick={toggleAdvanceOptions}
                      >
                        Advanced Option
                        <i
                          className={`bi  ${
                            !adOption ? "bi-chevron-down" : "bi-chevron-up"
                          } p-2 `}
                        ></i>
                      </p>
                      <div className="advance-options mt-3 mt-md-5 row ps-3">
                        <div className="col-xl-3 col-md-4 ps-3 between">
                          <label className="form-label fw-5">
                            Vocal Preference
                            {artistMandatory === "Mandatory" && (
                              <span className="text-danger">*</span>
                            )}
                            <Popover
                              content={artistInfo}
                              placement="bottomRight"
                            >
                              <Button
                                type="button"
                                className="p-1"
                                tabIndex={-1}
                              >
                                <i className="bi bi-info-circle c_575757 hover-info"></i>
                              </Button>
                            </Popover>
                          </label>
                          <div className="d-flex py-3 w-100 gap-3">
                            <div className="radio-buttons-container">
                              <div className="checkbox-wrapper-26">
                                <input
                                  type="radio"
                                  name="gender"
                                  id="male"
                                  value="Male"
                                  onChange={handleSelete}
                                  className="form-check-input"
                                  checked={input.gender === "Male"}
                                />
                                <label htmlFor="male">
                                  <div className="tick_mark"></div>
                                </label>
                                <span className="mt-2 mt-md-0">Male</span>
                              </div>
                              <div className="checkbox-wrapper-26">
                                <input
                                  type="radio"
                                  name="gender"
                                  id="female"
                                  value="Female"
                                  onChange={handleSelete}
                                  className="form-check-input"
                                  checked={input.gender === "Female"}
                                />
                                <label htmlFor="female">
                                  <div className="tick_mark"></div>
                                </label>
                                <span className="mt-2 mt-md-0">Female</span>
                              </div>
                            </div>
                          </div>
                          {errors.gender && (
                            <div className="text-danger">{errors.gender}</div>
                          )}
                        </div>
                        <div className="col-xl-4 col-md-4  between">
                          <label
                            htmlFor="instrument"
                            className="form-label fw-5 w-100"
                            style={{
                              position: "relative",
                            }}
                          >
                            Song Version
                            {instumentMandatory === "Mandatory" && (
                              <span className="text-danger">*</span>
                            )}
                            <Popover
                              content={instrumentInfo}
                              placement="bottomLeft"
                            >
                              <Button
                                type="button"
                                className="p-1"
                                tabIndex={-1}
                              >
                                <i className="bi bi-info-circle c_575757 hover-info"></i>
                              </Button>
                            </Popover>
                          </label>

                          <select
                            name="instrument"
                            id="instrument"
                            className="form-select"
                            onChange={handleSelete}
                            value={input.instrument}
                          >
                            <option value="" disabled>
                              Select Version
                            </option>
                            {instrumentSelete.map((item, index) => (
                              <option value={item.name} key={index}>
                                {item.name}-₹{item.price}
                              </option>
                            ))}
                          </select>
                          {input.instrument && (
                            <div className="d-flex align-items-center sample-audio-div pe-3">
                              <p className="sample_audio">Sample Audio</p>
                              <button
                                type="button"
                                onClick={togglePlayPause}
                                className="sample-play-btn"
                              >
                                {isPlaying && input.instrument !== "" ? (
                                  <i className="bi bi-pause-fill pause-icon"></i>
                                ) : (
                                  <i className="bi bi-play-fill play-icon"></i>
                                )}
                              </button>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  flex: 1,
                                }}
                                className="mono-space"
                              >
                                <input
                                  type="range"
                                  min={0}
                                  max={Math.round(duration)}
                                  value={Math.round(currentTime)}
                                  onChange={changeCurrentTime}
                                  className="flex-grow-1"
                                  readOnly
                                />
                                {isPlaying ? (
                                  <span className="audio-duration">
                                    {formatDuration(currentTime)}
                                  </span>
                                ) : (
                                  <span className="audio-duration">
                                    {formatDuration(duration)}
                                  </span>
                                )}
                              </div>
                            </div>
                          )}

                          {errors.instrument && (
                            <div className="text-danger">
                              {errors.instrument}
                            </div>
                          )}
                        </div>
                        {/* <div className="col-xl-3 col-md-4  between">
                          <label htmlFor="songMood" className="form-label fw-5">
                            Choose Mood
                            {moodMandatory === "Mandatory" && (
                              <span className="text-danger">*</span>
                            )}
                            <Popover content={moodInfo} placement="bottomLeft">
                              <Button type="button" className="p-1">
                                <i className="bi bi-info-circle c_575757 hover-info"></i>
                              </Button>
                            </Popover>
                          </label>
                          <select
                            name="songMood"
                            id="songMood"
                            className="form-select"
                            onChange={handleInput}
                            value={input.songMood}
                          >
                            <option value="" disabled>
                              Select Mood
                            </option>
                            {moodSelete.map((item, index) => (
                              <option value={item.name} key={index}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          {errors.songMood && (
                            <div className="text-danger">{errors.songMood}</div>
                          )}
                        </div> */}
                      </div>
                    </>

                    <div className="d-none d-sm-block mt-4 ps-0">
                      <div className="row pay-card-tab ms-2 d-none d-sm-block">
                        <div className="col-12 d-flex align-items-center py-3 bg-fff">
                          <div className="row w-100 d-flex align-items-center">
                            <div className="col-sm-8 col-12 d-flex align-items-center">
                              <img
                                src={require("../../assets/images/audio-img.png")}
                                alt=""
                                className="img-fluid audio-img"
                              />
                              <div className="d-flex mx-2">
                                <p className="mb-0 c_575757">Price Details</p>
                                {Object.keys(input).map((key) => {
                                  if (
                                    ![
                                      "name",
                                      "email",
                                      "number",
                                      "total",
                                      "songMood",
                                    ].includes(key) &&
                                    input[key]
                                  ) {
                                    return (
                                      <div
                                        key={key}
                                        className="priceCard c_F77B21 d-flex fw-7 gap-2 rounded"
                                      >
                                        {key === "language" ? (
                                          <>
                                            {input[key]} - ₹
                                            {
                                              languageSelete.find(
                                                (item) =>
                                                  item.name === input[key]
                                              )?.price
                                            }
                                          </>
                                        ) : key === "instrument" ? (
                                          <>
                                            {input[key]} - ₹
                                            {
                                              instrumentSelete.find(
                                                (item) =>
                                                  item.name === input[key]
                                              )?.price
                                            }
                                          </>
                                        ) : key === "gender" ? (
                                          <>
                                            {input[key]} - ₹
                                            {
                                              artistSelete.find(
                                                (item) =>
                                                  item.artist === input[key]
                                              )?.price
                                            }
                                          </>
                                        ) : (
                                          input[key]
                                        )}
                                        <i
                                          className="bi bi-x-circle-fill c_F77B21"
                                          onClick={() => handleRemoveField(key)}
                                        ></i>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            </div>
                            <div className="col-12 col-sm-4 callme_btn d-flex p-0">
                              <div className="ms-auto amount-btn c_#FFFFFF fw-5 p-3">
                                Total: ₹
                                {parseInt(languageAmount || 0) +
                                  parseInt(instrumentAmount || 0) +
                                  parseInt(artistAmount || 0)}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-button d-none d-sm-flex mt-4 p-0">
                          {/* <div className="">
                              <button type="submit" className="back-btn fw-6">
                                Back
                              </button>
                            </div> */}
                          <div className="">
                            <button type="submit" className="continue-btn fw-6">
                              Continue
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* =================== Back and continue =============== */}
                    <div className="d-sm-none mt-4 sticky-bottom bg-color bottom_znone">
                      <div className="row ">
                        <div className="col-4 text-center p-2 me-auto">
                          <span className="h3 fw-bold c_F77B21 ">
                            ₹
                            {parseInt(languageAmount || 0) +
                              parseInt(instrumentAmount || 0) +
                              parseInt(artistAmount || 0)}
                          </span>
                        </div>
                        <div className="col-3 p-2 d-none">
                          <button type="submit" className="back-btn-new fw-6">
                            Back
                          </button>
                        </div>
                        <div className="col-5 p-2">
                          <button
                            type="submit"
                            className="continue-btn-new fw-6"
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="col-xl-3 col-lg-4 col-md-4 d-none d-sm-block">
                  <div className="form-img position-absolute bottom-0 right-0">
                    <img
                      src={require("../../assets/images/TuneFor2Img/custom-song-writer.webp")}
                      alt="custom song writer"
                      title="custom song writer"
                      className="form-bg img-fluid d-none d-md-block "
                    />
                  </div>
                  <div className="shape3">
                    <img
                      src={require("../../assets/images/shape3.png")}
                      alt=""
                      className="d-none d-md-block img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Content for the second tab */}
        {activeTab === 1 && (
          <div className="container-fluid">
            <UserDetailsTab />
          </div>
        )}

        {/* Content for the third tab */}
        {activeTab === 2 && (
          <div className="container-fluid">
            <VerifyDetails />
          </div>
        )}

        {/* Content for the fourth tab */}
        {activeTab === 3 && (
          <div className="container-fluid">
            <Payment />
          </div>
        )}
      </section>
      <Footer />
    </>
  );
};
export default UserDetails;
