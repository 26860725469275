import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { useState } from "react";
import { useEffect } from "react";

const NavLogo = ({createSong , home, landingpage}) => {

    const[scroll , setScroll] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll' , function(){
            if(window.scrollY > 80){
                setScroll(true);
            }else{
                setScroll(false);
            }
        })

    },[]);
    
    return (
        <nav className= "navbar navbar-expand-lg Montserrat bg-color Poppins" >
            <div className="container-fluid">
                <Link to={'/'} className="navbar-brand nav-link">
                    <img src={logo} alt="Logo" className="img-fluid" width={'100%'} />
                </Link>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <ul className="navbar-nav">
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default NavLogo;
